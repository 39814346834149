import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "components/templatesComponents/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import elementsTypes from "utils/elementsTypes";

const TabsForm = (props) => {
  const { content, onContentChange, classes } = props;
  const { label, children } = content;
  const tabsTypes = [elementsTypes.TAB, elementsTypes.TAB_CARDS];

  const tabs = children.filter((c) => tabsTypes.includes(c.type));
  const titleChildren = tabs.map((t) => t.children && t.children.find((c) => c.key === "title"));
  const tabLength = titleChildren.length;

  const handleUpClick = (t, childBefore) => {
    onContentChange({
      ...content,
      children: [
        ...children.filter((c) => !tabsTypes.includes(c.type)),
        ...children
          .filter((c) => tabsTypes.includes(c.type))
          .sort((tab1, tab2) => {
            if (tabsTypes.includes(tab1.type) && tabsTypes.includes(tab2.type)) {
              const titleTab1 = tab1.children.find((c) => c.key === "title");
              const titleTab2 = tab2.children.find((c) => c.key === "title");
              if (titleTab1 === t && titleTab2 === childBefore) {
                return -1;
              }
              if (titleTab2 === t && titleTab1 === childBefore) {
                return 1;
              }
            }
            return 0;
          }),
      ],
    });
  };
  const handleDownClick = (t, childAfter) => {
    onContentChange({
      ...content,
      children: [
        ...children.filter((c) => !tabsTypes.includes(c.type)),
        ...children
          .filter((c) => tabsTypes.includes(c.type))
          .sort((tab1, tab2) => {
            if (tabsTypes.includes(tab1.type) && tabsTypes.includes(tab2.type)) {
              const titleTab1 = tab1.children.find((c) => c.key === "title");
              const titleTab2 = tab2.children.find((c) => c.key === "title");
              if (titleTab1 === t && titleTab2 === childAfter) {
                return 1;
              }
              if (titleTab2 === t && titleTab1 === childAfter) {
                return -1;
              }
            }
            return 0;
          }),
      ],
    });
  };

  return (
    <Fragment>
      <h2 className={classes.title}>{label}</h2>
      {titleChildren.map((t, i) => (
        <List dense key={t.id}>
          <ListItem>
            <ListItemText primary={t.value} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => handleUpClick(t, titleChildren[i - 1])}
                edge="end"
                aria-label="Monter"
                disabled={i === 0}
              >
                <Icon icon="chevron-up" iconDSFR="arrow-up-s-line" title="Monter" />
              </IconButton>
              <IconButton
                onClick={() => handleDownClick(t, titleChildren[i + 1])}
                edge="end"
                aria-label="Descendre"
                disabled={i >= tabLength - 1}
              >
                <Icon icon="chevron-down" iconDSFR="arrow-down-s-line" title="Descendre" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
    </Fragment>
  );
};

TabsForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default TabsForm;
