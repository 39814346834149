import React, { useCallback } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const AdminBannerContent = (props) => {
  const { classes, contents = [], errors, lang, onChange, showError, title } = props;

  const getValue = useCallback(
    (name) => (contents?.length ? contents.find((content) => content.lang === lang)?.[name] : ""),
    [contents, lang]
  );

  return (
    <Grid item xs={6}>
      <fieldset className={classes.fieldset}>
        <FormLabel component="legend">{title}</FormLabel>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl error={showError(`title${lang}`)}>
              <TextField
                name="title"
                label="Titre du bandeau *"
                value={getValue("title")}
                defaultValue={getValue("title")}
                onChange={onChange}
                error={showError(`title${lang}`)}
              />
              {showError(`title${lang}`) && <FormHelperText>{errors[`title${lang}`]}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              name="text"
              label="Contenu du bandeau"
              value={getValue("text")}
              defaultValue={getValue("text")}
              onChange={onChange}
              variant="outlined"
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs>
              <TextField
                name="linkLabel"
                label="Libellé du lien"
                value={getValue("linkLabel")}
                defaultValue={getValue("linkLabel")}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="link"
                label="Url du lien"
                value={getValue("link")}
                defaultValue={getValue("link")}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </fieldset>
    </Grid>
  );
};

AdminBannerContent.propTypes = {
  classes: PropTypes.shape().isRequired,
  contents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  errors: PropTypes.shape(),
  lang: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

AdminBannerContent.defaultProps = {
  errors: {},
};

export default AdminBannerContent;
