import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Slider from "@mui/material/Slider";
import { formControlStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import PropTypes from "prop-types";
import React from "react";

const SliderForm = (props) => {
  const { content, onContentChange, ...others } = props;

  const { id, label, value, min, max, step, width } = content;

  const handleChange = (event, newValue) => {
    onContentChange({
      ...content,
      value: String(newValue),
    });
  };

  return (
    <FormControl variant="standard" key={id} sx={formControlStyled(width)}>
      <InputLabel sx={{ pointerEvents: "auto" }} shrink>
        {label}
      </InputLabel>

      <Slider
        value={Number(value)}
        valueLabelDisplay="auto"
        step={Number(step)}
        marks
        min={Number(min)}
        max={Number(max)}
        onChange={handleChange}
        sx={{ mt: 2, mb: 0, mx: "auto", width: "90%" }}
        {...others}
      />
    </FormControl>
  );
};

SliderForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default SliderForm;
