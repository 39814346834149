import ValidateArrow from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { CKEditor } from "ckeditor4-react";
import AdminContext from "components/adminComponents/AdminContext";
import CKEditorCustom from "components/adminComponents/CKEditorCustom";
import FilePicker from "components/adminComponents/FilePicker";
import SelectPage from "components/adminComponents/SelectPage";
import AdminPageVersionsService, { pageVersionImageKey } from "components/adminServices/AdminPageVersionsService";
import news from "components/templates/news/news";
import templates, { excludedTemplatesForPageCreation } from "components/templates/templates";
import Autosuggest from "components/templatesComponents/Autosuggest";
import Button from "components/templatesComponents/Button";
import { ModalActions } from "components/templatesComponents/Modal";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PageService from "services/PageService";
import { generateContentId } from "utils/adminContentsUtils";
import { debounce, descriptionToolbarEditor } from "utils/commonUtils";
import languages from "utils/languagesTypes";
import { getShortDescriptionMaxsize, getTitleMaxsize } from "utils/newsTemplateUtils";
import { stringToPath } from "utils/urlUtils";

const fieldsetCustomStyled = {
  border: "1px solid rgba(0,0,0, 0.28)",
  borderRadius: 1,
  "& legend": {
    fontSize: "0.875rem",
  },
  "& .cke_editable": {
    minHeight: 100,
  },
};

const autoSuggestClasses = (theme) => ({
  container: {
    width: "100%",
    position: "relative",
    padding: 0,
  },
  suggestionsContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.componentColors[30]}`,
    borderBottom: "none",
    zIndex: 100,
  },
  suggestionsList: {
    listStyleType: "none",
    paddingLeft: "0 !important",
    margin: 0,
    maxHeight: "190px",
    overflow: "auto",
    borderBottom: `1px solid ${theme.palette.componentColors[30]}`,
  },
});

const defaultForm = {
  path: "/",
  shortDescription: "",
};

const FormBlock = (p) => {
  const { children } = p;
  return <Box mb={2}>{children}</Box>;
};

const AdminPageForm = (props) => {
  const { version = null, onValidate, onCancel, isImport = false } = props;

  const { currentSite } = useContext(AdminContext);

  const { name: currentSiteName } = currentSite || {};

  const [form, setForm] = useState(version || defaultForm);
  const [newTag, setNewTag] = useState("");
  const [validatedTag, setValidatedTag] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const DESCRIPTION_MIN_CHARACTER = 50;
  const DESCRIPTION_MAX_CHARACTER = 160;
  const [pathIsUnavailable, setPathIsUnavailable] = useState(false);
  const [originalPageIsUnavailable, setOriginalPageIsUnavailable] = useState(false);
  const [isEnglishHomePage, setIsEnglishHomePage] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const { page, template, title, path } = form;
    const { parentId, name, id, lang, originalPageId } = page || {};
    const isHomePage = id && !parentId && path === "/";
    let pathError;
    if (!pathIsUnavailable) {
      pathError = null;
    }
    if (path === "/" && !isHomePage) {
      pathError = "Veuillez saisir un chemin pour cette page";
    }
    if (pathIsUnavailable) {
      pathError = "Ce chemin est déjà utilisé dans une page publiée";
    }
    let originalPageError;
    if (lang === "FR") {
      originalPageError = null;
    }
    if (lang === "EN") {
      if (!originalPageId) {
        originalPageError = "Veuillez sélectionner une page référence";
      }
      if (originalPageIsUnavailable) {
        originalPageError = "Cette page de référence est déjà utilisée";
      }
    }
    setErrors({
      parentId: !parentId && !(isHomePage || isEnglishHomePage),
      template: !template,
      lang: !lang,
      originalPageId: originalPageError,
      name: !name,
      title_required: !title,
      title_max_size: template === news.key ? title?.length > getTitleMaxsize() : null,
      path: pathError,
    });
  }, [form, originalPageIsUnavailable, pathIsUnavailable, isEnglishHomePage]);

  useEffect(() => {
    if (version) {
      const { contents } = version;
      const versionImage = contents.find((c) => c.key === pageVersionImageKey);
      if (versionImage) {
        try {
          const imageParsed = JSON.parse(versionImage.value);
          setForm({
            ...form,
            image: {
              ...(form.image || {}),
              ...imageParsed,
            },
          });
        } catch (e) {
          // if versionImage.value cannot be parsed, does nothing
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNewPage = !version || isImport;
  const canUpdateTemplate = !version;
  const canUpdateParent = isNewPage;

  const allTemplates = isNewPage
    ? templates
        .sort((a, b) => a.label.localeCompare(b.label))
        .filter((t) => !excludedTemplatesForPageCreation.includes(t))
    : templates;

  const { path, template, title, shortDescription, description, tags, image, page = {} } = form || {};

  const { name, id: pageId, parentId, lang, originalPageId } = page;

  const formTitle = isNewPage ? "Créer une nouvelle page" : "Mettre à jour la page";
  const validateText = isNewPage ? "Créer" : "Modifier";

  const profilTagsArray = useMemo(
    () =>
      (lang.toUpperCase() === "EN" ? process.env.profil_tags_en : process.env.profil_tags || "")
        .toLowerCase()
        .split(","),
    [lang]
  );

  const handleFormChange = (e) => {
    const { value, name: fieldName } = e.target;

    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const handleFormPageChange = (e) => {
    const { value, name: fieldName } = e.target;

    setForm({
      ...form,
      page: {
        ...form.page,
        [fieldName]: value,
      },
    });
  };

  const handleChangeTitle = (e) => {
    const { value } = e.target;
    const pathFromTitle = stringToPath(value);
    setForm({
      ...form,
      path: form.path === stringToPath(form.title) ? pathFromTitle : form.path,
      title: value,
    });
  };

  const handleChangePath = (e) => {
    const { value } = e.target;
    setPathIsUnavailable(false);

    setForm({
      ...form,
      path: stringToPath(value),
    });
  };

  const checkIfPathIsAvailable = useCallback(
    (pathname, id) =>
      AdminPageVersionsService.find({
        params: {
          join: ["page"],
          filter: [
            `path||eq||${pathname}`,
            `page.siteId||eq||${currentSite?.id}`,
            parentId ? `page.parentId||eq||${parentId}` : `page.parentId||isnull`,
            `page.id||ne||${id}`,
            `status||eq||published`,
          ],
        },
      }).then((versions) => {
        if (versions?.length > 0) {
          setPathIsUnavailable(true);
        } else {
          setPathIsUnavailable(false);
        }
      }),
    [currentSite.id, parentId]
  );

  useEffect(() => {
    debounce(checkIfPathIsAvailable(form.path, pageId), 500);
  }, [form.path, form.parentId, checkIfPathIsAvailable, pageId]);

  const handleValidate = () => {
    setFormSubmitted(true);
    if (Object.values(errors).reduce((a, b) => a && !b, true)) {
      if (typeof onValidate === "function") {
        if (form?.page?.lang === "FR") {
          onValidate({ ...form, page: { ...form.page, originalPageId: null } });
        } else {
          onValidate(form);
        }
      }
    }
  };

  const handleTemplateChange = (e) => {
    const { value } = e.target;
    const templateDefinition = templates.find((t) => t.key === value);

    const { initialContents = [] } = templateDefinition;

    setForm({
      ...form,
      template: value,
      contents: initialContents.map(generateContentId),
    });
  };

  const checkIfIsEnglishHomePage = useCallback(
    () =>
      AdminPageVersionsService.find({
        params: {
          join: ["page"],
          filter: [`page.siteId||eq||${currentSite?.id}`, `status||eq||published`, `path||eq||/`],
        },
      }).then((versions) => {
        if (versions?.length > 0) {
          setIsEnglishHomePage(versions.map((v) => v.pageId)?.includes(originalPageId));
        }
      }),
    [currentSite.id, originalPageId]
  );

  useEffect(() => {
    checkIfIsEnglishHomePage();
  }, [checkIfIsEnglishHomePage]);

  const handleSelectParentPage = (parentPage) => {
    setForm({
      ...form,
      page: {
        ...form.page,
        parentId: parentPage?.id || "",
      },
    });
  };

  const handleSelectLanguage = (e) => {
    const { value } = e.target;
    setForm({
      ...form,
      page: {
        ...form.page,
        lang: value,
        parentId: lang && canUpdateParent ? "" : form.page?.parentId,
      },
    });
  };

  const handleSelectOriginalPage = (originalPage) => {
    setForm({
      ...form,
      page: {
        ...form.page,
        originalPageId: originalPage?.id || "",
      },
    });
  };

  const checkIfOriginalPageIsAvailable = useCallback(
    () =>
      AdminPageVersionsService.find({
        params: {
          join: ["page"],
          filter: [
            `page.siteId||eq||${currentSite?.id}`,
            `status||eq||published`,
            `page.originalPageId||notnull`,
            `pageId||ne||${pageId}`,
          ],
        },
      }).then((versions) => {
        if (versions?.length > 0) {
          setOriginalPageIsUnavailable(versions.map((v) => v.page?.originalPageId)?.includes(originalPageId));
        }
      }),
    [currentSite.id, originalPageId, pageId]
  );

  useEffect(() => {
    checkIfOriginalPageIsAvailable();
  }, [checkIfOriginalPageIsAvailable]);

  const handleChangeImage = (newImage = {}) => {
    setForm({
      ...form,
      image: newImage,
    });
  };

  const handleShortDescriptionChange = (value) => {
    setForm({
      ...form,
      shortDescription: value,
    });
  };

  const handleSubmitFormTags = React.useCallback(
    (e) => {
      if (e !== undefined) {
        e.preventDefault();
      }
      if (newTag) {
        setFormSubmitted(true);

        let newTags = [];
        if (tags && Array.isArray(tags)) {
          newTags = tags;
        }

        const tag = newTag.trim();
        if (tag.length > 0) {
          if (newTags.find((t) => t.toLowerCase() === newTag.toLowerCase()) !== undefined) {
            setErrors({ ...errors, duplicateTag: true });
          } else {
            newTags.push(tag);
            setForm({
              ...form,
              tags: newTags,
            });
            setNewTag("");
          }
        }
        setValidatedTag(false);
      }
    },
    [form, newTag, tags, errors]
  );

  const handleClickDeleteChip = (tag) => () => {
    const tagsList = tags.filter((t) => t !== tag);

    setForm({
      ...form,
      tags: tagsList,
    });
  };

  const handleTagChange = (e) => {
    const { value } = e.target;

    setNewTag(value);
    setErrors({ ...errors, duplicateTag: false });
  };

  const showError = (key) => formSubmitted && errors[key];

  const renderInputComponent = (inputProps) => (
    <TextField
      variant="standard"
      placeholder="Ajouter un tag"
      error={showError("duplicateTag")}
      helperText={showError("duplicateTag") && "Ce tag est déjà renseigné"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ValidateArrow
              color={newTag ? "primary" : "disabled"}
              onClick={handleSubmitFormTags}
              sx={{ cursor: newTag ? "pointer" : "initial" }}
            />
          </InputAdornment>
        ),
      }}
      {...inputProps}
    />
  );

  const [{ data }] = useAxiosCache(
    PageService.getConfig("getTags", {
      currentSiteName,
      lang: form?.page?.lang || "FR",
    })
  );
  const { aggregations } = data || {};
  const { tags: allTags } = aggregations || {};
  const { buckets } = allTags || {};

  const getSuggestions = async (search) => {
    return (
      (Array.isArray(buckets) && buckets.map((bucket) => bucket.key))
        ?.filter((buck) => buck?.toLowerCase().includes(search?.toLowerCase()))
        ?.filter((b) => !tags?.includes(b)) || []
    );
  };

  const inputProps = {
    value: newTag,
    onChange: handleTagChange,
  };

  const onSuggestionSelected = (event, { suggestionValue }) => {
    if (suggestionValue) {
      setNewTag(suggestionValue);
      setValidatedTag(true);
    }
  };

  useEffect(() => {
    if (validatedTag) {
      handleSubmitFormTags();
    }
  }, [validatedTag, handleSubmitFormTags]);

  return (
    <Box sx={{ width: 768 }}>
      <h2>{formTitle}</h2>
      <FormBlock>
        <Box component="fieldset" sx={fieldsetCustomStyled}>
          <legend>Multilingue (PNU)</legend>
          <Grid container justifyContent="space-between">
            <Grid item xs={4}>
              <FormControl variant="standard" error={showError("lang")}>
                <InputLabel>Langue</InputLabel>
                <Select
                  value={lang || ""}
                  name="lang"
                  displayEmpty
                  variant="standard"
                  onChange={handleSelectLanguage}
                  disabled={parentId}
                  MenuProps={{ getContentAnchorEl: null }}
                >
                  {Object.values(languages).map((l) => (
                    <MenuItem key={l.lang} value={l.value}>
                      {l.label}
                    </MenuItem>
                  ))}
                </Select>
                {showError("lang") && <FormHelperText>Veuillez sélectionner une langue</FormHelperText>}
              </FormControl>
            </Grid>
            {lang === "EN" && (
              <Grid item xs={7}>
                <FormControl error={showError("originalPageId")}>
                  <SelectPage
                    currentPage={{ id: originalPageId }}
                    onSelectPage={handleSelectOriginalPage}
                    label="Page de référence (FR)"
                    onlyPublishedPages
                    onlyFrenchPages
                  />
                  {showError("originalPageId") && <FormHelperText>{errors.originalPageId}</FormHelperText>}
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Box>
      </FormBlock>
      <FormBlock>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <FormControl error={showError("parentId")}>
              <SelectPage
                currentPage={{ id: parentId }}
                onSelectPage={handleSelectParentPage}
                disabled={!canUpdateParent || !lang}
                label="Page parente"
                onlyPublishedPages={lang === "FR"}
                lang={lang}
              />
              {!lang && <FormHelperText>Veuillez sélectionner une langue pour commencer</FormHelperText>}
              {showError("parentId") && <FormHelperText>Veuillez sélectionner une page parente</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="standard" error={showError("template")}>
              <InputLabel>Gabarit</InputLabel>
              <Select
                value={template || ""}
                name="template"
                variant="standard"
                onChange={handleTemplateChange}
                disabled={!canUpdateTemplate}
                MenuProps={{ getContentAnchorEl: null }}
              >
                {allTemplates.map((t) => (
                  <MenuItem key={t.key} value={t.key}>
                    {t.label}
                  </MenuItem>
                ))}
              </Select>
              {showError("template") && <FormHelperText>Veuillez sélectionner un gabarit</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              error={showError("name")}
              label="Nom (uniquement pour l'administration)"
              value={name || ""}
              name="name"
              onChange={handleFormPageChange}
              helperText={showError("name") && "Veuillez nommer cette page"}
            />
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              error={showError("title_required") || showError("title_max_size")}
              label="Titre de la page"
              value={title || ""}
              name="title"
              onChange={handleChangeTitle}
              inputProps={{
                maxLength: template === news.key ? getTitleMaxsize() : null,
              }}
              helperText={
                showError("title_required")
                  ? "Veuillez donner un titre à cette page"
                  : showError("title_max_size") && `Le titre ne doit pas dépasser ${getTitleMaxsize()} caractères`
              }
            />
            {template === news.key && <p>{`Caractères : ${title?.length || 0}/${getTitleMaxsize()}`}</p>}
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              error={showError("path")}
              label="Chemin"
              value={path}
              name="path"
              onChange={handleChangePath}
              helperText={showError("path") && errors.path}
            />
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component="fieldset" sx={fieldsetCustomStyled}>
              <legend>Tags (utilisés dans certains gabarits de pages)</legend>
              <form onSubmit={handleSubmitFormTags}>
                <Grid container direction="row-reverse" alignItems="baseline" spacing={2}>
                  <Grid item xs={8}>
                    {tags &&
                      tags.map((tag) => (
                        <Chip
                          key={tags.indexOf(tag)}
                          label={tag}
                          sx={{
                            m: 1,
                            bgcolor: profilTagsArray.includes(tag.toLowerCase()) ? "primary.70" : "primary.main",
                            "& span:first-letter": { textTransform: "uppercase" },
                          }}
                          onDelete={handleClickDeleteChip(tag)}
                          color="primary"
                        />
                      ))}
                  </Grid>
                  <Grid item xs={4}>
                    <Autosuggest
                      getSuggestions={getSuggestions}
                      debounce={300}
                      getSuggestionValue={(suggestion) => suggestion}
                      renderSuggestion={(suggestion) => (
                        <Box sx={{ "&:first-letter": { textTransform: "uppercase" } }}>{suggestion}</Box>
                      )}
                      renderInputComponent={renderInputComponent}
                      onSuggestionSelected={onSuggestionSelected}
                      inputProps={inputProps}
                      classes={autoSuggestClasses(theme)}
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={1}>
              <Box component="fieldset" sx={fieldsetCustomStyled}>
                <legend>Description courte (chapeau de certains gabarits de pages)</legend>
                {template === news.key ? (
                  <CKEditorCustom
                    key={page.id}
                    initData={decodeURIComponent(shortDescription || "")}
                    onChange={(e) => handleShortDescriptionChange(encodeURIComponent(e))}
                    type="inline"
                    maxSize={getShortDescriptionMaxsize()}
                    config={{ toolbar: descriptionToolbarEditor }}
                  />
                ) : (
                  <CKEditor
                    key={page.id}
                    onBeforeLoad={(CKEDITOR) => {
                      // eslint-disable-next-line no-param-reassign
                      CKEDITOR.disableAutoInline = true;
                    }}
                    editorUrl="/ckeditor/ckeditor.js"
                    initData={decodeURIComponent(shortDescription || "")}
                    onChange={(e) => handleShortDescriptionChange(encodeURIComponent(e.editor.getData()))}
                    type="inline"
                    config={{ toolbar: descriptionToolbarEditor }}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={1}>
              <TextField
                label={`Description de la page (meta-donnée de référencement). ${DESCRIPTION_MIN_CHARACTER} caractères minimum recommandés.`}
                value={description || ""}
                name="description"
                onChange={handleFormChange}
                multiline
                rows={5}
                inputProps={{ maxLength: DESCRIPTION_MAX_CHARACTER }}
              />
              <p>{`Caractères : ${description?.length || 0}/${DESCRIPTION_MAX_CHARACTER}`}</p>
            </Box>
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mb: 1,
            "& button": {
              color: "red",
              "&:hover": {
                bgcolor: "#d82a2a1a",
              },
            },
          }}
        >
          <label>Image</label>
          {image && (
            <MuiButton variant="text" className="fr-text--xs" onClick={() => handleChangeImage(null)}>
              Supprimer l&apos;image
            </MuiButton>
          )}
        </Grid>
        <FilePicker
          onSelectFile={handleChangeImage}
          file={image}
          pageVersion={version}
          image
          formSubmitted={formSubmitted}
        />
      </FormBlock>
      <ModalActions>
        <Button onClick={onCancel} outlined>
          Annuler
        </Button>
        <Button onClick={handleValidate}>{validateText}</Button>
      </ModalActions>
    </Box>
  );
};

AdminPageForm.propTypes = {
  version: PropTypes.shape(),
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isImport: PropTypes.bool,
};

export default AdminPageForm;
