import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import AdminMenuItems from "components/adminComponents/AdminMenuItems";

const useStyles = makeStyles(() => ({
  root: {},
}));

const columns = ["LinkExternal", "LinkInternal1", "LinkInternal2"];

const AdminFooterForm = (props) => {
  const { footer, onFooterChange } = props;
  const { contents = [] } = footer;

  const classes = useStyles();

  const handleItemsChange = (columnKey) => (items) => {
    onFooterChange({
      ...footer,
      contents: [...contents.filter((child) => child.key !== columnKey), ...items],
    });
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        {columns.map((columnKey, index) => (
          <Grid item key={columnKey} xs={4}>
            <h4>Colonne {index + 1} :</h4>
            <AdminMenuItems
              items={contents.filter((c) => c.key === columnKey)}
              itemKey={columnKey}
              onItemsChange={handleItemsChange(columnKey)}
              maxDepth={0}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

AdminFooterForm.propTypes = {
  footer: PropTypes.shape(),
  onFooterChange: PropTypes.func.isRequired,
};

AdminFooterForm.defaultProps = {
  footer: {},
};

export default AdminFooterForm;
