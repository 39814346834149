import Edit from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import AdminContentModal from "components/adminComponents/AdminContentModal";
import PropTypes from "prop-types";
import React, { useState } from "react";

const AdminActions = (props) => {
  const { children, content, onContentChange, modalProps = {}, ...others } = props;

  const [displayModalContent, setDisplayModalContent] = useState(false);

  const handleClickEdit = () => {
    setDisplayModalContent(true);
  };

  const handleContentChange = (updatedContent) => {
    setDisplayModalContent(false);
    onContentChange(updatedContent);
  };

  return (
    <Box sx={{ position: "relative", border: "double #2646D4" }} {...others}>
      <Box
        sx={{
          position: "absolute",
          top: "12px",
          left: "12px",
          zIndex: "50",
          bgcolor: "#ffffff66",
          display: "flex",
          borderRadius: "50%",
          p: 0.25,
          cursor: "pointer",
        }}
      >
        <Edit onClick={handleClickEdit} />
      </Box>
      {children}
      <AdminContentModal
        open={displayModalContent}
        content={content}
        onValidate={handleContentChange}
        onClose={() => setDisplayModalContent(false)}
        {...modalProps}
      />
    </Box>
  );
};

AdminActions.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(),
};

export default AdminActions;
