import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AdminSitesService from "components/adminServices/AdminSitesService";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const SelectSiteName = (props) => {
  const { siteName = "", onSiteNameChange } = props;

  const [sites, setSites] = useState([]);

  useEffect(() => {
    AdminSitesService.find({
      params: {
        sort: "name,ASC",
      },
    }).then(setSites);
  }, []);

  const handleSelectSiteChange = (e) => {
    const name = e.target.value;
    onSiteNameChange(name);
  };

  return (
    <Select
      value={siteName}
      onChange={handleSelectSiteChange}
      displayEmpty
      variant="standard"
      MenuProps={{ getContentAnchorEl: null }}
      sx={{ width: 140 }}
    >
      <MenuItem value="allSites">Tous les sites</MenuItem>
      {sites.map((s) => (
        <MenuItem key={s.name} value={s.name}>
          {s.name}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectSiteName.propTypes = {
  siteName: PropTypes.string,
  onSiteNameChange: PropTypes.func.isRequired,
};

export default SelectSiteName;
