import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import AdminMenuItems from "components/adminComponents/AdminMenuItems";

const useStyles = makeStyles(() => ({
  root: {},
}));

const AdminMenuForm = (props) => {
  const { menu, onMenuChange } = props;
  const { contents = [] } = menu;

  const classes = useStyles();

  const handleItemsChange = (menuItems) => {
    onMenuChange({
      ...menu,
      contents: menuItems,
    });
  };

  return (
    <div className={classes.root}>
      <AdminMenuItems
        items={contents}
        itemKey="menus"
        subItemKey="subMenus"
        onItemsChange={handleItemsChange}
        maxDepth={1}
      />
    </div>
  );
};

AdminMenuForm.propTypes = {
  menu: PropTypes.shape(),
  onMenuChange: PropTypes.func.isRequired,
};

AdminMenuForm.defaultProps = {
  menu: {},
};

export default AdminMenuForm;
