import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";

const InputForm = (props) => {
  const { content, info, classes, onContentChange, ...others } = props;

  const { id, label, value } = content;

  const handleInputChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.value,
    });
  };

  return (
    <FormControl className={classes.formControl} key={id}>
      <InputLabel shrink className={info ? classes.info : undefined}>
        <p className="fr-text-sm">{label}</p>
        {info && (
          <Tooltip title={info} aria-label="info" placement="top-end" style={{ fontSize: "1.14rem" }}>
            <p>&nbsp;&#9432;</p>
          </Tooltip>
        )}
      </InputLabel>
      <Input value={value} onChange={handleInputChange} {...others} />
    </FormControl>
  );
};

InputForm.propTypes = {
  content: PropTypes.shape().isRequired,
  info: PropTypes.string,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

InputForm.defaultProps = {
  info: null,
};

export default InputForm;
