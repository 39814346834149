import axios from "axios";
import generateCRUD from "components/adminServices/generateCRUD";

const name = "geopoints";
const GeoPointsCRUD = generateCRUD(name);

export default class AdminGeoPointsService extends GeoPointsCRUD {
  static import = ({ file, type, jsonFile }) => {
    const formData = new FormData();
    formData.append("file", file);
    return axios.post(`/${name}/enhance`, formData, { params: { type, jsonFile } }).then((r) => r.data);
  };

  static export = (params) => {
    return axios.get(`/api/${name}/export`, { params, responseType: "blob" }).then((r) => r.data);
  };
}
