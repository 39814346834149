import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ComedecService from "services/ComedecService";

const SelectDepartment = (props) => {
  const { department = "", setDepartment } = props;

  const [departments, setDepartments] = useState("");
  const [getDepartmentsError, setGetDepartmentsError] = useState(false);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(true);

  const [inputValue, setInputValue] = useState(department?.nom || "");

  const getDepartments = async () => {
    try {
      const newDepartments = await ComedecService.getDepartments();
      setDepartments(newDepartments);
      setIsLoadingDepartments(false);
    } catch (e) {
      setGetDepartmentsError(true);
      setIsLoadingDepartments(false);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const handleSelectDepartmentChange = (e, value) => {
    if (value && !(value instanceof String)) {
      const { code } = value;
      const newDepartment = departments.find((d) => d.code === code);
      setDepartment(newDepartment);
      setInputValue(newDepartment?.nom || "");
    } else {
      setInputValue(value || "");
    }
  };

  return (
    <FormControl error={getDepartmentsError}>
      <Autocomplete
        sx={{
          "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
            width: "26px",
            height: "26px",
          },
        }}
        value={department}
        inputValue={inputValue}
        options={departments}
        openText="Ouvrir"
        clearText="Effacer"
        getOptionLabel={(option) => (option ? `${option.code} - ${option.nom}` : "")}
        noOptionsText="Aucun département correspondant"
        onChange={handleSelectDepartmentChange}
        onInputChange={(e, value) => setInputValue(value)}
        disabled={isLoadingDepartments || getDepartmentsError}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            label="Par département"
            name="page"
            placeholder="Sélectionner un département"
            disabled={isLoadingDepartments || getDepartmentsError}
          />
        )}
        closeIcon={
          <IconButton key="clear" aria-label="Supprimer" color="inherit" onClick={() => setDepartment("")}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </FormControl>
  );
};

SelectDepartment.propTypes = {
  department: PropTypes.oneOfType([PropTypes.shape(), PropTypes.oneOf([""])]),
  setDepartment: PropTypes.func.isRequired,
};

export default SelectDepartment;
