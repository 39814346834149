import React, { useContext } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FilePicker from "components/adminComponents/FilePicker";
import AdminContext from "components/adminComponents/AdminContext";

const FileForm = (props) => {
  const { content, classes, onContentChange, image } = props;

  const { id, label, value } = content;

  const { currentPageVersion } = useContext(AdminContext);

  const handleSelectFile = (file) => {
    onContentChange({
      ...content,
      value: JSON.stringify(file),
    });
  };

  let fileValue;
  try {
    fileValue = JSON.parse(value);
  } catch (e) {
    fileValue = value;
  }

  return (
    <FormControl className={classes.formControl} key={id}>
      <label>{label}</label>
      <FilePicker onSelectFile={handleSelectFile} pageVersion={currentPageVersion} image={image} file={fileValue} />
    </FormControl>
  );
};

FileForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  image: PropTypes.bool,
};

FileForm.defaultProps = {
  image: false,
};

export default FileForm;
