import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useState } from "react";

export const SideBarElement = (props) => {
  const { children = null, focus = false, icon = null, iconDSFR = null, ...others } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: "relative",
        fontSize: "0.875rem",
        pl: 6,
        pr: 3,
        height: "45px",
        cursor: "pointer",
        bgcolor: focus ? "#ccc" : "transparent",
        "& > a": {
          fontSize: "0.875rem",
        },
        "&:hover": {
          bgcolor: focus ? "#ccc" : "#ebebeb",
        },
      }}
      {...others}
    >
      {(icon || iconDSFR) && (
        <Icon icon={icon} iconDSFR={iconDSFR} sx={{ position: "absolute", left: "18px", top: "calc(50% - 11px)" }} />
      )}
      {children}
    </Stack>
  );
};

SideBarElement.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  iconDSFR: PropTypes.string,
  focus: PropTypes.bool,
};

export const SideBarGroup = (props) => {
  const { name, children = null, ...others } = props;

  const [open, setOpen] = useState(false);

  const handleClickGroupName = () => setOpen(!open);

  const icon = open ? "chevron-up" : "chevron-down";
  const iconDSFR = open ? "arrow-up-s-line" : "arrow-down-s-line";

  return (
    <>
      <SideBarElement onClick={handleClickGroupName} {...others}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ flex: "1" }}>
          {name}
          <Icon icon={icon} iconDSFR={iconDSFR} />
        </Stack>
      </SideBarElement>
      <Box sx={{ height: open ? "auto" : 0, overflow: "hidden", pl: 2 }}>{children}</Box>
    </>
  );
};

SideBarGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export const SideBarDivider = () => {
  return <Box sx={{ borderBottom: "1px solid #ccc", m: 2 }} />;
};

const SideBar = (props) => {
  const { open, children } = props;

  return (
    <Box
      sx={{
        zIndex: 1200,
        position: "fixed",
        top: 81,
        left: `${open ? "0" : "-300px"}`,
        bottom: 0,
        bgcolor: "#fff",
        borderRight: "1px solid #ccc",
        pt: 3,
        width: "300px",
        transition: "left linear 350ms",
        overflow: "auto",
      }}
    >
      {children}
    </Box>
  );
};

SideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default SideBar;
