import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Button from "components/templatesComponents/Button";
import { ModalActions } from "components/templatesComponents/Modal";
import { useTheme } from "@material-ui/styles";

const ModalConfirm = (props) => {
  const {
    title,
    text,
    confirmText,
    cancelText,
    detailContent,
    onConfirm,
    onCancel,
    confirmButtonProps,
    cancelButtonProps,
  } = props;

  const theme = useTheme();

  const [seeDetail, setSeeDetail] = useState(false);

  return (
    <div>
      <h2>{title}</h2>
      {text && <Box mb={2}>{text}</Box>}
      {detailContent && (
        <>
          <Box
            sx={{ color: theme.palette.primary.main, cursor: "pointer", textDecoration: "underline", my: 2 }}
            onClick={() => setSeeDetail(!seeDetail)}
          >
            {seeDetail ? "Masquer" : "Voir"} le détail
          </Box>
          {seeDetail && detailContent}
        </>
      )}
      <ModalActions>
        <Button outlined onClick={onCancel} {...cancelButtonProps}>
          {cancelText}
        </Button>
        <Button onClick={onConfirm} {...confirmButtonProps}>
          {confirmText}
        </Button>
      </ModalActions>
    </div>
  );
};

ModalConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.shape(),
  detailContent: PropTypes.shape(),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonProps: PropTypes.shape({}),
  cancelButtonProps: PropTypes.shape({}),
};

ModalConfirm.defaultProps = {
  text: {},
  detailContent: null,
  confirmText: "Confirmer",
  cancelText: "Annuler",
  confirmButtonProps: {},
  cancelButtonProps: {},
};

export default ModalConfirm;
