import axios from "axios";
// import i18n from "../../src/components/StandAlone/i18n/i18n";
import SnackbarUtil from "da-ged-web/utils/SnackbarUtil";
import axiosCatchError from "./AxiosCatchErrorImplement";

const i18n = { t: (m) => m };

const API_GED_URL = "/da-ged";

export default class FileService {
  static createFile = ({ file, nodeId } = {}) => {
    const options = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percentCompleted < 100) {
          SnackbarUtil.setSnackbar({
            status: "in_progress",
            message: `${percentCompleted}% ${i18n.t("Import in progress")}`,
            progress: percentCompleted,
          });
        }
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    return axios
      .post(`${API_GED_URL}/api${nodeId ? `/folders/${nodeId}` : ""}/files`, formData, options)
      .catch((error) => axiosCatchError(error));
  };

  static getPreview = (nodeId) => {
    return axios
      .get(`${API_GED_URL}/api/files/${nodeId}/preview`, {
        responseType: "blob", // Force to receive data in a Blob Format
      })
      .catch((error) => axiosCatchError(error));
  };

  static deleteFile = (nodeId) => {
    return axios.delete(`${API_GED_URL}/api/files/${nodeId}`).catch((error) => axiosCatchError(error));
  };

  static trulyDeleteFile = (nodeId) => {
    return axios.delete(`${API_GED_URL}/api/trash/files/${nodeId}`).catch((error) => axiosCatchError(error));
  };

  static renameFile = ({ fileId, name } = {}) => {
    return axios
      .patch(`${API_GED_URL}/api/files/${fileId}`, {
        name,
      })
      .then((response) => {
        if (response) {
          SnackbarUtil.setSnackbar({
            status: "success",
            message: i18n.t("Node renamed"),
          });
        }
      })
      .catch((error) => {
        const customError = axiosCatchError(error);

        if (customError.error === "name.already.exist") {
          throw new Error(i18n.t("File exist"));
        } else {
          SnackbarUtil.setSnackbar({
            status: "error",
            message: i18n.t("Node renamed error"),
          });
          throw new Error();
        }
      });
  };

  static downloadFile = (fileId) => {
    return axios
      .get(`${API_GED_URL}/api/files/${fileId}?alt=media`)
      .then((archiveUrl) => {
        return axios
          .get(`${API_GED_URL}/${archiveUrl.data.downloadUrl}`, {
            responseType: "blob",
          })
          .then((response) => {
            return response.data;
          })
          .catch((error) => axiosCatchError(error));
      })
      .catch((error) => axiosCatchError(error));
  };

  static moveFile = ({ fileId, parent, message } = {}) => {
    return axios
      .patch(`${API_GED_URL}/api/files/${fileId}`, {
        parent,
      })
      .then((response) => {
        if (response) {
          SnackbarUtil.setSnackbar({
            status: "success",
            message,
          });
        }
      })
      .catch((error) => {
        const moveError = axiosCatchError(error);
        if (moveError && moveError.error === "folder.not.found") {
          throw new Error(i18n.t("Folder not found"));
        } else if (moveError && moveError.error === "name.already.exist") {
          throw new Error(i18n.t("File isn't move"));
        } else {
          SnackbarUtil.setSnackbar({
            status: "error",
            message: i18n.t("File operation impossible"),
          });
          throw new Error();
        }
      });
  };

  static duplicateFile = ({ fileId, fileName } = {}) => {
    return axios
      .post(`${API_GED_URL}/api/files/${fileId}/copy`, {
        name: i18n.t("Copy of") + fileName,
      })
      .catch((error) => axiosCatchError(error));
  };

  static restoreFile = (nodeId) => {
    return new Promise(async (resolve) => {
      try {
        await axios.patch(`${API_GED_URL}/api/trash/files/${nodeId}`, {
          deleted: false,
        });
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    });
  };

  static publish = ({ file }) => {
    const { id: fileId } = file;

    return axios.post(`${API_GED_URL}/api/files/${fileId}/publish`).catch((error) => axiosCatchError(error));
  };
}
