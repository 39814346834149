import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Cancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/styles";
import AdminContentModal, { canBeAdministrated } from "components/adminComponents/AdminContentModal";
import SelectContentModal from "components/adminComponents/SelectContentModal";
import { getAllDefaultContents } from "utils/adminContentsUtils";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
  },
  delete: {
    position: "absolute",
    top: "-12px",
    right: "-12px",
    cursor: "pointer",
  },
}));

const DynamicAdmin = (props) => {
  const {
    children,
    onAddContent,
    onRemoveContent,
    contentKey,
    addContentText,
    deleteContentText,
    contentsTypes,
    limit,
  } = props;

  const classes = useStyles();

  const [insertIndex, setInsertIndex] = useState();
  const [displayModalSelectContent, setDisplayModalSelectContent] = useState(false);
  const [selectedContent, setSelectedContent] = useState();

  const availableContents = getAllDefaultContents().filter((content) => contentsTypes.includes(content.type));

  const handleAddContent = (content, index) => {
    if (typeof onAddContent === "function") {
      const { key, value = "", children: contentChildren, type } = content;
      onAddContent(
        {
          key: contentKey || key,
          value,
          type,
          children: contentChildren,
        },
        index !== undefined ? index : insertIndex
      );
    }
    setSelectedContent();
  };

  const handleSelectContent = (content, index) => {
    setDisplayModalSelectContent(false);
    if (canBeAdministrated(content)) {
      if (index) {
        setInsertIndex(index);
      }
      setSelectedContent(content);
    } else {
      handleAddContent(content, index);
    }
  };

  const handleClickAddContent = (index) => () => {
    if (availableContents.length > 1) {
      setDisplayModalSelectContent(true);
      setInsertIndex(index);
    } else if (availableContents.length === 1) {
      handleSelectContent(availableContents[0], index);
    }
  };

  const handeRemoveContent = (index) => () => {
    onRemoveContent(index, contentKey);
  };

  const AddContentButton = (p) => {
    const { index } = p;
    return (
      availableContents.length > 0 && (
        <Button variant="outlined" onClick={handleClickAddContent(index)}>
          {addContentText}
        </Button>
      )
    );
  };

  const appendButtonToNode = (node, index, allNodes = []) =>
    !!node && (
      <Fragment key={node.key}>
        {index === 0 && allNodes.length < limit && <AddContentButton index={0} />}
        <div className={classes.wrapper}>
          <div style={{ position: "relative" }}>
            {node}
            <span title={deleteContentText}>
              <Cancel className={classes.delete} onClick={handeRemoveContent(index)} />
            </span>
          </div>
          {availableContents.length > 0 && allNodes.length < limit && <AddContentButton index={index + 1} />}
        </div>
      </Fragment>
    );

  const renderedChildren =
    typeof children === "function" ? children(appendButtonToNode) : React.Children.map(children, appendButtonToNode);
  let hasChildren = !!renderedChildren;
  if (Array.isArray(renderedChildren)) {
    hasChildren = renderedChildren.filter((c) => !!c).length > 0;
  }

  return (
    <Fragment>
      {!hasChildren && <AddContentButton index={0} />}
      {renderedChildren}
      <SelectContentModal
        open={displayModalSelectContent}
        contents={availableContents}
        onSelectContent={handleSelectContent}
        onClose={() => setDisplayModalSelectContent(false)}
      />
      <AdminContentModal
        open={!!selectedContent}
        content={selectedContent}
        onValidate={handleAddContent}
        onClose={() => setSelectedContent()}
      />
    </Fragment>
  );
};

DynamicAdmin.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onAddContent: PropTypes.func,
  onRemoveContent: PropTypes.func.isRequired,
  addContentText: PropTypes.string,
  deleteContentText: PropTypes.string,
  contentKey: PropTypes.string,
  contentsTypes: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DynamicAdmin.defaultProps = {
  children: null,
  onAddContent: () => {},
  addContentText: "Ajouter un élement",
  deleteContentText: null,
  contentKey: null,
  contentsTypes: [],
  limit: Infinity,
};

export default DynamicAdmin;
