import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { formControlStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import PropTypes from "prop-types";
import React from "react";

const SelectForm = (props) => {
  const { content, onContentChange, disabled = false } = props;

  const { id, label, value, options, width } = content;

  const handleSelectChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.value,
    });
  };

  return (
    <FormControl variant="standard" sx={formControlStyled(width)} key={id} disabled={disabled}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        value={value}
        // native
        variant="standard"
        onChange={handleSelectChange}
        displayEmpty
        MenuProps={{ getContentAnchorEl: null }}
        sx={{ fontStyle: !value ? "italic" : "inherit" }}
      >
        <MenuItem value="" sx={{ fontStyle: "italic" }}>
          -- Sélectionner --
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectForm;
