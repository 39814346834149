import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CKEditor from "ckeditor4-react";

CKEditor.editorUrl = "/ckeditor/ckeditor.js";

function reducer(state, action) {
  return action.value;
}

const CKEditorCustom = (props) => {
  const { onChange, data, debounce, config, maxSize, ...others } = props;

  const timeoutRef = useRef(null);
  const [localData, dispatch] = React.useReducer(reducer, data);

  useEffect(() => {
    dispatch({ value: data });
  }, [data]);

  useEffect(() => {
    if (localData !== data) {
      if (timeoutRef && timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        onChange(localData);
      }, debounce);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, localData]);

  const newConfig = config;
  if (maxSize) {
    newConfig.extraPlugins = "wordcount";
    newConfig.wordcount = {
      showParagraphs: false,
      showWordCount: false,
      showCharCount: true,
      countSpacesAsChars: true,
      maxCharCount: maxSize,
    };
  }

  return (
    <CKEditor
      onBeforeLoad={(CKEDITOR) => {
        // eslint-disable-next-line no-param-reassign
        CKEDITOR.disableAutoInline = true;
      }}
      data={localData}
      onChange={(e) => dispatch({ value: e.editor.getData() })}
      {...others}
      config={newConfig}
    />
  );
};

CKEditorCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.string,
  debounce: PropTypes.number,
  config: PropTypes.shape({}),
  maxSize: PropTypes.number,
};

CKEditorCustom.defaultProps = {
  data: "",
  debounce: 2000,
  config: {},
  maxSize: null,
};

export default CKEditorCustom;
