import Box from "@mui/material/Box";
import CardForm from "components/adminComponents/contentForms/CardForm";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import ContactFormProfileListForm from "components/adminComponents/contentForms/ContactFormProfileListForm";
import ContactFormSubjectListForm from "components/adminComponents/contentForms/ContactFormSubjectListForm";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import DSFRSubpageForm from "components/adminComponents/contentForms/DSFRSubpageForm";
import FileForm from "components/adminComponents/contentForms/FileForm";
import IconForm from "components/adminComponents/contentForms/IconForm";
import ImageForm from "components/adminComponents/contentForms/ImageForm";
import InputForm from "components/adminComponents/contentForms/InputForm";
import LinkForm from "components/adminComponents/contentForms/LinkForm";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import SiteOnboardingForm from "components/adminComponents/contentForms/SiteOnboardingForm";
import SiteSettingsForm from "components/adminComponents/contentForms/SiteSettingsForm";
import SliderForm from "components/adminComponents/contentForms/SliderForm";
import SubpageForm from "components/adminComponents/contentForms/SubpageForm";
import TabForm from "components/adminComponents/contentForms/TabForm";
import TabsForm from "components/adminComponents/contentForms/TabsForm";
import TextAreaForm from "components/adminComponents/contentForms/TextAreaForm";
import allNews from "components/templates/news/allNews";
import lastNews from "components/templates/news/lastNews";
import news from "components/templates/news/news";
import PropTypes from "prop-types";
import React from "react";
import elementsTypes from "utils/elementsTypes";

const ContentForm = (props) => {
  const { content, topLevel = false, onContentChange = null } = props;

  const { type, id, label, children, editionModal } = content;

  if (editionModal && !topLevel) {
    return null;
  }

  let Form = null;
  let formProps = {};

  switch (type) {
    case elementsTypes.EDITABLE_INPUT:
    case elementsTypes.INPUT:
    case elementsTypes.URL:
    case elementsTypes.NUMERIC:
      Form = InputForm;
      break;
    case elementsTypes.SHORT_TEXT:
      Form = TextAreaForm;
      break;
    case elementsTypes.CHECKBOX:
      Form = CheckboxForm;
      break;
    case elementsTypes.SELECT:
      Form = SelectForm;
      break;
    case elementsTypes.IMAGE:
      Form = ImageForm;
      break;
    case elementsTypes.FILE_IMAGE:
      formProps = { image: true };
      Form = FileForm;
      break;
    case elementsTypes.FILE:
      Form = FileForm;
      break;
    case elementsTypes.LINK:
      Form = LinkForm;
      break;
    case elementsTypes.CARD:
      Form = CardForm;
      break;
    case elementsTypes.CARD_NEWS:
      formProps = { template: [news.key] };
      Form = CardForm;
      break;
    case elementsTypes.OLD_CARD_NEWS:
      formProps = { template: [allNews.key, lastNews.key], inAllSites: true };
      Form = CardForm;
      break;
    case elementsTypes.DSFR_CARD:
    case elementsTypes.TILE_CARD:
      formProps = { inAllSites: true };
      Form = CardForm;
      break;
    case elementsTypes.DSFR_SUBPAGE:
      Form = DSFRSubpageForm;
      break;
    case elementsTypes.SUBPAGE:
      Form = SubpageForm;
      break;
    case elementsTypes.ICON:
      Form = IconForm;
      break;
    case elementsTypes.TAB:
    case elementsTypes.TAB_CARDS:
    case elementsTypes.TAB_TILE_CARDS:
      Form = TabForm;
      break;
    case elementsTypes.TABS:
    case elementsTypes.TABS_CARDS:
    case elementsTypes.TABS_TILE_CARDS:
      Form = TabsForm;
      break;
    case elementsTypes.SLIDER:
      Form = SliderForm;
      break;
    case elementsTypes.SITE_SETTINGS:
      Form = SiteSettingsForm;
      break;
    case elementsTypes.SITE_ONBOARDING:
      Form = SiteOnboardingForm;
      break;
    case elementsTypes.CONTACT_FORM_PROFILE_LIST:
      Form = ContactFormProfileListForm;
      break;
    case elementsTypes.CONTACT_FORM_SUBJECT_LIST:
      Form = ContactFormSubjectListForm;
      break;
    default:
      break;
  }
  if (Form) {
    return <Form content={content} onContentChange={onContentChange} {...formProps} />;
  }
  if (children) {
    const childrenForm = children
      .map((child) => {
        const onChildChange = (newChild) => {
          onContentChange({
            ...content,
            children: children.map((c) => (c === child ? newChild : c)),
          });
        };
        return ContentForm({ content: child, onContentChange: onChildChange, topLevel: false });
      })
      .filter((c) => !!c);
    if (childrenForm.length) {
      if (topLevel) {
        return (
          <>
            <Box component="h2" sx={formStyled.title}>
              {label}
            </Box>
            <div>{childrenForm}</div>
          </>
        );
      }
      return (
        <Box component="fieldset" key={id} sx={formStyled.fieldset}>
          <legend>{label}</legend>
          {childrenForm}
        </Box>
      );
    }
  }
  return null;
};

ContentForm.propTypes = {
  content: PropTypes.shape().isRequired,
  topLevel: PropTypes.bool,
  onContentChange: PropTypes.func,
};

export default ContentForm;
