import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React from "react";
import { generateContentId, getDefaultContentByType } from "utils/adminContentsUtils";

const AdminListForm = (props) => {
  const { contents, elementsType = null, elementsKey = null, onContentsChange, inline = false, children } = props;

  const swapArrayItems = (array, index1, index2) => {
    const newArray = [...array];
    if (index1 !== -1 && index2 !== -1) {
      const tmpItem = newArray[index1];
      newArray[index1] = newArray[index2];
      newArray[index2] = tmpItem;
    }
    return newArray;
  };

  const handleMove = (childId, up = false) => {
    const contentToMove = contents.find((c) => c.id === childId);
    const contentIndex = contents.indexOf(contentToMove);
    const targetIndex = up ? contentIndex - 1 : contentIndex + 1;
    onContentsChange(swapArrayItems(contents, contentIndex, targetIndex));
  };

  const handleClickDelete = (childId) => {
    onContentsChange([...contents.filter((c) => c.id !== childId)]);
  };

  const handleClickAdd = () => {
    const defaultContent = getDefaultContentByType(elementsType);
    onContentsChange([...contents, { key: elementsKey, ...generateContentId(defaultContent) }]);
  };

  return (
    <>
      {React.Children.map(
        children,
        (child, index) =>
          child && (
            <Stack
              flexDirection={inline ? "row" : "column"}
              justifyContent={inline ? "space-between" : "flex-start"}
              alignItems={inline ? "center" : "stretch"}
              key={child.key}
              sx={{ my: 0.75 }}
            >
              <Box sx={{ flex: 1 }}>{child}</Box>
              <Box sx={{ alignSelf: inline ? "auto" : "flex-end" }}>
                <IconButton
                  onClick={() => handleMove(child.key, true)}
                  edge="end"
                  aria-label="Monter"
                  disabled={index === 0}
                >
                  <Icon icon="chevron-up" iconDSFR="arrow-up-s-line" title="Monter" />
                </IconButton>
                <IconButton
                  onClick={() => handleMove(child.key)}
                  edge="end"
                  aria-label="Descendre"
                  disabled={index === children.length - 1}
                >
                  <Icon icon="chevron-down" iconDSFR="arrow-down-s-line" title="Descendre" />
                </IconButton>
                <IconButton onClick={() => handleClickDelete(child.key)} edge="end" aria-label="Supprimer">
                  <Icon icon="trash" iconDSFR="delete-line" title="Supprimer" />
                </IconButton>
              </Box>
            </Stack>
          )
      )}
      {elementsType && <Button onClick={handleClickAdd}>Ajouter</Button>}
    </>
  );
};

AdminListForm.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  elementsType: PropTypes.string,
  elementsKey: PropTypes.string,
  onContentsChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
};

export default AdminListForm;
