import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import InputForm from "components/adminComponents/contentForms/InputForm";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import AdminListForm from "components/adminComponents/contentForms/AdminListForm";
import elementsTypes from "utils/elementsTypes";
import ExpansionPanel from "components/templatesComponents/ExpansionPanel";
import CKEditor from "ckeditor4-react";
import { fullToolbarEditor } from "utils/commonUtils";

CKEditor.editorUrl = "/ckeditor/ckeditor.js";

const ContactFormSubjectListForm = (props) => {
  const { content, classes, onContentChange } = props;
  const { id, label, children = [] } = content;

  const objectsChildren = children.filter((c) => c.type === elementsTypes.CONTACT_FORM_SUBJECT) || [];

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: newChildren,
    });
  };

  return (
    <div key={id}>
      <h2 className={classes.title}>{label}</h2>

      <div className={classes.fieldset}>
        <h4>Objets</h4>

        <AdminListForm
          inline
          contents={objectsChildren}
          elementsType={elementsTypes.CONTACT_FORM_SUBJECT}
          elementsKey="ContactFormSubjects"
          onContentsChange={(newContents) => handleChildrenChange(objectsChildren, newContents)}
        >
          {objectsChildren.map((currentObject) => (
            <ExpansionPanel
              key={currentObject.id}
              summary={(currentObject.children.find((c) => c.key === "title") || {}).value}
              details={
                <Fragment>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputForm
                        content={{
                          ...currentObject.children.find((c) => c.key === "title"),
                          label: "Objet",
                        }}
                        info={
                          <p style={{ color: "#fff" }}>
                            Si ce champ n&apos;est pas rempli, cet objet n&apos;apparaîtra pas dans la liste
                          </p>
                        }
                        onContentChange={(newContent) =>
                          handleChildChange(currentObject, {
                            ...currentObject,
                            children: (currentObject.children || []).map((c) => (c.key === "title" ? newContent : c)),
                          })
                        }
                        classes={classes}
                      />
                    </Grid>
                  </Grid>
                  <InputForm
                    content={{
                      ...currentObject.children.find((c) => c.key === "recipient"),
                      label: "E-mail de destination",
                    }}
                    info={
                      <p style={{ color: "#fff" }}>
                        En cas d&apos;absence de réponse automatique, si ce champ n&apos;est pas rempli, cet objet
                        n&apos;apparaîtra pas dans la liste
                      </p>
                    }
                    onContentChange={(newContent) =>
                      handleChildChange(currentObject, {
                        ...currentObject,
                        children: (currentObject.children || []).map((c) => (c.key === "recipient" ? newContent : c)),
                      })
                    }
                    classes={classes}
                  />

                  <CheckboxForm
                    content={{
                      ...(currentObject.children || []).find((c) => c.key === "autoReply"),
                      label: "Activer la réponse automatique",
                    }}
                    onContentChange={(newContent) =>
                      handleChildChange(currentObject, {
                        ...currentObject,
                        children: (currentObject.children || []).map((c) => (c.key === "autoReply" ? newContent : c)),
                      })
                    }
                    classes={classes}
                  />
                  {((currentObject.children || []).find((c) => c.key === "autoReply") || {}).value === "true" && (
                    <CKEditor
                      onBeforeLoad={(CKEDITOR) => {
                        // eslint-disable-next-line no-param-reassign
                        CKEDITOR.disableAutoInline = true;
                      }}
                      key={currentObject.id}
                      onChange={(e) =>
                        handleChildChange(currentObject, {
                          ...currentObject,
                          children: (currentObject.children || []).map((c) =>
                            c.key === "replyMessage" ? { ...c, value: encodeURIComponent(e.editor.getData()) } : c
                          ),
                        })
                      }
                      data={decodeURIComponent(
                        ((currentObject.children || []).find((c) => c.key === "replyMessage") || {}).value
                      )}
                      config={{ toolbar: fullToolbarEditor }}
                    />
                  )}
                </Fragment>
              }
            />
          ))}
        </AdminListForm>
      </div>
    </div>
  );
};

ContactFormSubjectListForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default ContactFormSubjectListForm;
