import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputForm from "components/adminComponents/contentForms/InputForm";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import Link from "components/templatesComponents/Link";
import Icon from "components/templatesComponents/Icon";
import dsfrIcons from "apps/front-office/public/utility/icons/utility-icons.json";

const IconForm = (props) => {
  const { content, classes, onContentChange } = props;

  const { id, children, label } = content;

  const typeChild = children.find((c) => c.key === "type");
  const iconChild = children.find((c) => c.key === "icon");
  const groupChild = children.find((c) => c.key === "groupDSFR");
  const iconDSFRChild = children.find((c) => c.key === "iconDSFR");

  const theme = useTheme();

  const handleChildChange = (oldChild) => (newChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === oldChild) {
          return newChild;
        }
        return child;
      }),
    });
  };

  return (
    <fieldset key={id} className={classes.fieldset}>
      <legend>{label || "Icône"}</legend>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <SelectForm content={groupChild} onContentChange={handleChildChange(groupChild)} classes={classes} />
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.iconDSFRItem}
          style={{
            border: `1px solid ${iconDSFRChild?.value ? theme.palette.secondary.main : "grey"}`,
            backgroundColor: iconDSFRChild?.value && theme.palette.secondary[20],
          }}
        >
          <Icon
            iconDSFR={iconDSFRChild?.value}
            title={iconDSFRChild?.value}
            isHidden={false}
            aria-label={`Logo sélectionné: ${iconDSFRChild?.value}`}
          />
        </Grid>
        <Grid item xs={1} className={classes.iconDSFRItem}>
          {iconDSFRChild?.value && (
            <IconButton
              onClick={() =>
                handleChildChange(iconDSFRChild)({
                  ...iconDSFRChild,
                  value: "",
                })
              }
              aria-label="Supprimer l'icône"
            >
              <Icon iconDSFR="close-line" style={{ color: theme.palette.secondary.main }} title="Supprimer l'icône" />
            </IconButton>
          )}
        </Grid>
        {dsfrIcons &&
          groupChild &&
          dsfrIcons[groupChild.value]?.map((icon) => (
            <Grid
              item
              xs={1}
              className={classnames(classes.iconDSFRItem, classes.hover)}
              style={{
                cursor: "pointer",
                backgroundColor: icon === iconDSFRChild?.value && theme.palette.secondary[20],
                border: icon === iconDSFRChild?.value && `1px solid ${theme.palette.secondary.main}`,
              }}
              onClick={() =>
                handleChildChange(iconDSFRChild)({
                  ...iconDSFRChild,
                  value: icon,
                })
              }
            >
              <Icon iconDSFR={icon} title={icon} />
            </Grid>
          ))}
      </Grid>
      <Grid container spacing={1} className={classes.iconForm}>
        <Grid item xs={12}>
          <i>(Utilisation dépréciée, préférer les icônes du DSFR ci-dessus)</i>
        </Grid>
        <Grid item xs={6}>
          <InputForm content={iconChild} onContentChange={handleChildChange(iconChild)} classes={classes} />
        </Grid>
        <Grid item xs={6}>
          <SelectForm content={typeChild} onContentChange={handleChildChange(typeChild)} classes={classes} />
        </Grid>
        <Link
          url="https://fontawesome.com/icons?d=gallery&s=light"
          external
          underline="always"
          color="primary"
          style={{ fontSize: "0.79rem" }}
        >
          Voir la liste des icônes
        </Link>
      </Grid>
    </fieldset>
  );
};

IconForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default IconForm;
