import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { StaticRouter } from "react-router";
import PropTypes from "prop-types";
import Modal, { ModalActions } from "components/templatesComponents/Modal";
import Button from "components/templatesComponents/Button";
import { enhanceContent } from "utils/adminContentsUtils";
import ContentForm from "components/adminComponents/contentForms/ContentForm";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import themes from "../../apps/front-office/client/themes/themes";

const theme = themes.ANTS;

export const canBeAdministrated = (content) => {
  const div = document.createElement("div");
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <StaticRouter location={window.location}>
        <ContentForm content={content} topLevel />
      </StaticRouter>
    </ThemeProvider>,
    div
  );
  const hasContentForm = !!div.innerHTML;
  div.remove();
  return hasContentForm;
};

const AdminContentModal = (props) => {
  const { open, content, onValidate, onClose, ...others } = props;
  const [fullContent, setFullContent] = useState({});

  useEffect(() => {
    if (open) {
      setFullContent(enhanceContent(content));
    }
  }, [open, content]);

  const handleClickValidate = () => {
    onValidate(fullContent);
  };

  return (
    <Modal
      aria-labelledby="admin-content"
      aria-describedby="content-administration"
      open={open}
      onClose={onClose}
      size="md"
      {...others}
    >
      <ContentForm topLevel content={fullContent} onContentChange={setFullContent} />
      <ModalActions>
        <Button onClick={onClose} outlined>
          Annuler
        </Button>
        <Button onClick={handleClickValidate}>Valider</Button>
      </ModalActions>
    </Modal>
  );
};

AdminContentModal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.shape(),
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AdminContentModal.defaultProps = {
  open: false,
  content: null,
};

export default AdminContentModal;
