import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import IconForm from "components/adminComponents/contentForms/IconForm";
import InputForm from "components/adminComponents/contentForms/InputForm";
import LinkForm from "components/adminComponents/contentForms/LinkForm";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import SelectIconForm from "components/adminComponents/contentForms/SelectIconForm";
import TextAreaForm from "components/adminComponents/contentForms/TextAreaForm";
import SelectPage from "components/adminComponents/SelectPage";
import PageVersionContext from "components/PageVersionContext";
import news from "components/templates/news/news";
import Icon from "components/templatesComponents/Icon";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext, useMemo, useState } from "react";
import sanitizeHtml from "sanitize-html";
import SiteService from "services/SiteService";
import elementsTypes from "utils/elementsTypes";

const CardForm = (props) => {
  const { content, onContentChange = null, template = "", inAllSites = false } = props;

  const { id, label, children } = content;

  const { currentPageVersion = {} } = useContext(PageVersionContext);
  const { page = {} } = currentPageVersion;
  const { lang } = page;

  const pageChild = children.find((c) => c.type === elementsTypes.PAGE);
  const titleChild = children.find((c) => c.key === "title");
  const descriptionChild = children.find((c) => c.key === "description");
  const linkChild = children.find((c) => c.type === elementsTypes.LINK);
  const iconChild = children.find((c) => c.key === "icon");
  const selectChild = children.find((c) => c.key === "site");
  const checkBoxChild = children.find((c) => c.type === elementsTypes.CHECKBOX);

  const [pageCard, setPageCard] = useState(!!pageChild.value || !id);

  const handleCardTypeChange = (e) => {
    setPageCard(e.target.checked);
    if (!e.target.checked) {
      onContentChange({
        ...content,
        children: children.map((child) => {
          if (child === pageChild) {
            return {
              ...child,
              value: "",
            };
          }
          return child;
        }),
      });
    }
  };

  const [{ data: allSites = [] }] = useAxiosCache(SiteService.getConfig("getAllSites"));

  const handleSelectPage = (p) => {
    const { versions, ...others } = p;
    versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
    const [lastVersion] = versions;
    if (lastVersion) {
      const pageVersion = {
        ...lastVersion,
        page: { ...others },
      };
      onContentChange({
        ...content,
        children: children.map((child) => {
          // Update page value
          if (child === pageChild) {
            return {
              ...child,
              value: pageVersion,
            };
          }
          // Update title value
          if (child === titleChild) {
            return {
              ...child,
              value: lastVersion.title || "",
            };
          }
          // Update description value
          if (child === descriptionChild) {
            const description = decodeURIComponent(lastVersion.shortDescription || "")?.replace(/<[^>]*>/g, "") || "";
            return {
              ...child,
              value: sanitizeHtml(description),
            };
          }
          // Update link value
          if (child === linkChild) {
            return {
              ...child,
              children: linkChild.children.map((c) => {
                if (c.type === elementsTypes.PAGE) {
                  return {
                    ...c,
                    value: pageVersion,
                  };
                }
                if (c.type === elementsTypes.URL) {
                  return {
                    ...c,
                    value: "",
                  };
                }
                return c;
              }),
            };
          }
          // Update icon value
          if (child === iconChild && child.type === elementsTypes.SELECT) {
            const findSiteById = allSites?.find((site) => site.id === p.siteId);
            const { name: siteNameById } = findSiteById || {};
            return {
              ...child,
              value: siteNameById || "",
            };
          }
          return child;
        }),
      });
    }
  };

  const handleChange = (oldChild) => (newChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === oldChild) {
          return newChild;
        }
        return child;
      }),
    });
  };

  const hasTemplate = template.length;

  const filters = useMemo(() => (hasTemplate ? [`versions.template||in||${template}`] : []), [hasTemplate, template]);

  return (
    <div key={id}>
      <Box component="h2" sx={formStyled.title}>
        {label}
      </Box>
      <Grid container alignItems="center" spacing={2} sx={{ mb: selectChild ? -2 : 0 }}>
        <Grid item xs={12} md={8}>
          <SelectPage
            currentPage={pageChild.value && pageChild.value.page}
            onSelectPage={handleSelectPage}
            disabled={!hasTemplate && !pageCard}
            onlyPublishedPages={lang === "FR"}
            filters={filters}
            lang={lang}
            inAllSites={inAllSites}
            selectedSiteName={selectChild?.value}
          />
        </Grid>
        {selectChild && (
          <Grid item xs={12} md={4}>
            <SelectForm content={selectChild} onContentChange={handleChange(selectChild)} />
          </Grid>
        )}
      </Grid>
      {(!hasTemplate || !template.includes(news.key)) && (
        <>
          {!hasTemplate && (
            <FormControlLabel
              control={<Checkbox checked={pageCard} onChange={handleCardTypeChange} disableRipple />}
              label="Renseigner automatiquement"
            />
          )}
          {titleChild && (
            <InputForm
              content={titleChild}
              onContentChange={handleChange(titleChild)}
              disabled={!hasTemplate && pageCard}
            />
          )}
          {descriptionChild && (
            <TextAreaForm
              content={descriptionChild}
              onContentChange={handleChange(descriptionChild)}
              disabled={!hasTemplate && pageCard}
            />
          )}
          {!hasTemplate && (
            <>
              <LinkForm
                content={linkChild}
                onContentChange={handleChange(linkChild)}
                disabled={pageCard}
                inAllSites={inAllSites}
              />
              {iconChild && iconChild.type === elementsTypes.ICON && (
                <>
                  <IconForm content={iconChild} onContentChange={handleChange(iconChild)} />
                  <Grid item sx={{ display: "inline-block", verticalAlign: "middle", mr: 1 }}>
                    <p>
                      <Icon icon="exclamation-circle" iconDSFR="error-warning-line" title="Information" />
                      &nbsp;L&apos;icône ne s&apos;affiche pas sur toutes les cards
                    </p>
                  </Grid>
                </>
              )}
              {iconChild && iconChild.type === elementsTypes.SELECT && (
                <Tooltip
                  title={checkBoxChild.value === "true" ? "L'icône ne s'affiche pas sur les tuiles horizontales" : ""}
                  placement="top-end"
                >
                  <Grid container alignItems="center">
                    <SelectIconForm
                      content={iconChild}
                      onContentChange={handleChange(iconChild)}
                      child={iconChild}
                      disabled={checkBoxChild.value === "true"}
                    >
                      <img
                        src={iconChild.value ? `/logo-${iconChild.value}.svg` : ""}
                        className="fr-responsive-img"
                        alt=""
                      />
                    </SelectIconForm>
                  </Grid>
                </Tooltip>
              )}
              {checkBoxChild && <CheckboxForm content={checkBoxChild} onContentChange={handleChange(checkBoxChild)} />}
            </>
          )}
        </>
      )}
    </div>
  );
};

CardForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func,
  template: PropTypes.string,
  inAllSites: PropTypes.bool,
};

export default CardForm;
