import React, { useState, useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import MuiButton from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import AdminContext from "components/adminComponents/AdminContext";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import ModalConfirm from "components/adminComponents/ModalConfirm";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import AdminGeoPointsService from "components/adminServices/AdminGeoPointsService";
import { alphaNumericFormatDate, emailRegex, latitudeRegex, longitudeRegex } from "utils/commonUtils";
import themes from "apps/front-office/client/themes/themes";

const EasyGrid = (props) => {
  const { grid } = props;

  return grid.map((row) => (
    <Grid container alignItems="center" spacing={4}>
      {(Array.isArray(row) ? row : [row]).map((item) => (
        <Grid item xs>
          {item}
        </Grid>
      ))}
    </Grid>
  ));
};

EasyGrid.propTypes = {
  grid: PropTypes.arrayOf(PropTypes.oneOf[(PropTypes.arrayOf(PropTypes.element), PropTypes.element)]).isRequired,
};

const useStyles = makeStyles(() => ({
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "#eff4fd",
    opacity: "0.5",
    zIndex: 1000,
  },
}));

const AdminGeoPointForm = (props) => {
  const { geoPoint, onSubmit, submitLabel, onCancel, type, getGeoPoints } = props;

  const {
    others = {},
    isActive = false,
    customId,
    latitude,
    longitude,
    created,
    creator,
    updated,
    editor,
    category,
  } = geoPoint;

  const [submitted, setSubmitted] = useState(false);

  const [deleteGeoPointModal, setDeleteGeoPointModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const { displaySuccess, displayError } = useContext(AdminMessageContext);

  const { currentSite } = useContext(AdminContext);

  const classes = useStyles();

  const theme = themes[currentSite.name] || themes.ANTS;

  const handleValidate = useCallback((values) => {
    const { email, latitude: lat, longitude: long, customId: id, name } = values;
    const errors = {};
    if (email && !email.match(emailRegex)) {
      errors.email = "Format d'email incorrect";
    }
    if (lat && !lat.match(latitudeRegex)) {
      errors.latitude = "Latitude incorrecte. (Doit se situer entre -90 et 90 au format xx.xxx)";
    }
    if (long && !long.match(longitudeRegex)) {
      errors.longitude = "Longitude incorrecte. (Doit se situer entre -180 et 180 au format xx.xxx)";
    }
    if (!lat) {
      errors.latitude = "Champs requis";
    }
    if (!long) {
      errors.longitude = "Champs requis";
    }
    if (!id) {
      errors.customId = "Champs requis";
    }
    if (!name) {
      errors.name = "Champs requis";
    }
    return errors;
  }, []);

  const closeDeleteGeoPointModal = () => {
    setDeleteGeoPointModal(false);
  };

  const deleteGeopoint = () => {
    setLoader(true);
    AdminGeoPointsService.update({ id: geoPoint.id, isActive: false })
      .then((inactiveGeoPoint) => AdminGeoPointsService.delete(inactiveGeoPoint))
      .then(() => {
        displaySuccess("Établissement supprimé");
        getGeoPoints();
        setLoader(false);
      })
      .catch(() => {
        displayError("Échec lors de la suppression de l'établissement");
        setLoader(false);
      });
  };

  const handleFormSubmit = useCallback(
    (values, { setSubmitting }) => {
      const { isActive: active, customId: id, latitude: lat, longitude: long, category: cat, ...rest } = values;
      const submitPromise = onSubmit({
        id: geoPoint.id,
        isActive: active,
        customId: id,
        latitude: lat,
        longitude: long,
        category: cat,
        type,
        others: { customId: id, category: cat, ...rest },
      });
      if (submitPromise && submitPromise.then) {
        setSubmitting(true);
        submitPromise.then(() => setSubmitting(false));
      }
    },
    [geoPoint.id, onSubmit, type]
  );

  const categories = useMemo(() => {
    switch (type) {
      case "photographe":
        return [
          { value: "photographe", label: "Photographe" },
          { value: "cabine", label: "Cabine" },
        ];
      case "mairie":
        return [
          { value: "passeport", label: "Passeport" },
          { value: "certification", label: "Certification" },
        ];
      default:
        return [{}, {}];
    }
  }, [type]);

  return (
    <Box p={2} borderColor="grey.500" border={1} style={{ position: "relative" }}>
      {geoPoint.id && typeof onCancel === "function" && (
        <IconButton
          size="small"
          color="secondary"
          onClick={onCancel}
          style={{ position: "absolute", top: "4px", right: "8px" }}
        >
          <Icon iconDSFR="close-line" title="Fermer le détail" />
        </IconButton>
      )}
      <Formik
        initialValues={{
          ...others,
          isActive,
          customId,
          latitude,
          longitude,
          created,
          creator,
          updated,
          editor,
          category,
        }}
        validate={handleValidate}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form
            onSubmit={(e) => {
              setSubmitted(true);
              handleSubmit(e);
            }}
          >
            {(() => {
              const createInput = (name, label, extraProps = {}) => {
                const value = ["created", "updated"].includes(name)
                  ? alphaNumericFormatDate(values[name] || Date.now())
                  : values[name];
                return (
                  <TextField
                    error={submitted && !!errors[name]}
                    label={label}
                    value={value}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={submitted && errors[name]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...extraProps}
                  />
                );
              };
              const cityRow = [createInput("postalCode", "Code postal"), createInput("city", "Ville")];
              const addressRow =
                type && type === "siv"
                  ? [
                      <EasyGrid
                        grid={[[createInput("numVoie", "N° de voie"), createInput("typeVoie", "Type de voie")]]}
                      />,
                      createInput("libelleVoie", "Libellé de voie"),
                    ]
                  : [createInput("address", "Adresse")];
              const specificFields = [
                type && type !== "siv" ? (
                  <TextField
                    id="category"
                    name="category"
                    select
                    label="Type"
                    value={values.category?.toLowerCase() || ""}
                    onChange={handleChange}
                  >
                    {categories.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null,
              ];
              if (type && type === "photographe") {
                specificFields.splice(
                  1,
                  0,
                  <FormControlLabel
                    control={<Checkbox name="ePhoto" checked={values.ePhoto} onChange={handleChange} disableRipple />}
                    label="e-Photo"
                  />
                );
              }
              const otherFields = [
                <EasyGrid grid={[[createInput("latitude", "Latitude *"), createInput("longitude", "Longitude *")]]} />,
                <EasyGrid grid={[specificFields]} />,
              ];

              if (type && type !== "siv") {
                otherFields.splice(1, 0, createInput("address2", "Complément d'adresse"));
              }

              const grid = [
                [
                  createInput("name", "Dénomination *"),
                  <EasyGrid
                    grid={[
                      [
                        createInput("customId", "Identifiant *"),
                        <EasyGrid
                          grid={[
                            [
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="isActive"
                                    checked={values.isActive}
                                    onChange={handleChange}
                                    disableRipple
                                  />
                                }
                                label="Actif"
                              />,
                              geoPoint.id ? (
                                <IconButton
                                  onClick={() => setDeleteGeoPointModal(true)}
                                  aria-label="Supprimer"
                                  size="small"
                                  color="secondary"
                                >
                                  <Icon icon="trash-alt" title="Supprimer" />
                                </IconButton>
                              ) : null,
                            ],
                          ]}
                        />,
                      ],
                    ]}
                  />,
                ],
                addressRow.concat(cityRow),
                otherFields,
                [
                  createInput("phone", "Téléphone"),
                  createInput("email", "Email"),
                  createInput("webSite", "Site internet"),
                ],
                [
                  createInput("openingTimeMonday", "Lundi"),
                  createInput("openingTimeTuesday", "Mardi"),
                  createInput("openingTimeWednesday", "Mercredi"),
                  createInput("openingTimeThursday", "Jeudi"),
                  createInput("openingTimeFriday", "Vendredi"),
                  createInput("openingTimeSaturday", "Samedi"),
                  createInput("openingTimeSunday", "Dimanche"),
                ],
                [
                  createInput("created", "Créé le", { disabled: true }),
                  createInput("creator", "Créé par", { disabled: true }),
                  createInput("updated", "Mis à jour le", { disabled: true }),
                  createInput("editor", "Mis à jour par", { disabled: true }),
                ],
              ];
              return <EasyGrid grid={grid} />;
            })()}
            <Box mt={2}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  {typeof onCancel === "function" && (
                    <MuiButton
                      variant="contained"
                      color="default"
                      type="button"
                      size="large"
                      disabled={isSubmitting}
                      onClick={onCancel}
                    >
                      Annuler
                    </MuiButton>
                  )}
                </Grid>
                <Grid item>
                  <Button primary type="submit" disabled={isSubmitting}>
                    {submitLabel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
      {loader && (
        <Grid container justifyContent="center" alignItems="center" className={classes.loader}>
          <CircularProgress />
        </Grid>
      )}
      <Modal open={deleteGeoPointModal} size="sm" onClose={closeDeleteGeoPointModal}>
        <ModalConfirm
          title={`Supprimer "${geoPoint?.others?.name}"`}
          text={
            <Box pb={2}>
              Attention cette action est irréversible.
              <br />
              L&apos;établissement pourra être réimporté via un csv ou recréé manuellement.
            </Box>
          }
          confirmText="Supprimer"
          confirmButtonProps={{ style: { backgroundColor: theme.palette.error.main } }}
          onConfirm={() => {
            deleteGeopoint();
            closeDeleteGeoPointModal();
          }}
          onCancel={closeDeleteGeoPointModal}
        />
      </Modal>
    </Box>
  );
};

AdminGeoPointForm.propTypes = {
  geoPoint: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  onCancel: PropTypes.func,
  type: PropTypes.string.isRequired,
  getGeoPoints: PropTypes.func,
};

AdminGeoPointForm.defaultProps = {
  geoPoint: {},
  submitLabel: "Valider",
  onCancel: null,
  getGeoPoints: null,
};

export default AdminGeoPointForm;
