import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { SiteContextProvider } from "components/SiteContext";
import AdminContext from "components/adminComponents/AdminContext";
import LayoutFront from "components/LayoutFront/LayoutFront";
import generateEditablePage from "utils/templateAdminUtils";
import getTemplateComponentByKey from "utils/templateUtils";
import Page from "components/Page";
import PageVersionContext from "components/PageVersionContext";

const useStyles = makeStyles(() => ({
  ckeBorders: {
    "& .cke_editable, & input": {
      border: "1px dashed #999",
      background: "transparent",
      fontSize: "inherit",
      textAlign: "inherit",
      letterSpacing: "inherit",
      padding: 0,
    },
  },
}));

const RenderPage = (props) => {
  const { page, handlePageChange, editionMode } = props;

  const { currentSite, currentPageVersion, setCurrentPageVersion } = useContext(AdminContext);

  const classes = useStyles();

  if (!page) {
    return null;
  }

  const TemplateTag = getTemplateComponentByKey(page.template);

  return (
    <SiteContextProvider site={currentSite}>
      <PageVersionContext.Provider value={{ currentPageVersion, setCurrentPageVersion }}>
        <LayoutFront bo>
          <div className={classes.ckeBorders}>
            {editionMode ? (
              <TemplateTag page={generateEditablePage(page, handlePageChange, currentPageVersion)} />
            ) : (
              <Page page={page} />
            )}
          </div>
        </LayoutFront>
      </PageVersionContext.Provider>
    </SiteContextProvider>
  );
};

RenderPage.propTypes = {
  page: PropTypes.shape(),
  handlePageChange: PropTypes.func.isRequired,
  editionMode: PropTypes.bool.isRequired,
};

RenderPage.defaultProps = {
  page: null,
};

export default RenderPage;
