import React, { useContext } from "react";
import PropTypes from "prop-types";
import elementsTypes from "utils/elementsTypes";
import FilePicker from "components/adminComponents/FilePicker";
import AdminContext from "components/adminComponents/AdminContext";

const ImageForm = (props) => {
  const { content, classes, onContentChange, formSubmitted } = props;
  const { id, label, children } = content;

  const { currentPageVersion } = useContext(AdminContext);

  const fileImageChild = children.find((c) => c.type === elementsTypes.FILE_IMAGE);

  const altChild = children.find((c) => c.key === "alt");
  const titleChild = children.find((c) => c.key === "title");

  const fileValue = {
    ...((fileImageChild && fileImageChild.value && JSON.parse(fileImageChild.value)) || {}),
    alt: altChild && altChild.value,
    title: titleChild && titleChild.value,
  };

  const handleSelectFile = (file) => {
    const { alt, title } = file || {};

    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === fileImageChild) {
          return {
            ...child,
            value: file ? JSON.stringify(file) : "",
          };
        }
        if (child === altChild) {
          return {
            ...child,
            value: alt || "",
          };
        }
        if (child === titleChild) {
          return {
            ...child,
            value: title || "",
          };
        }
        return child;
      }),
    });
  };

  return (
    <fieldset className={classes.fieldset} key={id}>
      <legend>{label}</legend>
      <FilePicker
        classes={classes}
        onSelectFile={handleSelectFile}
        pageVersion={currentPageVersion}
        image
        file={fileValue}
        formSubmitted={formSubmitted}
      />
    </fieldset>
  );
};

ImageForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
};

ImageForm.defaultProps = {
  formSubmitted: false,
};

export default ImageForm;
