import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import AdminBannerForm from "components/adminComponents/AdminBannerForm";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import EnhancedTable from "components/adminComponents/EnhancedTable";
import AdminBannersService from "components/adminServices/AdminBannersService";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { displaySortedSites, formatDateToLocalTimezone, getDisplayPeriod, localeDateNow } from "utils/bannersUtils";

const AdminBanners = () => {
  const [banners, setBanners] = useState({
    data: [],
    total: 0,
  });
  const [sorting, setSorting] = useState({
    order: "DESC",
    orderBy: "updated",
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [createBannerModal, setCreateBannerModal] = useState(false);

  const { order, orderBy } = sorting;

  const { displaySuccess, displayError } = useContext(AdminMessageContext);

  const getByType = useCallback((type) => {
    switch (type) {
      case "info":
        return { label: "Information", color: "#0063cb" };
      case "warning":
        return { label: "Avertissement", color: "#b34000" };
      case "alert":
        return { label: "Alerte", color: "#ce0500" };
      default:
        return { label: "", color: "" };
    }
  }, []);

  const isDisplayed = useCallback((banner) => {
    const { forceDisplay, displayStartDate, displayEndDate, sites = [] } = banner;
    const newDisplayStartDate = formatDateToLocalTimezone(displayStartDate);
    const newDisplayEndDate = formatDateToLocalTimezone(displayEndDate);

    return (
      sites.length > 0 &&
      (forceDisplay ||
        (Date.parse(newDisplayStartDate) < localeDateNow && Date.parse(newDisplayEndDate) > localeDateNow))
    );
  }, []);

  const columns = [
    {
      id: "actions",
      label: "",
      render: () => <Icon icon="edit" iconDSFR="edit-box-line" title="Modifier" />,
    },
    {
      id: "displayed",
      label: "",
      render: (banner) => (
        <Icon
          icon="circle"
          iconDSFR={isDisplayed(banner) ? "checkbox-circle-fill" : "close-circle-fill"}
          sx={{ color: isDisplayed(banner) ? "green" : "red" }}
          title={isDisplayed(banner) ? "Affiché" : "Non affiché"}
          aria-label={isDisplayed(banner) ? "Affiché" : "Non affiché"}
        />
      ),
    },
    {
      id: "titreFr",
      label: "Contenu du bandeau (français)",
      render: (banner) => {
        const { contents = [] } = banner;
        const frenchContent = contents.find((content) => content.lang === "FR") || {};
        const { title = "", text = "" } = frenchContent;
        const titleAndText = title.concat(" ", text);
        const titleToDisplay = title.length > 80 ? title.slice(0, 80).concat("...") : title;
        const textToDisplay = titleAndText.length > 80 ? text.slice(0, 80).concat("...") : text;
        return (
          <>
            <span className="fr-notice__title">{titleToDisplay}</span>
            {title.length <= 80 && textToDisplay && <span className="fr-notice__desc">{textToDisplay}</span>}
          </>
        );
      },
    },
    {
      id: "type",
      label: "Type",
      sortable: true,
      render: (banner) => (
        <Box
          component="span"
          className={`fr-icon-${banner.type === "alert" ? "error-warning" : banner.type}-fill`}
          aria-hidden="true"
          title={getByType(banner.type).label}
          sx={{ color: getByType(banner.type).color }}
        />
      ),
    },
    {
      id: "sites",
      label: "Sites concernés",
      render: (banner) => displaySortedSites(banner.sites),
    },
    {
      id: "forceDisplay",
      label: "Période d'affichage",
      sortable: true,
      cellProps: { sx: { minWidth: "225px" } },
      render: (banner) => getDisplayPeriod(banner),
    },
  ];

  const sort = useMemo(() => {
    const sortingOptions = [`${orderBy},${order}`];
    if (orderBy === "forceDisplay") {
      sortingOptions.push(`displayEndDate,${order}`);
    }
    return sortingOptions;
  }, [order, orderBy]);

  const getBanners = useCallback(
    (moreOptions = {}) => {
      const options = {
        params: {
          ...moreOptions,
          join: ["sites"],
          sort,
          per_page: rowsPerPage,
          offset: (page - 1) * rowsPerPage,
        },
      };
      AdminBannersService.find(options).then((bannersResults) => {
        const { data = [], total = 0 } = bannersResults || {};
        return setBanners({ data, total });
      });
    },
    [sort, rowsPerPage, page]
  );

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  const handleChangeSort = (event, key) => {
    if (orderBy !== key) {
      setSorting({
        order: "ASC",
        orderBy: key,
      });
    } else {
      setSorting({
        order: order === "ASC" ? "DESC" : "ASC",
        orderBy: key,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSubmitBanner = (callback) => (banner) => {
    const onSuccess = () => {
      getBanners();
      if (typeof callback === "function") {
        callback();
      }
    };
    const { type, sites, forceDisplay, displayStartDate, displayEndDate, contents } = banner;
    const siteIds = sites.map((site) => site.id);
    let newBanner = {
      type,
      siteIds,
      forceDisplay,
      contents,
    };
    if (displayStartDate && displayEndDate && displayStartDate < displayEndDate) {
      newBanner = { displayStartDate, displayEndDate, ...newBanner };
    }
    if (banner.id) {
      return AdminBannersService.update({ id: banner.id, ...newBanner })
        .then(() => {
          displaySuccess("Bandeau mis à jour");
          onSuccess();
        })
        .catch(() => {
          displayError("Échec lors de la mise à jour du bandeau");
        });
    }
    return AdminBannersService.create(newBanner)
      .then(() => {
        displaySuccess("Bandeau créé");
        onSuccess();
      })
      .catch(() => {
        displayError("Échec lors de la création du bandeau");
      });
  };

  const handleClickAddBanner = () => {
    setCreateBannerModal(true);
  };

  const closeCreateBannerModal = () => {
    setCreateBannerModal(false);
  };

  return (
    <Stack sx={{ height: "100%" }}>
      <Grid container justifyContent="space-between">
        <h6>Gestion des bandeaux d&apos;information</h6>
        <Button
          onClick={handleClickAddBanner}
          color="secondary"
          icon={{ icon: "plus", iconDSFR: "add-line" }}
          iconPosition="left"
          sx={{ height: "40px" }}
        >
          Nouveau bandeau
        </Button>
      </Grid>
      <Box sx={{ flex: "1 0 auto" }}>
        {banners?.total === 0 ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <p>Aucun bandeau n&apos;a été créé</p>
          </Grid>
        ) : (
          <EnhancedTable
            columns={columns}
            data={banners?.data || []}
            order={order}
            orderBy={orderBy}
            page={page}
            totalRows={banners?.total || 0}
            rowsPerPage={rowsPerPage}
            onRequestSort={handleChangeSort}
            onChangePage={setPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            renderRowDetail={(row, closeDetail) => (
              <AdminBannerForm
                banner={row}
                banners={banners?.data}
                onSubmit={handleSubmitBanner(closeDetail)}
                onCancel={closeDetail}
                submitLabel="Mettre à jour"
                getBanners={getBanners}
              />
            )}
          />
        )}
      </Box>
      <Modal open={createBannerModal} size="lg" onClose={closeCreateBannerModal}>
        <h2>Nouveau bandeau</h2>
        <AdminBannerForm
          banners={banners?.data}
          onSubmit={handleSubmitBanner(closeCreateBannerModal)}
          onCancel={closeCreateBannerModal}
          submitLabel="Créer"
        />
      </Modal>
    </Stack>
  );
};

export default AdminBanners;
