import { createGenerateClassName, StylesProvider } from "@mui/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "apps/back-office/client/keycloak";
import axios from "axios";
import { LanguageContextProvider } from "components/LanguageContext";
import LayoutBack from "components/LayoutBack/LayoutBack";
import { AdminContextProvider } from "components/adminComponents/AdminContext";
import AuthServiceInterface from "da-ged-web/interfaces/AuthServiceInterface";
import FileServiceInterface from "da-ged-web/interfaces/FileServiceInterface";
import FolderServiceInterface from "da-ged-web/interfaces/FolderServiceInterface";
import NodeServiceInterface from "da-ged-web/interfaces/NodeServiceInterface";
import AuthService from "gedServices/AuthService";
import FileService from "gedServices/FileService";
import FolderService from "gedServices/FolderService";
import NodeService from "gedServices/NodeService";
import merge from "lodash.merge";
import React from "react";
import { createRoot } from "react-dom/client";
import { Route, BrowserRouter as Router } from "react-router-dom";

AuthServiceInterface.setImplementation(AuthService);
NodeServiceInterface.setImplementation(NodeService);
FileServiceInterface.setImplementation(FileService);
FolderServiceInterface.setImplementation(FolderService);

const generateClassName = createGenerateClassName({
  productionPrefix: "ants-",
});

axios.interceptors.request.use(
  async (config) => {
    if (!config.url?.startsWith("/") && keycloak.authServerUrl && !config.url?.includes(keycloak.authServerUrl)) {
      return config;
    }
    return merge({}, config, {
      headers: {
        authorization: keycloak && keycloak.token ? `Bearer ${keycloak.token}` : undefined,
      },
    });
  },
  (error) => Promise.reject(error)
);

const LoadingKeycloak = () => "loading keycloak";

const Bo = () => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <LanguageContextProvider>
        <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={{
            onLoad: "login-required",
            checkLoginIframe: false,
            pkceMethod: "S256",
          }}
          LoadingComponent={<LoadingKeycloak />}
        >
          <Router>
            <AdminContextProvider>
              <Route path="/" component={LayoutBack} />
            </AdminContextProvider>
          </Router>
        </ReactKeycloakProvider>
      </LanguageContextProvider>
    </StylesProvider>
  );
};

export default Bo;
createRoot(document.getElementById("root")).render(<Bo />);
