import axios from "axios";
import Node, { FILE, FOLDER } from "da-ged-web/models/Node";
import Owner from "da-ged-web/models/Owner";
import AuthService from "./AuthService";
import axiosCatchError from "./AxiosCatchErrorImplement";

const API_GED_URL = "/da-ged";

const nodeAttributeMap = {
  id: "id",
  name: "name",
  size: "size",
  lastUpdate: "updated",
};

const getSize = (size) => {
  if (size >= 1048576) {
    return `${parseInt(size / 1048576, 10)} Mo`;
  }
  if (size >= 1024) {
    return `${parseInt(size / 1024, 10)} Ko`;
  }
  return `${size} octets`;
};

const formatNode = (node, accountId) => {
  const ownerName = node.owner === accountId ? "myAccount" : "FirstName Last Name"; // À corriger après l'ajout d'une requête correspondante
  const lastUpdate = node.updated ? node.updated.split(".") : null;

  const { parent } = node;

  return new Node({
    type: node.type,
    mimeType: node.mimeType ? node.mimeType : null,
    id: node.id,
    name: node.name,
    size: node.size ? getSize(node.size) : 0,
    lastUpdate: lastUpdate ? new Date(lastUpdate[0]).toLocaleDateString("fr-FR") : null,
    owner: new Owner({ name: ownerName, id: node.owner }),
    erasable: node.erasable,
    parentId: parent.id,
    parentName: parent.identifierKey === "root" ? "Mes documents" : parent.name,
  });
};

export default class NodeService {
  static deleteAll = () => {
    return axios.delete(`${API_GED_URL}/api/trash`).catch((error) => axiosCatchError(error));
  };

  static fetchGedItems = async ({ pageSize, pageIndex, sortBy, order, search, nodeId, folderOnly, trash } = {}) => {
    const accountId = await AuthService.getMyAccountId();

    const queryParams = {
      sort: (sortBy && `${nodeAttributeMap[sortBy]},${order || "asc"}`) || "",
      search: search || "",
      size: pageSize || 10,
      page: pageIndex || 0,
      folderOnly: folderOnly || false,
    };

    // Récupération des dossiers
    let folders = {};

    if (trash) {
      folders = await axios.get(`${API_GED_URL}/api/trash/folders`, {
        params: queryParams,
      });
    } else {
      folders = await axios.get(`${API_GED_URL}/api/folders/${nodeId || "root"}/folders`, {
        params: queryParams,
      });
    }

    const { data: folderData } = folders;

    const formatedFolders =
      (folderData &&
        folderData.content &&
        folderData.content.map((fo) => {
          const folder = fo;
          folder.type = FOLDER;
          return formatNode(folder, accountId);
        })) ||
      [];

    // Si (formatedFolders.length === pageSize) alors on a que les dossier sur cette page et donc on n'a pas besoin de récupérer les fichiers

    if (formatedFolders.length === pageSize || folderOnly) {
      return { data: formatedFolders };
    }
    // Récupération des fichiers

    // Calcul des première et deuxième pages des fichiers

    const firstPage = pageIndex - ((folderData && folderData.totalPages) || 0);
    const missingNodesCount = (pageSize - (((folderData && folderData.totalElements) || 0) % pageSize)) % pageSize;
    const secondPage = missingNodesCount !== 0 ? firstPage + 1 : false;

    let filesResult = [];

    if (firstPage >= 0) {
      queryParams.page = firstPage;

      let files = {};

      if (trash) {
        files = await axios.get(
          `${API_GED_URL}/api/trash/files`,

          {
            params: queryParams,
          }
        );
      } else {
        files = await axios.get(
          `${API_GED_URL}/api/folders/${nodeId || "root"}/files`,

          {
            params: queryParams,
          }
        );
      }

      filesResult = (files && files.data && files.data.content) || [];
    }
    if (secondPage !== false) {
      queryParams.page = secondPage;

      let files = {};

      if (trash) {
        files = await axios.get(`${API_GED_URL}/api/trash/files`, {
          params: queryParams,
        });
      } else {
        files = await axios.get(`${API_GED_URL}/api/folders/${nodeId || "root"}/files`, {
          params: queryParams,
        });
      }

      filesResult = [...filesResult, ...((files && files.data && files.data.content) || [])];
    }

    if (firstPage >= 0) {
      filesResult.splice(0, missingNodesCount);
      filesResult = filesResult.slice(0, pageSize);
    } else {
      filesResult = filesResult.slice(0, missingNodesCount);
    }

    const formatedFiles = filesResult.map((f) => {
      const file = f;
      file.type = FILE;
      return formatNode(file, accountId);
    });

    return { data: [...formatedFolders, ...formatedFiles] };
  };

  static downloadFilesAndFolders = (elements) => {
    const arrayFilesId = [];
    const arrayFoldersId = [];
    elements.forEach((elt) => {
      if (elt.type === FILE) {
        arrayFilesId.push(elt.id);
      } else if (elt.type === FOLDER) {
        arrayFoldersId.push(elt.id);
      }
    });

    return Promise.all([arrayFilesId, arrayFoldersId]).then((promise) => {
      const [filesId, foldersId] = promise;
      return axios
        .post(`${API_GED_URL}/api/nodes/archive`, {
          filesId,
          foldersId,
        })
        .then((archiveUrl) => {
          return axios
            .get(`${API_GED_URL}/${archiveUrl.data.downloadUrl}`, {
              responseType: "blob",
            })
            .then((response) => {
              return response.data;
            })
            .catch((error) => axiosCatchError(error));
        })
        .catch((error) => axiosCatchError(error));
    });
  };

  static moveFilesAndFolder = (elements, parent) => {
    return Promise.all(
      elements.map((elt) => {
        if (elt.type === "FOLDER") {
          return axios
            .patch(`${API_GED_URL}/api/folders/${elt.id}`, { parent })
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return axiosCatchError(err);
            });
        }
        if (elt.type === "FILE") {
          return axios
            .patch(`${API_GED_URL}/api/files/${elt.id}`, { parent })
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return axiosCatchError(err);
            });
        }
        return elt;
      })
    );
  };
}
