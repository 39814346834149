import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import InputForm from "components/adminComponents/contentForms/InputForm";
import AdminListForm from "components/adminComponents/contentForms/AdminListForm";
import elementsTypes from "utils/elementsTypes";
import ExpansionPanel from "components/templatesComponents/ExpansionPanel";

const ContactFormProfileListForm = (props) => {
  const { content, classes, onContentChange } = props;
  const { id, label, children = [] } = content;

  const profilesChildren = children.filter((c) => c.type === elementsTypes.CONTACT_FORM_PROFILE) || [];

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: newChildren,
    });
  };

  return (
    <div key={id}>
      <h2 className={classes.title}>{label}</h2>

      <div className={classes.fieldset}>
        <p>Profils</p>

        <AdminListForm
          inline
          contents={profilesChildren}
          elementsType={elementsTypes.CONTACT_FORM_PROFILE}
          elementsKey="ContactFormProfiles"
          onContentsChange={(newContents) => handleChildrenChange(profilesChildren, newContents)}
        >
          {profilesChildren.map((currentProfile) => (
            <ExpansionPanel
              key={currentProfile.id}
              summary={(currentProfile.children.find((c) => c.key === "title") || {}).value}
              details={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputForm
                      content={{
                        ...currentProfile.children.find((c) => c.key === "title"),
                        label: "Profil",
                      }}
                      info={
                        <p style={{ color: "#fff" }}>
                          Si ce champ n&apos;est pas rempli, ce profil n&apos;apparaîtra pas dans la liste
                        </p>
                      }
                      onContentChange={(newContent) =>
                        handleChildChange(currentProfile, {
                          ...currentProfile,
                          children: (currentProfile.children || []).map((c) => (c.key === "title" ? newContent : c)),
                        })
                      }
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              }
            />
          ))}
        </AdminListForm>
      </div>
    </div>
  );
};

ContactFormProfileListForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default ContactFormProfileListForm;
