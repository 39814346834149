import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import IconForm from "components/adminComponents/contentForms/IconForm";
import InputForm from "components/adminComponents/contentForms/InputForm";
import AdminListForm from "components/adminComponents/contentForms/AdminListForm";
import elementsTypes from "utils/elementsTypes";
import CKEditor from "ckeditor4-react";
import Tabs from "components/templatesComponents/Tabs";

CKEditor.editorUrl = "/ckeditor/ckeditor.js";

const SiteSettingsForm = (props) => {
  const { content, classes, onContentChange } = props;
  const { id, label, children = [] } = content;

  const socialNetworkListChild = children.filter((c) => c.key === "socialNetworks");

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: [...children.filter((c) => !oldChildren.map((oldChild) => oldChild.id).includes(c.id)), ...newChildren],
    });
  };

  return (
    <div key={id}>
      <h2 className={classes.title}>{label}</h2>

      <Tabs
        navigate={false}
        tabs={[
          {
            title: "Réseaux sociaux",
            children: (
              <div className={classes.fieldset}>
                <AdminListForm
                  inline
                  contents={socialNetworkListChild}
                  elementsType={elementsTypes.SOCIAL_NETWORK}
                  elementsKey="socialNetworks"
                  onContentsChange={(newContents) => handleChildrenChange(socialNetworkListChild, newContents)}
                >
                  {socialNetworkListChild.map((socialNetwork) => (
                    <div key={socialNetwork.id} className={classes.fieldset}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <InputForm
                            content={socialNetwork.children.find((c) => c.key === "url")}
                            onContentChange={(newContent) =>
                              handleChildChange(socialNetwork, {
                                ...socialNetwork,
                                children: (socialNetwork.children || []).map((c) => (c.key === "url" ? newContent : c)),
                              })
                            }
                            classes={classes}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputForm
                            content={socialNetwork.children.find((c) => c.key === "network")}
                            onContentChange={(newContent) =>
                              handleChildChange(socialNetwork, {
                                ...socialNetwork,
                                children: (socialNetwork.children || []).map((c) =>
                                  c.key === "network" ? newContent : c
                                ),
                              })
                            }
                            classes={classes}
                          />
                        </Grid>
                      </Grid>
                      <IconForm
                        content={socialNetwork.children.find((c) => c.key === "icon")}
                        onContentChange={(newContent) =>
                          handleChildChange(socialNetwork, {
                            ...socialNetwork,
                            children: (socialNetwork.children || []).map((c) => (c.key === "icon" ? newContent : c)),
                          })
                        }
                        classes={classes}
                      />
                    </div>
                  ))}
                </AdminListForm>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

SiteSettingsForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default SiteSettingsForm;
