import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import { formControlStyled, formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import InputForm from "components/adminComponents/contentForms/InputForm";
import SelectPage from "components/adminComponents/SelectPage";
import PageVersionContext from "components/PageVersionContext";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import elementsTypes from "utils/elementsTypes";

const linkTypes = {
  page: "page",
  url: "url",
};

const LinkForm = (props) => {
  const { content, onContentChange, disabled = false, inAllSites = false } = props;

  const { id, label, children, width } = content;

  const { currentPageVersion = {} } = useContext(PageVersionContext);
  const { page = {} } = currentPageVersion;
  const { lang } = page;

  const pageChild = children.find((c) => c.type === elementsTypes.PAGE);
  const urlChild = children.find((c) => c.type === elementsTypes.URL);
  const titleUrlChild = children.find((c) => c.type === elementsTypes.INPUT);
  const checkboxChild = children.find((c) => c.type === elementsTypes.CHECKBOX);

  const [linkType, setLinkType] = useState(pageChild.value ? linkTypes.page : linkTypes.url);
  const [englishPage, setEnglishPage] = useState(lang === "EN");

  useEffect(() => {
    setLinkType(pageChild.value ? linkTypes.page : linkTypes.url);
  }, [pageChild.value]);

  const handleLinkTypeChange = (e) => {
    const type = e.target.value;
    setLinkType(type);
  };

  const handleSwitchLang = () => {
    setEnglishPage(!englishPage);
  };

  const handleSelectPage = (p) => {
    const { versions, ...others } = p;
    // sort version by updated (last modified first)
    versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
    const [lastVersion] = versions;
    if (lastVersion) {
      onContentChange({
        ...content,
        children: children.map((child) => {
          // Update page value
          if (child === pageChild) {
            return {
              ...child,
              value: {
                ...lastVersion,
                page: { ...others },
              },
            };
          }
          // Set url empty when a page is selected
          if (child === urlChild) {
            return {
              ...child,
              value: "",
            };
          }
          return child;
        }),
      });
    }
  };

  const handleUrlChange = (newUrlChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        // Set page empty when a page is selected
        if (child === pageChild) {
          return {
            ...child,
            value: "",
          };
        }
        // Update url value
        if (child === urlChild) {
          return newUrlChild;
        }
        return child;
      }),
    });
  };

  const handleCheckboxChange = (newCheckboxChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === checkboxChild) {
          return newCheckboxChild;
        }
        return child;
      }),
    });
  };

  const handleTitleUrlChange = (newTitleUrlChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === titleUrlChild) {
          return newTitleUrlChild;
        }
        return child;
      }),
    });
  };

  return (
    <Box component="fieldset" key={id} sx={formStyled.fieldset}>
      <legend>{label}</legend>
      <FormControl>
        <FormLabel component="legend">Type de lien</FormLabel>
        <Grid container mb={1.25}>
          <RadioGroup value={linkType} onChange={handleLinkTypeChange} row>
            <FormControlLabel
              value={linkTypes.url}
              control={<Radio color="secondary" disabled={disabled} />}
              label="Url"
            />
            <FormControlLabel
              value={linkTypes.page}
              control={<Radio color="secondary" disabled={disabled} />}
              label="Page"
            />
          </RadioGroup>
          {linkType === linkTypes.page && (
            <Grid item xs container alignItems="center" sx={{ ml: 2 }}>
              <Box component="p" sx={{ mr: 1.5, opacity: disabled ? 0.38 : 1 }}>
                FR
              </Box>
              <FormControlLabel
                control={<Switch color="secondary" checked={englishPage} onChange={handleSwitchLang} name="lang" />}
                label={<p>EN</p>}
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
      </FormControl>
      {linkType === linkTypes.url ? (
        <InputForm content={urlChild} onContentChange={handleUrlChange} placeholder="Url du lien" />
      ) : (
        <FormControl sx={formControlStyled(width)}>
          <SelectPage
            currentPage={pageChild.value && pageChild.value.page}
            onSelectPage={handleSelectPage}
            disabled={disabled}
            label={pageChild.label}
            englishPage={englishPage}
            switchComponent
            inAllSites={inAllSites}
          />
        </FormControl>
      )}
      <InputForm content={titleUrlChild} onContentChange={handleTitleUrlChange} placeholder="Titre du lien" />
      <CheckboxForm content={checkboxChild} onContentChange={handleCheckboxChange} />
    </Box>
  );
};

LinkForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inAllSites: PropTypes.bool,
};

export default LinkForm;
