import FormControl from "@mui/material/FormControl";
import AdminContext from "components/adminComponents/AdminContext";
import { formControlStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import FilePicker from "components/adminComponents/FilePicker";
import PropTypes from "prop-types";
import React, { useContext } from "react";

const FileForm = (props) => {
  const { content, onContentChange, image = false } = props;

  const { id, label, value, width } = content;

  const { currentPageVersion } = useContext(AdminContext);

  const handleSelectFile = (file) => {
    onContentChange({
      ...content,
      value: JSON.stringify(file),
    });
  };

  let fileValue;
  try {
    fileValue = JSON.parse(value);
  } catch (e) {
    fileValue = value;
  }

  return (
    <FormControl sx={formControlStyled(width)} key={id}>
      <label>{label}</label>
      <FilePicker onSelectFile={handleSelectFile} pageVersion={currentPageVersion} image={image} file={fileValue} />
    </FormControl>
  );
};

FileForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  image: PropTypes.bool,
};

export default FileForm;
