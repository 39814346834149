import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SelectPage from "components/adminComponents/SelectPage";
import elementsTypes from "utils/elementsTypes";
import IconForm from "components/adminComponents/contentForms/IconForm";
import InputForm from "components/adminComponents/contentForms/InputForm";
import LinkForm from "components/adminComponents/contentForms/LinkForm";
import TextAreaForm from "components/adminComponents/contentForms/TextAreaForm";
import Icon from "components/templatesComponents/Icon";
import news from "components/templates/news/news";
import PageVersionContext from "components/PageVersionContext";
import sanitizeHtml from "sanitize-html";

const CardForm = (props) => {
  const { content, classes, onContentChange, template, inAllSites } = props;

  const { id, label, children } = content;

  const { currentPageVersion = {} } = useContext(PageVersionContext);
  const { page = {} } = currentPageVersion;
  const { lang } = page;

  const pageChild = children.find((c) => c.type === elementsTypes.PAGE);
  const titleChild = children.find((c) => c.key === "title");
  const descriptionChild = children.find((c) => c.key === "description");
  const linkChild = children.find((c) => c.type === elementsTypes.LINK);
  const iconChild = children.find((c) => c.type === elementsTypes.ICON);

  const [pageCard, setPageCard] = useState(!!pageChild.value || !id);

  const handleCardTypeChange = (e) => {
    setPageCard(e.target.checked);
    if (!e.target.checked) {
      onContentChange({
        ...content,
        children: children.map((child) => {
          if (child === pageChild) {
            return {
              ...child,
              value: "",
            };
          }
          return child;
        }),
      });
    }
  };

  const handleSelectPage = (p) => {
    const { versions, ...others } = p;
    versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
    const [lastVersion] = versions;
    if (lastVersion) {
      const pageVersion = {
        ...lastVersion,
        page: { ...others },
      };
      onContentChange({
        ...content,
        children: children.map((child) => {
          // Update page value
          if (child === pageChild) {
            return {
              ...child,
              value: pageVersion,
            };
          }
          // Update title value
          if (child === titleChild) {
            return {
              ...child,
              value: lastVersion.title || "",
            };
          }
          // Update description value
          if (child === descriptionChild) {
            const description = decodeURIComponent(lastVersion.shortDescription || "")?.replace(/<[^>]*>/g, "") || "";
            return {
              ...child,
              value: sanitizeHtml(description),
            };
          }
          // Update link value
          if (child === linkChild) {
            return {
              ...child,
              children: linkChild.children.map((c) => {
                if (c.type === elementsTypes.PAGE) {
                  return {
                    ...c,
                    value: pageVersion,
                  };
                }
                if (c.type === elementsTypes.URL) {
                  return {
                    ...c,
                    value: "",
                  };
                }
                return c;
              }),
            };
          }
          return child;
        }),
      });
    }
  };

  const handleTitleChange = (newTitleChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === titleChild) {
          return newTitleChild;
        }
        return child;
      }),
    });
  };

  const handleDescriptionChange = (newDescriptionChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === descriptionChild) {
          return newDescriptionChild;
        }
        return child;
      }),
    });
  };

  const handleLinkChange = (newLinkChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === linkChild) {
          return newLinkChild;
        }
        return child;
      }),
    });
  };

  const handleIconChange = (newIconChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === iconChild) {
          return newIconChild;
        }
        return child;
      }),
    });
  };

  const hasTemplate = template.length;

  const filters = hasTemplate ? [`versions.template||in||${template}`] : [];

  return (
    <div key={id}>
      <h2 className={classes.title}>{label}</h2>
      <Box sx={{ maxWidth: 450 }}>
        <SelectPage
          currentPage={pageChild.value && pageChild.value.page}
          onSelectPage={handleSelectPage}
          disabled={!hasTemplate && !pageCard}
          onlyPublishedPages={lang === "FR"}
          filters={filters}
          lang={lang}
          inAllSites={inAllSites}
        />
      </Box>
      {(!hasTemplate || !template.includes(news.key)) && (
        <>
          {!hasTemplate && (
            <FormControlLabel
              control={<Checkbox checked={pageCard} onChange={handleCardTypeChange} disableRipple />}
              label="Renseigner automatiquement"
            />
          )}
          <InputForm
            content={titleChild}
            onContentChange={handleTitleChange}
            classes={classes}
            disabled={!hasTemplate && pageCard}
          />
          <TextAreaForm
            content={descriptionChild}
            onContentChange={handleDescriptionChange}
            classes={classes}
            disabled={!hasTemplate && pageCard}
          />
          {!hasTemplate && (
            <>
              <LinkForm content={linkChild} onContentChange={handleLinkChange} classes={classes} disabled={pageCard} />

              <IconForm content={iconChild} onContentChange={handleIconChange} classes={classes} />
              <Grid item className={classes.iconWrapper}>
                <p>
                  <Icon
                    icon="exclamation-circle"
                    iconDSFR="error-warning-line"
                    className={classes.icon}
                    title="Information"
                  />
                  &nbsp;L&apos;icône ne s&apos;affiche pas sur toutes les cards
                </p>
              </Grid>
            </>
          )}
        </>
      )}
    </div>
  );
};

CardForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func,
  template: PropTypes.string,
  inAllSites: PropTypes.bool,
};

CardForm.defaultProps = {
  onContentChange: null,
  template: "",
  inAllSites: false,
};

export default CardForm;
