import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AdminListForm from "components/adminComponents/contentForms/AdminListForm";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import InputForm from "components/adminComponents/contentForms/InputForm";
import ExpansionPanel from "components/templatesComponents/ExpansionPanel";
import PropTypes from "prop-types";
import React from "react";
import elementsTypes from "utils/elementsTypes";

const ContactFormProfileListForm = (props) => {
  const { content, onContentChange } = props;
  const { id, label, children = [] } = content;

  const profilesChildren = children.filter((c) => c.type === elementsTypes.CONTACT_FORM_PROFILE) || [];

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: newChildren,
    });
  };

  return (
    <div key={id}>
      <Box component="h2" sx={formStyled.title}>
        {label}
      </Box>

      <Box sx={formStyled.fieldset}>
        <p>Profils</p>

        <AdminListForm
          inline
          contents={profilesChildren}
          elementsType={elementsTypes.CONTACT_FORM_PROFILE}
          elementsKey="ContactFormProfiles"
          onContentsChange={(newContents) => handleChildrenChange(profilesChildren, newContents)}
        >
          {profilesChildren.map((currentProfile) => (
            <ExpansionPanel
              key={currentProfile.id}
              summary={(currentProfile.children.find((c) => c.key === "title") || {}).value}
              details={
                <Grid container>
                  <InputForm
                    content={{
                      ...currentProfile.children.find((c) => c.key === "title"),
                      label: "Profil",
                    }}
                    info={
                      <Box component="p" sx={{ color: "#fff" }}>
                        Si ce champ n&apos;est pas rempli, ce profil n&apos;apparaîtra pas dans la liste
                      </Box>
                    }
                    onContentChange={(newContent) =>
                      handleChildChange(currentProfile, {
                        ...currentProfile,
                        children: (currentProfile.children || []).map((c) => (c.key === "title" ? newContent : c)),
                      })
                    }
                  />
                </Grid>
              }
            />
          ))}
        </AdminListForm>
      </Box>
    </div>
  );
};

ContactFormProfileListForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default ContactFormProfileListForm;
