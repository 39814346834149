import Search from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import AdminContext from "components/adminComponents/AdminContext";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import EnhancedTable from "components/adminComponents/EnhancedTable";
import AdminPagesService from "components/adminServices/AdminPagesService";
import Button from "components/templatesComponents/Button";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { exportDate } from "utils/contentsUtils";

const columns = [
  {
    id: "lang",
    label: "Langue",
    sortable: true,
    render: (page) => page.lang,
  },
  {
    id: "name",
    label: "Nom de la page",
    sortable: true,
    render: (page) => page.name,
  },
  {
    id: "status",
    label: "Statut",
    render: (page) => (page.isPublished ? "Publiée" : "Non publiée"),
  },
  {
    id: "updated",
    label: "Page éditée le",
    sortable: true,
    render: (page) => new Date(page.updated).toLocaleDateString(),
    cellProps: { style: { minWidth: "166px" } },
  },
  {
    id: "updatedVersion",
    label: "Version éditée le",
    render: (page) => new Date(page.versions[0].updated).toLocaleDateString(),
    cellProps: { style: { minWidth: "162px" } },
  },
];

let searchTimeout = null;

const PagesTable = (props) => {
  const { onPageClick, pageId = "", title, canExport = false } = props;

  const [pages, setPages] = useState({
    data: [],
    total: 0,
  });
  const [sorting, setSorting] = useState({
    order: "DESC",
    orderBy: "updated",
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [onExport, setOnExport] = useState(false);

  const { displaySuccess, displayError } = useContext(AdminMessageContext);

  const { currentSite } = useContext(AdminContext);

  const { order, orderBy } = sorting;

  useEffect(() => {
    const filter = [`siteId||eq||${currentSite.id}`];
    if (search) {
      filter.push(`name||cont||${search}`);
    }
    if (pageId) {
      filter.push(`parentId||eq||${pageId}`);
    }
    const options = {
      params: {
        join: ["versions"],
        filter,
        fields: "id,name,lang,isPublished,updated,versions.updated",
        sort: [`${orderBy},${order}`],
        per_page: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      },
    };
    AdminPagesService.find(options).then((pagesResult) => {
      const { data, total } = pagesResult;
      setPages({ data, total });
    });
  }, [currentSite.id, order, orderBy, page, rowsPerPage, search, pageId]);

  const handleExport = () => {
    setOnExport(true);
    AdminPagesService.export(currentSite.id)
      .then((response) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.setAttribute(
          "download",
          `${process.env.environment_suffix} - Liste des pages de ${currentSite.name} - ${exportDate}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        displaySuccess("La liste des pages a été exportée");
        setOnExport(false);
      })
      .catch(() => {
        displayError("Échec lors de l'export de la liste");
        setOnExport(false);
      });
  };

  const handleChangeSort = (event, key) => {
    if (orderBy !== key) {
      setSorting({
        order: "ASC",
        orderBy: key,
      });
    } else {
      setSorting({
        order: order === "ASC" ? "DESC" : "ASC",
        orderBy: key,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      searchTimeout = null;
      setSearch(value);
    }, 350);
  };

  if (!pages.total && !search) return <i>Aucune sous page</i>;

  return (
    <Stack sx={{ height: "65vh", position: "relative" }}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <h6>{title}</h6>
        {canExport && (
          <Button
            onClick={handleExport}
            icon={{
              icon: onExport ? "sync fa-spin" : "download",
              iconDSFR: onExport ? "refresh-line" : "download-line",
            }}
            iconPosition="left"
            disabled={onExport || !pages.total}
          >
            Exporter la liste
          </Button>
        )}
      </Grid>
      <Input
        placeholder="Filtrer les pages"
        disableUnderline
        onChange={handleSearchChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton>
              <Search />
            </IconButton>
          </InputAdornment>
        }
      />
      <Box sx={{ flex: "1 0 auto" }}>
        {pages.total === 0 && search ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <p>Aucune page</p>
          </Grid>
        ) : (
          <EnhancedTable
            columns={columns}
            data={pages.data}
            order={order}
            orderBy={orderBy}
            page={page}
            totalRows={pages.total}
            rowsPerPage={rowsPerPage}
            onRequestSort={handleChangeSort}
            onChangePage={setPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onRowClick={onPageClick}
          />
        )}
      </Box>
    </Stack>
  );
};

PagesTable.propTypes = {
  onPageClick: PropTypes.func.isRequired,
  pageId: PropTypes.string,
  title: PropTypes.string.isRequired,
  canExport: PropTypes.bool,
};

export default PagesTable;
