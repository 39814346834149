import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Prompt } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "components/templatesComponents/Button";
import Modal, { ModalActions } from "components/templatesComponents/Modal";

const PreventNavigation = (props) => {
  const { location, history, blocked, onSave, onShowPrompt, onNavigate } = props;

  const [blockedLocation, setBlockedLocation] = useState(false);
  const [confirmNavigation, setConfirmNavigation] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    window.onbeforeunload = () => {
      if (blocked) {
        return "";
      }
    };
  }, [blocked]);

  useEffect(() => {
    setConfirmNavigation(false);
  }, [location]);

  useEffect(() => {
    if (confirmNavigation && blockedLocation) {
      history.push(blockedLocation);
      setBlockedLocation(false);
    }
  }, [blockedLocation, confirmNavigation, history]);

  const handleBlockedNavigation = (nextLocation) => {
    if (nextLocation === location) {
      return false;
    }
    if (!confirmNavigation) {
      if (typeof onShowPrompt === "function") {
        onShowPrompt();
      }
      setBlockedLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigation = () => {
    if (typeof onNavigate === "function") {
      onNavigate();
    }
    setConfirmNavigation(true);
  };

  const handleCancelNavigation = () => {
    setBlockedLocation(false);
  };

  const handleSaveAndLeave = () => {
    if (typeof onSave === "function") {
      const promise = onSave();
      if (promise && typeof promise.then === "function") {
        promise.then(() => {
          setConfirmNavigation(true);
        });
      } else {
        setConfirmNavigation(true);
      }
    }
  };

  return (
    <Fragment>
      <Prompt when={blocked} message={handleBlockedNavigation} />
      <Modal open={blockedLocation} onClose={handleCancelNavigation}>
        <h2>Voulez-vous enregistrer les modifications apportées à cette page ?</h2>
        <Box pb={6}>Vous perdrez vos modifications, si vous ne les enregistrez pas.</Box>
        <ModalActions>
          <Button onClick={handleConfirmNavigation} style={{ backgroundColor: "#E0E0E0" }}>
            Ne pas enregistrer
          </Button>
          <Button outlined onClick={handleCancelNavigation}>
            Annuler
          </Button>
          {typeof onSave === "function" && <Button onClick={handleSaveAndLeave}>Enregistrer et quitter</Button>}
        </ModalActions>
      </Modal>
    </Fragment>
  );
};

PreventNavigation.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  onShowPrompt: PropTypes.func,
  onSave: PropTypes.func,
  onNavigate: PropTypes.func,
  blocked: PropTypes.bool,
};

PreventNavigation.defaultProps = {
  blocked: false,
  onSave: false,
  onNavigate: null,
  onShowPrompt: null,
};

export default withRouter(PreventNavigation);
