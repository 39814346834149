import Box from "@mui/material/Box";
import Button from "components/templatesComponents/Button";
import { ModalActions } from "components/templatesComponents/Modal";
import PropTypes from "prop-types";
import React, { useState } from "react";

const ModalConfirm = (props) => {
  const {
    title,
    text,
    confirmText,
    cancelText = "Annuler",
    detailContent = null,
    onConfirm,
    onCancel,
    confirmButtonProps = {},
    cancelButtonProps = {},
  } = props;

  const [seeDetail, setSeeDetail] = useState(false);

  return (
    <div>
      <h2>{title}</h2>
      {text && <Box mb={2}>{text}</Box>}
      {detailContent && (
        <>
          <Box
            sx={{ color: "primary.main", cursor: "pointer", textDecoration: "underline", my: 2 }}
            onClick={() => setSeeDetail(!seeDetail)}
          >
            {seeDetail ? "Masquer" : "Voir"} le détail
          </Box>
          {seeDetail && detailContent}
        </>
      )}
      <ModalActions>
        <Button outlined onClick={onCancel} {...cancelButtonProps}>
          {cancelText}
        </Button>
        <Button onClick={onConfirm} {...confirmButtonProps}>
          {confirmText}
        </Button>
      </ModalActions>
    </div>
  );
};

ModalConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.shape().isRequired,
  detailContent: PropTypes.shape(),
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonProps: PropTypes.shape({}),
  cancelButtonProps: PropTypes.shape({}),
};

export default ModalConfirm;
