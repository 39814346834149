import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import FileForm from "components/adminComponents/contentForms/FileForm";
import InputForm from "components/adminComponents/contentForms/InputForm";
import Tabs from "components/templatesComponents/Tabs";
import PropTypes from "prop-types";
import React, { useState } from "react";

const SiteSettingsForm = (props) => {
  const { content, onContentChange } = props;
  const { id, label, children = [] } = content;

  const videoSrcChild = children.find((c) => c.key === "videoSrc") || {};
  const titleChild = children.find((c) => c.key === "title") || {};
  const mobileTitleChild = children.find((c) => c.key === "mobileTitle") || {};
  const buttonNextStepLabelChild = children.find((c) => c.key === "buttonNextStepLabel") || {};
  const buttonGoToSiteLabelChild = children.find((c) => c.key === "buttonGoToSiteLabel") || {};
  const desktopImageSrc = children.find((c) => c.key === "desktopImageSrc") || {};
  const mobileImageSrcChild = children.find((c) => c.key === "mobileImageSrc") || {};
  const isActiveChild = children.find((c) => c.key === "isActive") || {};
  const thresholdChild = children.find((c) => c.key === "threshold") || {};
  const resetDateChild = children.find((c) => c.key === "resetDate") || {};

  const [initialDate] = useState(resetDateChild.value);
  const [reset, setReset] = useState(false);

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleImageChildChange = (oldChild, newChild) => {
    const { value } = newChild;
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? { ...newChild, value } : c)),
    });
  };

  const handleResetDateChange = (e) => {
    const { checked } = e.target;
    setReset(checked);
    onContentChange({
      ...content,
      children: children.map((c) =>
        c === resetDateChild ? { ...resetDateChild, value: checked ? new Date() : initialDate } : c
      ),
    });
  };

  return (
    <Box key={id} sx={{ height: "75vh", overflowY: "scroll", overflowX: "hidden" }}>
      <Box component="h2" sx={formStyled.title}>
        {label}
      </Box>

      <Tabs
        navigate={false}
        tabs={[
          {
            title: "Premier calque",
            children: (
              <Box sx={formStyled.fieldset}>
                <InputForm
                  content={{ ...titleChild, label: "Titre" }}
                  onContentChange={(newContent) => handleChildChange(titleChild, newContent)}
                />
                <InputForm
                  content={{ ...mobileTitleChild, label: "Titre en version mobile" }}
                  onContentChange={(newContent) => handleChildChange(mobileTitleChild, newContent)}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputForm
                      content={{ ...buttonNextStepLabelChild, label: 'Libellé bouton "calque suivant"' }}
                      onContentChange={(newContent) => handleChildChange(buttonNextStepLabelChild, newContent)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputForm
                      content={{ ...buttonGoToSiteLabelChild, label: 'Libellé bouton "fermer le calque"' }}
                      onContentChange={(newContent) => handleChildChange(buttonGoToSiteLabelChild, newContent)}
                    />
                  </Grid>
                </Grid>
                <InputForm
                  content={{ ...videoSrcChild, label: "Lien de la vidéo" }}
                  onContentChange={(newContent) => handleChildChange(videoSrcChild, newContent)}
                />
                <fieldset>
                  <legend>Image 1 (affichée sur mobile uniquement)</legend>
                  <FileForm
                    content={{
                      ...mobileImageSrcChild,
                      value: mobileImageSrcChild.value,
                    }}
                    onContentChange={(newContent) => handleImageChildChange(mobileImageSrcChild, newContent)}
                    image
                  />
                </fieldset>
              </Box>
            ),
          },
          {
            title: "Deuxième calque",
            children: (
              <Box sx={formStyled.fieldset}>
                <FileForm
                  content={{
                    ...desktopImageSrc,
                    value: desktopImageSrc.value,
                    label: "Image 2 (affichée sur ordinateur uniquement)",
                  }}
                  onContentChange={(newContent) => handleImageChildChange(desktopImageSrc, newContent)}
                  image
                />
              </Box>
            ),
          },
          {
            title: "Autres paramètres",
            children: (
              <Box sx={formStyled.fieldset}>
                <InputForm
                  content={{ ...thresholdChild, label: "Nombre de répétitions" }}
                  onContentChange={(newContent) => handleChildChange(thresholdChild, newContent)}
                  type="number"
                />
                <CheckboxForm
                  content={{ ...isActiveChild, label: "Activer le calque" }}
                  onContentChange={(newContent) => handleChildChange(isActiveChild, newContent)}
                />
                <br />
                <FormControlLabel
                  control={<Checkbox checked={reset} onChange={handleResetDateChange} disableRipple />}
                  label="Réinitialiser pour tous les visiteurs"
                />
              </Box>
            ),
          },
        ]}
      />
    </Box>
  );
};

SiteSettingsForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default SiteSettingsForm;
