import AdminActions from "components/adminComponents/AdminActions";
import DynamicAdmin from "components/adminComponents/DynamicAdmin";
import PropTypes from "prop-types";
import React from "react";

const AdminTabWrapper = (props) => {
  const {
    id,
    content,
    children = null,
    contentKey,
    addContentText,
    onContentChange,
    onAddContent,
    onRemoveContent,
    contentsTypes,
    limit = null,
  } = props;

  return (
    <AdminActions key={id} content={content} onContentChange={onContentChange}>
      <DynamicAdmin
        contentKey={contentKey}
        addContentText={addContentText}
        contentsTypes={contentsTypes}
        onAddContent={onAddContent}
        onRemoveContent={onRemoveContent}
        limit={limit}
      />
      {children}
    </AdminActions>
  );
};

AdminTabWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.shape().isRequired,
  children: PropTypes.node,
  contentKey: PropTypes.string.isRequired,
  addContentText: PropTypes.string.isRequired,
  onContentChange: PropTypes.func.isRequired,
  onAddContent: PropTypes.func.isRequired,
  onRemoveContent: PropTypes.func.isRequired,
  contentsTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  limit: PropTypes.number,
};

export default AdminTabWrapper;
