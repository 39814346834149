import React from "react";
import NodesListing from "da-ged-web/components/NodesListing/NodesListing";
import FullToolbar from "da-ged-web/components/Toolbar/FullToolbar";

const GEDModal = () => {
  return (
    <div style={{ height: "85vh", width: "85vw", display: "flex", flexDirection: "column" }}>
      <div>
        <FullToolbar />
      </div>
      <div style={{ flex: "1" }}>
        <NodesListing
          tableProps={{
            rowHeight: 35,
          }}
        />
      </div>
    </div>
  );
};

export default GEDModal;
