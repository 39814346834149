import React, { useContext } from "react";
import PropTypes from "prop-types";
import SelectPage from "components/adminComponents/SelectPage";
import { Box } from "@material-ui/core";
import PageVersionContext from "components/PageVersionContext";

const SubpageForm = (props) => {
  const { content, classes, onContentChange } = props;

  const { id, label } = content;

  const { currentPageVersion = {} } = useContext(PageVersionContext);
  const { page = {} } = currentPageVersion;
  const { lang } = page;

  const handleSelectPage = (p) => {
    const { versions, ...others } = p;
    versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
    const [lastVersion] = versions;
    if (lastVersion) {
      const pageVersion = {
        ...lastVersion,
        page: { ...others },
      };
      onContentChange({
        ...content,
        value: pageVersion,
      });
    }
  };

  return (
    <div key={id}>
      <h2 className={classes.title}>{label}</h2>
      <Box sx={{ maxWidth: 450 }}>
        <SelectPage
          currentPage={content.value && content.value.page}
          onSelectPage={handleSelectPage}
          label=""
          onlyPublishedPages={lang === "FR"}
          lang={lang}
        />
      </Box>
    </div>
  );
};

SubpageForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default SubpageForm;
