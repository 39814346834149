import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import dsfrIcons from "apps/front-office/public/utility/icons/utility-icons.json";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import InputForm from "components/adminComponents/contentForms/InputForm";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import SelectIconForm from "components/adminComponents/contentForms/SelectIconForm";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React from "react";

const IconForm = (props) => {
  const { content, onContentChange } = props;

  const { id, children, label } = content;

  const typeChild = children.find((c) => c.key === "type");
  const iconChild = children.find((c) => c.key === "icon");
  const groupChild = children.find((c) => c.key === "groupDSFR");
  const iconDSFRChild = children.find((c) => c.key === "iconDSFR");

  const handleChildChange = (oldChild) => (newChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === oldChild) {
          return newChild;
        }
        return child;
      }),
    });
  };

  return (
    <Box component="fieldset" key={id} sx={formStyled.fieldset}>
      <legend>{label || "Icône"}</legend>
      <Grid container alignItems="center">
        <SelectIconForm
          content={groupChild}
          onContentChange={handleChildChange(groupChild)}
          onIconContentChange={handleChildChange(iconDSFRChild)}
          child={iconDSFRChild}
        >
          <Icon
            iconDSFR={iconDSFRChild?.value}
            title={iconDSFRChild?.value}
            isHidden={false}
            aria-label={`Logo sélectionné: ${iconDSFRChild?.value}`}
          />
        </SelectIconForm>
        {dsfrIcons &&
          groupChild &&
          dsfrIcons[groupChild.value]?.map((icon) => (
            <Grid
              item
              xs={1}
              p={1}
              key={icon}
              sx={{
                textAlign: "center",
                cursor: "pointer",
                bgcolor: icon === iconDSFRChild?.value ? "secondary.20" : "transparent",
                border: icon === iconDSFRChild?.value ? "1px solid" : "none",
                borderColor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.20",
                },
              }}
              onClick={() =>
                handleChildChange(iconDSFRChild)({
                  ...iconDSFRChild,
                  value: icon,
                })
              }
            >
              <Icon iconDSFR={icon} title={icon} />
            </Grid>
          ))}
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          width: `calc(100% + 24px)`,
          mt: 1.5,
          mb: -1.5,
          mx: -1.5,
          px: 1.5,
          py: 1.5,
          borderTop: "1px solid grey",
          bgcolor: "rgba(0, 0, 0, 0.07)",
          opacity: "0.75",
        }}
      >
        <Grid item xs={12} pb={1}>
          <i>(Utilisation dépréciée, préférer les icônes du DSFR ci-dessus)</i>
        </Grid>
        <Grid item xs={6}>
          <InputForm content={iconChild} onContentChange={handleChildChange(iconChild)} />
        </Grid>
        <Grid item xs={6}>
          <SelectForm content={typeChild} onContentChange={handleChildChange(typeChild)} />
        </Grid>
        <Link
          url="https://fontawesome.com/icons?d=gallery&s=light"
          external
          underline="always"
          color="primary"
          sx={{ fontSize: "0.79rem" }}
        >
          Voir la liste des icônes
        </Link>
      </Grid>
    </Box>
  );
};

IconForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default IconForm;
