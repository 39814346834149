import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const fieldsetCustomStyled = {
  border: "1px solid rgba(0,0,0, 0.28)",
  borderRadius: 1,
  "& legend": {
    fontSize: "0.875rem",
  },
};

const AdminBannerContent = (props) => {
  const { contents = [], errors = {}, lang, onChange, showError, title } = props;

  const getValue = useCallback(
    (name) => (contents?.length ? contents.find((content) => content.lang === lang)?.[name] : ""),
    [contents, lang]
  );

  return (
    <Grid item xs={6}>
      <Box component="fieldset" sx={fieldsetCustomStyled}>
        <FormLabel component="legend">{title}</FormLabel>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl error={showError(`title${lang}`)}>
              <TextField
                name="title"
                label="Titre du bandeau *"
                value={getValue("title")}
                defaultValue={getValue("title")}
                variant="standard"
                onChange={onChange}
                error={showError(`title${lang}`)}
              />
              {showError(`title${lang}`) && <FormHelperText>{errors[`title${lang}`]}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              name="text"
              label="Contenu du bandeau"
              value={getValue("text")}
              defaultValue={getValue("text")}
              onChange={onChange}
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs>
              <TextField
                name="linkLabel"
                label="Libellé du lien"
                value={getValue("linkLabel")}
                defaultValue={getValue("linkLabel")}
                variant="standard"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs>
              <TextField
                name="link"
                label="Url du lien"
                value={getValue("link")}
                defaultValue={getValue("link")}
                variant="standard"
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

AdminBannerContent.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  errors: PropTypes.shape(),
  lang: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AdminBannerContent;
