import Box from "@mui/material/Box";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import ImageForm from "components/adminComponents/contentForms/ImageForm";
import LinkForm from "components/adminComponents/contentForms/LinkForm";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import TextAreaForm from "components/adminComponents/contentForms/TextAreaForm";
import PropTypes from "prop-types";
import React from "react";
import elementsTypes from "utils/elementsTypes";

const DSFRSubpageForm = (props) => {
  const { content, onContentChange = null } = props;

  const { id, label, children } = content;

  const selectChild = children.find((c) => c.type === elementsTypes.SELECT);
  const titleChild = children.find((c) => c.key === "title");
  const descriptionChild = children.find((c) => c.key === "description");
  const linkChild = children.find((c) => c.type === elementsTypes.LINK);
  const imageChild = children.find((c) => c.type === elementsTypes.IMAGE);

  const handleChildChange = (oldChild) => (newChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === oldChild) {
          return newChild;
        }
        if (child === titleChild && newChild.type === elementsTypes.SELECT) {
          const optionSelected = newChild?.options.find((ch) => ch.value === newChild.value) || {};
          return {
            ...child,
            value: optionSelected.label,
          };
        }
        return child;
      }),
    });
  };

  return (
    <div key={id}>
      <Box component="h2" sx={formStyled.title}>
        {label}
      </Box>
      <Box sx={{ maxWidth: 450 }}>
        <SelectForm content={selectChild} onContentChange={handleChildChange(selectChild)} />
      </Box>
      {selectChild?.value && selectChild.value === "KNOW_EVERYTHING" && (
        <>
          <TextAreaForm content={descriptionChild} onContentChange={handleChildChange(descriptionChild)} />
          <ImageForm content={imageChild} onContentChange={handleChildChange(imageChild)} />
        </>
      )}
      {selectChild?.value && selectChild.value !== "KNOW_EVERYTHING" && (
        <LinkForm content={linkChild} onContentChange={handleChildChange(linkChild)} />
      )}
    </div>
  );
};

DSFRSubpageForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func,
};

export default DSFRSubpageForm;
