import Box from "@mui/material/Box";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import InputForm from "components/adminComponents/contentForms/InputForm";
import PropTypes from "prop-types";
import React from "react";

const TabForm = (props) => {
  const { content, onContentChange } = props;
  const { label, children } = content;

  const titleChild = children.find((c) => c.key === "title");

  const handleChange = (newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === titleChild ? newChild : c)),
    });
  };

  return (
    <>
      <Box component="h2" sx={formStyled.title}>
        {label}
      </Box>
      <InputForm content={titleChild} onContentChange={handleChange} />
    </>
  );
};

TabForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default TabForm;
