import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import elementsTypes from "utils/elementsTypes";
import CardForm from "components/adminComponents/contentForms/CardForm";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import FileForm from "components/adminComponents/contentForms/FileForm";
import IconForm from "components/adminComponents/contentForms/IconForm";
import ImageForm from "components/adminComponents/contentForms/ImageForm";
import InputForm from "components/adminComponents/contentForms/InputForm";
import LinkForm from "components/adminComponents/contentForms/LinkForm";
import SubpageForm from "components/adminComponents/contentForms/SubpageForm";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import SliderForm from "components/adminComponents/contentForms/SliderForm";
import TextAreaForm from "components/adminComponents/contentForms/TextAreaForm";
import TabForm from "components/adminComponents/contentForms/TabForm";
import TabsForm from "components/adminComponents/contentForms/TabsForm";
import SiteSettingsForm from "components/adminComponents/contentForms/SiteSettingsForm";
import SiteOnboardingForm from "components/adminComponents/contentForms/SiteOnboardingForm";
import ContactFormSubjectListForm from "components/adminComponents/contentForms/ContactFormSubjectListForm";
import ContactFormProfileListForm from "components/adminComponents/contentForms/ContactFormProfileListForm";
import allNews from "components/templates/news/allNews";
import lastNews from "components/templates/news/lastNews";
import news from "components/templates/news/news";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
  },
  fieldset: {
    marginBottom: "0.875rem",
    "& legend": {
      fontSize: "0.875rem",
    },
  },
  formControl: (props) => ({
    marginBottom: "0.875rem",
    width: props.width ? props.width : undefined,
    marginRight: props.width ? theme.spacing(1.5) : undefined,
  }),
  info: {
    display: "flex",
    alignItems: "center",
  },
  slider: {
    margin: theme.spacing(2, "auto", 0),
    width: "90%",
  },
  iconWrapper: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: "1.5rem",
    height: "1.5rem",
  },
  iconForm: {
    width: `calc(100% + ${theme.spacing(3)}px)`,
    margin: theme.spacing(1.5, -1.5, -1.5),
    padding: theme.spacing(1, 1.5),
    borderTop: "1px solid grey",
    backgroundColor: "rgba(0, 0, 0, 0.07)",
    opacity: "0.75",
  },
  iconDSFRItem: {
    textAlign: "center",
    padding: `${theme.spacing(1.25, 0.5)} !important`,
  },
  hover: {
    "&:hover": {
      backgroundColor: theme.palette.secondary[20],
    },
  },
  switchLabel: {
    fontSize: "1rem",
  },
  thumb: {
    backgroundColor: theme.palette.secondary.main,
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ContentForm = (props) => {
  const { content, topLevel, onContentChange } = props;

  const { type, id, label, width, children, editionModal } = content;

  const classes = useStyles({ width });

  if (editionModal && !topLevel) {
    return null;
  }

  let Form = null;
  let formProps = {};

  switch (type) {
    case elementsTypes.EDITABLE_INPUT:
    case elementsTypes.INPUT:
    case elementsTypes.URL:
    case elementsTypes.NUMERIC:
      Form = InputForm;
      break;
    case elementsTypes.SHORT_TEXT:
      Form = TextAreaForm;
      break;
    case elementsTypes.CHECKBOX:
      Form = CheckboxForm;
      break;
    case elementsTypes.SELECT:
      Form = SelectForm;
      break;
    case elementsTypes.IMAGE:
      Form = ImageForm;
      break;
    case elementsTypes.FILE_IMAGE:
      formProps = { image: true };
      Form = FileForm;
      break;
    case elementsTypes.FILE:
      Form = FileForm;
      break;
    case elementsTypes.LINK:
      Form = LinkForm;
      break;
    case elementsTypes.CARD:
      Form = CardForm;
      break;
    case elementsTypes.CARD_NEWS:
      formProps = { template: [news.key] };
      Form = CardForm;
      break;
    case elementsTypes.OLD_CARD_NEWS:
      formProps = { template: [allNews.key, lastNews.key], inAllSites: true };
      Form = CardForm;
      break;
    case elementsTypes.SUBPAGE:
      Form = SubpageForm;
      break;
    case elementsTypes.ICON:
      Form = IconForm;
      break;
    case elementsTypes.TAB:
    case elementsTypes.TAB_CARDS:
      Form = TabForm;
      break;
    case elementsTypes.TABS:
    case elementsTypes.TABS_CARDS:
      Form = TabsForm;
      break;
    case elementsTypes.SLIDER:
      Form = SliderForm;
      break;
    case elementsTypes.SITE_SETTINGS:
      Form = SiteSettingsForm;
      break;
    case elementsTypes.SITE_ONBOARDING:
      Form = SiteOnboardingForm;
      break;
    case elementsTypes.CONTACT_FORM_PROFILE_LIST:
      Form = ContactFormProfileListForm;
      break;
    case elementsTypes.CONTACT_FORM_SUBJECT_LIST:
      Form = ContactFormSubjectListForm;
      break;
    default:
      break;
  }
  if (Form) {
    return <Form content={content} onContentChange={onContentChange} classes={classes} {...formProps} />;
  }
  if (children) {
    const childrenForm = children
      .map((child) => {
        const onChildChange = (newChild) => {
          onContentChange({
            ...content,
            children: children.map((c) => (c === child ? newChild : c)),
          });
        };
        return ContentForm({ content: child, onContentChange: onChildChange, topLevel: false });
      })
      .filter((c) => !!c);
    if (childrenForm.length) {
      if (topLevel) {
        return (
          <Fragment>
            <h2 className={classes.title}>{label}</h2>
            <div>{childrenForm}</div>
          </Fragment>
        );
      }
      return (
        <fieldset key={id} className={classes.fieldset}>
          <legend>{label}</legend>
          {childrenForm}
        </fieldset>
      );
    }
  }
  return null;
};

ContentForm.propTypes = {
  content: PropTypes.shape().isRequired,
  topLevel: PropTypes.bool,
  editionModal: PropTypes.bool,
  onContentChange: PropTypes.func,
};

ContentForm.defaultProps = {
  topLevel: false,
  editionModal: false,
  onContentChange: null,
};

export default ContentForm;
