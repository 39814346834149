import Grid from "@mui/material/Grid";
import AdminMenuItems from "components/adminComponents/AdminMenuItems";
import PropTypes from "prop-types";
import React from "react";

const columns = ["LinkExternal", "LinkInternal1", "LinkInternal2"];

const AdminFooterForm = (props) => {
  const { footer = {}, onFooterChange } = props;
  const { contents = [] } = footer;

  const handleItemsChange = (columnKey) => (items) => {
    onFooterChange({
      ...footer,
      contents: [...contents.filter((child) => child.key !== columnKey), ...items],
    });
  };

  return (
    <Grid container justifyContent="space-between" sx={{ my: 1.5 }}>
      {columns.map((columnKey, index) => (
        <Grid item key={columnKey} xs={4}>
          <h4>Colonne {index + 1} :</h4>
          <AdminMenuItems
            items={contents.filter((c) => c.key === columnKey)}
            itemKey={columnKey}
            onItemsChange={handleItemsChange(columnKey)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

AdminFooterForm.propTypes = {
  footer: PropTypes.shape(),
  onFooterChange: PropTypes.func.isRequired,
};

export default AdminFooterForm;
