import axios from "axios";
import SnackbarUtil from "da-ged-web/utils/SnackbarUtil";
import axiosCatchError from "./AxiosCatchErrorImplement";
// import i18n from "../../src/components/StandAlone/i18n/i18n";

const i18n = { t: (m) => m };

const API_GED_URL = "/da-ged";

export default class FolderService {
  static getRootId = () => {
    return axios
      .get(`${API_GED_URL}/api/folders/root`)
      .then((response) => {
        return response.data && response.data.identifierKey;
      })
      .catch((error) => axiosCatchError(error));
  };

  static createFolder = ({ name, parentId = null } = {}) => {
    return axios
      .post(`${API_GED_URL}/api/folders`, {
        name,
        parent: parentId ? { id: parentId } : null,
      })
      .then((response) => {
        if (response) {
          SnackbarUtil.setSnackbar({
            status: "success",
            message: i18n.t("Folder created"),
          });
        }
      })
      .catch((error) => {
        const customError = axiosCatchError(error);

        if (customError.error === "name.already.exist") {
          throw new Error(i18n.t("Folder exist"));
        } else {
          SnackbarUtil.setSnackbar({
            status: "error",
            message: i18n.t("Folder created error"),
          });
          throw new Error();
        }
      });
  };

  static downloadFolder = (folderId) => {
    return axios
      .get(`${API_GED_URL}/api/folders/${folderId}?alt=media`)
      .then((archiveUrl) => {
        return axios
          .get(`${API_GED_URL}/${archiveUrl.data.downloadUrl}`, {
            responseType: "blob",
          })
          .then((response) => {
            return response.data;
          })
          .catch((error) => axiosCatchError(error));
      })
      .catch((error) => axiosCatchError(error));
  };

  static deleteFolder = (nodeId) => {
    return axios.delete(`${API_GED_URL}/api/folders/${nodeId}`).catch((error) => axiosCatchError(error));
  };

  static trulyDeleteFolder = (nodeId) => {
    return axios.delete(`${API_GED_URL}/api/trash/folders/${nodeId}`).catch((error) => axiosCatchError(error));
  };

  static renameFolder = ({ folderId, name } = {}) => {
    return axios
      .patch(`${API_GED_URL}/api/folders/${folderId}`, {
        name,
      })
      .then((response) => {
        if (response) {
          SnackbarUtil.setSnackbar({
            status: "success",
            message: i18n.t("Node renamed"),
          });
        }
      })
      .catch((error) => {
        const customError = axiosCatchError(error);

        if (customError.error === "name.already.exist") {
          throw new Error(i18n.t("Folder exist"));
        } else {
          SnackbarUtil.setSnackbar({
            status: "error",
            message: i18n.t("Node renamed error"),
          });
          throw new Error();
        }
      });
  };

  static moveFolder = ({ folderId, parent, message } = {}) => {
    return axios
      .patch(`${API_GED_URL}/api/folders/${folderId}`, {
        parent,
      })
      .then((response) => {
        if (response) {
          SnackbarUtil.setSnackbar({
            status: "success",
            message,
          });
        }
      })
      .catch((error) => {
        const moveError = axiosCatchError(error);
        if (moveError && moveError.error === "folder.not.found") {
          throw new Error(i18n.t("Folder not found"));
        } else if (moveError && moveError.error === "name.already.exist") {
          throw new Error(i18n.t("Folder isn't move"));
        } else {
          SnackbarUtil.setSnackbar({
            status: "error",
            message: i18n.t("Folder operation impossible"),
          });
          throw new Error();
        }
      });
  };

  static getBreadcrumb = (nodeId) => {
    return axios
      .get(`${API_GED_URL}/api/folders/${nodeId || "root"}/breadcrumb`)
      .catch((error) => axiosCatchError(error));
  };

  static restoreFolder = async (nodeId) => {
    try {
      await axios.patch(`${API_GED_URL}/api/trash/folders/${nodeId}`, {
        deleted: false,
      });
      return true;
    } catch (error) {
      return false;
    }
  };
}
