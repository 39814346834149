import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import AdminContext from "components/adminComponents/AdminContext";
import AdminPagesService from "components/adminServices/AdminPagesService";
import templates from "components/templates/templates";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getSiteName } from "utils/commonUtils";

const DEFAULT_INPUT_VALUE = "Aucune";
const DEFAULT_PAGE = {
  id: "",
  name: DEFAULT_INPUT_VALUE,
  templateLabel: "",
  versions: [],
};

// Empty array is defined outside of component to avoid React to loop on `useEffect`
const emptyArray = [];

const SelectPage = (props) => {
  const {
    currentPage = "",
    onSelectPage,
    filters = emptyArray,
    disabled = false,
    label = "",
    onlyPublishedPages = false,
    onlyFrenchPages = false,
    lang = "",
    englishPage = false,
    switchComponent = false,
    inAllSites = false,
    selectedSiteName = "",
  } = props;

  const { currentSite = {}, allSites } = useContext(AdminContext);

  const [pages, setPages] = useState([]);

  const [inputValue, setInputValue] = useState(currentPage?.name || "");

  const getSiteNameBySiteId = useCallback(
    (id) => {
      if (allSites.find((site) => site.id === id)?.name) {
        return getSiteName(allSites.find((site) => site.id === id)?.name);
      }
      return "";
    },
    [allSites]
  );
  const getSiteIdBySiteName = useCallback(
    (name) => {
      if (allSites.find((site) => site.name === name)?.id) {
        return allSites.find((site) => site.name === name)?.id;
      }
      return "";
    },
    [allSites]
  );
  const filter = useMemo(() => {
    const newFilters = [...filters];
    if (!inAllSites && currentSite) {
      newFilters.push(`siteId||eq||${currentSite.id}`);
    }
    if (inAllSites && getSiteIdBySiteName(selectedSiteName)) {
      newFilters.push(`siteId||eq||${getSiteIdBySiteName(selectedSiteName)}`);
    }
    if (onlyPublishedPages) {
      newFilters.push("isPublished||eq||1");
    }
    if (onlyFrenchPages) {
      newFilters.push("lang||eq||FR");
    }
    if (lang && !disabled) {
      newFilters.push(`lang||eq||${lang}`);
    }
    if (switchComponent) {
      newFilters.push(`lang||eq||${englishPage ? "EN" : "FR"}`);
    }
    return newFilters;
  }, [
    currentSite,
    disabled,
    englishPage,
    filters,
    getSiteIdBySiteName,
    inAllSites,
    lang,
    onlyFrenchPages,
    onlyPublishedPages,
    selectedSiteName,
    switchComponent,
  ]);

  useEffect(() => {
    if (currentSite && currentSite.id) {
      AdminPagesService.find({
        params: {
          filter,
          join: ["versions"],
          sort: "name,ASC",
          per_page: 500,
        },
      }).then((res) => {
        if (filters.length) {
          res.sort((a, b) => getSiteNameBySiteId(a.siteId).localeCompare(getSiteNameBySiteId(b.siteId)));
        }
        return setPages(res);
      });
    }
  }, [currentSite, onlyPublishedPages, filter, filters, getSiteNameBySiteId]);

  const sortedPages = useMemo(() => {
    const filteredPages = pages
      .filter((page) => page.versions && page.versions.length > 0 && (!onlyPublishedPages || page.isPublished))
      .map((page) => ({
        ...page,
        templateLabel:
          page.versions?.length > 0
            ? templates.find((template) => template.key === page.versions[0].template)?.label || ""
            : "",
      }))
      .sort((a, b) => a.templateLabel.localeCompare(b.templateLabel));
    return filteredPages;
  }, [onlyPublishedPages, pages]);

  const getPage = () => {
    if (inputValue === DEFAULT_INPUT_VALUE && !currentPage) {
      return DEFAULT_PAGE;
    }
    if (currentPage && !currentPage?.name && currentPage.id) {
      return pages.find((page) => page.id === currentPage.id) || DEFAULT_PAGE;
    }
    return currentPage;
  };

  const handleSelectPageChange = (e, value) => {
    if (value && !(value instanceof String)) {
      const { id } = value;
      const page = pages.find((p) => p.id === id);
      onSelectPage(page);
      setInputValue(page?.name || "");
    } else {
      setInputValue(value || "");
    }
  };

  const getLabel = (page) => {
    const { name, lang: langPage, siteId } = page;
    const siteName = inAllSites && siteId ? `[${getSiteNameBySiteId(siteId)}] ` : "";
    const language = langPage ? ` (${langPage})` : "";
    return `${siteName}${name}${language}`;
  };

  return (
    <Autocomplete
      sx={{
        "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
          width: "26px",
          height: "26px",
        },
      }}
      value={getPage() || null}
      inputValue={inputValue}
      options={sortedPages}
      openText="Ouvrir"
      clearText="Effacer"
      getOptionLabel={(option) => (option && option.name ? getLabel(option) : "")}
      noOptionsText="Aucune page correspondante"
      groupBy={(option) => (option && option.templateLabel) || ""}
      onChange={handleSelectPageChange}
      onInputChange={(e, value) => setInputValue(value)}
      disabled={!!disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          label={label}
          name="page"
          placeholder="-- Sélectionner une page --"
          disabled={!!disabled}
        />
      )}
      clearIcon={
        <IconButton key="clear" aria-label="Supprimer" color="inherit" onClick={() => onSelectPage(null)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

SelectPage.propTypes = {
  currentPage: PropTypes.oneOfType([PropTypes.shape(), PropTypes.oneOf([""])]),
  onSelectPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onlyPublishedPages: PropTypes.bool,
  onlyFrenchPages: PropTypes.bool,
  lang: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  englishPage: PropTypes.bool,
  switchComponent: PropTypes.bool,
  inAllSites: PropTypes.bool,
  selectedSiteName: PropTypes.string,
};

export default SelectPage;
