import Box from "@mui/material/Box";
import AdminContext from "components/adminComponents/AdminContext";
import LayoutFront from "components/LayoutFront/LayoutFront";
import Page from "components/Page";
import PageVersionContext from "components/PageVersionContext";
import { SiteContextProvider } from "components/SiteContext";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import generateEditablePage from "utils/templateAdminUtils";
import getTemplateComponentByKey from "utils/templateUtils";

const RenderPage = (props) => {
  const { page = null, handlePageChange, editionMode } = props;

  const { currentSite, currentPageVersion, setCurrentPageVersion } = useContext(AdminContext);

  const pageVersionContextValue = useMemo(
    () => ({ currentPageVersion, setCurrentPageVersion }),
    [currentPageVersion, setCurrentPageVersion]
  );

  if (!page) {
    return null;
  }

  const TemplateTag = getTemplateComponentByKey(page.template);

  return (
    <SiteContextProvider site={currentSite}>
      <PageVersionContext.Provider value={pageVersionContextValue}>
        <LayoutFront bo>
          <Box
            sx={{
              "& .cke_editable, & input": {
                border: "1px dashed #999",
                background: "transparent",
                fontSize: "inherit",
                textAlign: "inherit",
                letterSpacing: "inherit",
                p: 0,
              },
            }}
          >
            {editionMode ? (
              <TemplateTag page={generateEditablePage(page, handlePageChange, currentPageVersion)} />
            ) : (
              <Page page={page} />
            )}
          </Box>
        </LayoutFront>
      </PageVersionContext.Provider>
    </SiteContextProvider>
  );
};

RenderPage.propTypes = {
  page: PropTypes.shape(),
  handlePageChange: PropTypes.func.isRequired,
  editionMode: PropTypes.bool.isRequired,
};

export default RenderPage;
