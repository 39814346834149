export const formControlStyled = (width) => ({
  mb: 1.875,
  width: width || undefined,
  mr: width ? 1.5 : undefined,
});

export const formStyled = {
  title: {
    textAlign: "center",
  },
  fieldset: {
    mb: 1.75,
    "& legend": {
      fontSize: "0.875rem",
    },
  },
};
