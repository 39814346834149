import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";

const AutoResizeInput = (props) => {
  const { value = "", onBlur = null, onChange, debounce = 2000, maxSize = null, ...others } = props;

  const [showInput, setShowInput] = useState(false);

  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    setLocalValue(inputValue);
    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }
    setCurrentTimeOut(
      setTimeout(() => {
        onChange(inputValue);
      }, debounce)
    );
  };

  const inputRef = createRef();

  useEffect(() => {
    if (inputRef.current && showInput) {
      inputRef.current.focus();
    }
  }, [showInput, inputRef]);

  const handleSizerClick = (e) => {
    e.stopPropagation();
    setShowInput(true);
  };

  const handleInputBlur = (e) => {
    setShowInput(false);
    if (typeof onBlur === "function") {
      onBlur(e);
    }
  };

  return (
    <>
      <Box
        component="span"
        onClick={handleSizerClick}
        sx={{
          display: showInput ? "none" : "inline-block",
          width: "100%",
          border: "1px dashed #999",
          minHeight: "1em",
          cursor: "text",
        }}
      >
        {localValue}
      </Box>
      <Box
        component="input"
        {...others}
        onChange={handleChange}
        value={localValue}
        ref={inputRef}
        onBlur={handleInputBlur}
        maxLength={maxSize}
        sx={{ display: showInput ? "inline-block" : "none", width: "100%", color: "inherit" }}
      />
      {maxSize && <p>{`Caractères : ${localValue?.length || 0}/${maxSize}`}</p>}
    </>
  );
};

AutoResizeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  debounce: PropTypes.number,
  maxSize: PropTypes.number,
};

export default AutoResizeInput;
