import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import keycloak from "apps/back-office/client/keycloak";
import AdminGeoPointForm from "components/adminComponents/AdminGeoPointForm";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import EnhancedTable from "components/adminComponents/EnhancedTable";
import InputFilter from "components/adminComponents/InputFilter";
import SelectDepartment from "components/adminComponents/SelectDepartment";
import AdminGeoPointsService from "components/adminServices/AdminGeoPointsService";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { alphaNumericFormatDate } from "utils/commonUtils";
import { exportDate } from "utils/contentsUtils";
import { v4 as uuidv4 } from "uuid";

const allStatus = {
  all: "all",
  active: "1",
  inactive: "0",
};

const endIconStyled = {
  position: "relative",
  "& > button": {
    position: "absolute",
    right: 6,
    p: 0.25,
    mt: 2.25,
  },
};

let searchTimeout = null;

const AdminGeoPoints = (props) => {
  const { type } = props;

  const categories = useMemo(() => {
    switch (type) {
      case "photographe":
        return { basic: "Photographe", specific: "Cabine" };
      case "mairie":
        return { basic: "Passeport", specific: "Certification" };
      default:
        return {};
    }
  }, [type]);

  const [uploaderId] = useState(`uploader-${uuidv4()}`);
  const [onUploadFile, setOnUploadFile] = useState(false);
  const [onExport, setOnExport] = useState(false);
  const [onUploadDirect, setOnUploadDirect] = useState(false);
  const [canRefresh, setCanRefresh] = useState(false);

  const [geoPoints, setGeoPoints] = useState({
    data: [],
    total: 0,
  });
  const [sorting, setSorting] = useState({
    order: "DESC",
    orderBy: "updated",
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [inputIdentValue, setInputIdentValue] = useState("");
  const [identifiant, setIdentifiant] = useState("");
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [specificType, setSpecificType] = useState("all");
  const [department, setDepartment] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [displaySearch, setDisplaySearch] = useState(false);

  const { order, orderBy } = sorting;

  const [createGeoPointModal, setCreateGeoPointModal] = useState(false);

  const { displaySuccess, displayError } = useContext(AdminMessageContext);

  const filter = useMemo(() => {
    const newFilters = [`type||eq||${type}`];
    if (search) {
      // Pas beau mais 'others' est un champ string côté API
      // La seule autre solution serait de brancher le tableau à l'elastic search
      newFilters.push(`others||cont||${search}`);
    }
    if (identifiant) {
      newFilters.push(`customId||cont||${identifiant}`);
    }
    if (status !== "all") {
      newFilters.push(`isActive||eq||${Number(allStatus[status])}`);
    }
    if (specificType !== "all") {
      newFilters.push(`category||eq||${categories[specificType].toLowerCase()}`);
    }
    if (department) {
      newFilters.push(`others||cont||"postalCode":"${department.code}`);
    }
    return newFilters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, search, identifiant, status, specificType, department]);

  const getGeoPoints = useCallback(() => {
    const options = {
      params: {
        filter,
        sort: [`${orderBy},${order}`],
        per_page: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      },
    };
    AdminGeoPointsService.find(options).then((geoPointsResults) => {
      const { data, total } = geoPointsResults;
      setGeoPoints({ data, total });
    });
  }, [filter, orderBy, order, rowsPerPage, page]);

  let customId = "";
  switch (type) {
    case "mairie":
      customId = "Code UGF";
      break;
    case "photographe":
      customId = "Agrément";
      break;
    case "siv":
      customId = "Habilitation";
      break;
    default:
      customId = "Identifiant";
  }

  const columns = [
    {
      id: "actions",
      label: "",
      cellProps: { sx: { width: "1px" } },
      render: () => <Icon icon="edit" iconDSFR="edit-box-line" title="Modifier" />,
    },
    {
      id: "customId",
      label: customId,
      render: (geoPoint) => geoPoint.customId,
    },
    {
      id: "name",
      label: "Dénomination",
      render: (geoPoint) => geoPoint.others && geoPoint.others.name,
    },
    {
      id: "address",
      label: "Adresse",
      render: (geoPoint) => geoPoint.others && geoPoint.others.address,
    },
    {
      id: "postalCode",
      label: "CP",
      render: (geoPoint) => geoPoint.others && geoPoint.others.postalCode,
    },
    {
      id: "city",
      label: "Ville",
      render: (geoPoint) => geoPoint.others && geoPoint.others.city,
    },
    {
      id: "updated",
      label: "Édité le",
      sortable: true,
      render: (geoPoint) => alphaNumericFormatDate(geoPoint.updated || geoPoint.created),
    },
    {
      id: "isActive",
      label: "Actif",
      sortable: true,
      render: (geoPoint) => (
        <Icon
          icon="circle"
          type="fas"
          iconDSFR={geoPoint.isActive ? "checkbox-circle-fill" : "close-circle-fill"}
          sx={{ color: geoPoint.isActive ? "green" : "red" }}
          title={geoPoint.isActive ? "Actif" : "Inactif"}
          aria-label={geoPoint.isActive ? "Actif" : "Inactif"}
        />
      ),
    },
  ];

  useEffect(() => {
    getGeoPoints();
  }, [getGeoPoints]);

  const handleChangeSort = (event, key) => {
    if (orderBy !== key) {
      setSorting({
        order: "ASC",
        orderBy: key,
      });
    } else {
      setSorting({
        order: order === "ASC" ? "DESC" : "ASC",
        orderBy: key,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleIdentifiantChange = (event) => {
    const { value } = event.target;
    setInputIdentValue(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      searchTimeout = null;
      setIdentifiant(value);
    }, 350);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setInputSearchValue(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      searchTimeout = null;
      setSearch(value);
    }, 350);
  };

  const handleResetSearch = () => {
    setInputSearchValue("");
    setSearch("");
  };

  const handleResetIdentifiant = () => {
    setInputIdentValue("");
    setIdentifiant("");
  };

  const handleSubmitGeoPoint = (callback) => (geoPoint) => {
    const onSuccess = () => {
      getGeoPoints();
      if (typeof callback === "function") {
        callback();
      }
    };
    if (geoPoint.id) {
      return AdminGeoPointsService.update(geoPoint)
        .then(() => {
          displaySuccess("Établissement mis à jour");
          onSuccess();
        })
        .catch((e) => {
          if (e?.response?.data?.detail?.includes("Duplicate entry")) {
            displayError("L'identifiant est déjà utilisé pour un autre établissement");
          } else {
            displayError("Échec lors de la mise à jour de l'établissement");
          }
        });
    }
    return AdminGeoPointsService.create(geoPoint)
      .then(() => {
        displaySuccess("Établissement créé");
        onSuccess();
      })
      .catch((e) => {
        if (e?.response?.data?.detail?.includes("Duplicate entry")) {
          displayError("L'identifiant est déjà utilisé pour un autre établissement");
        } else {
          displayError("Échec lors de la création de l'établissement");
        }
      });
  };

  const handleClickAddGeoPoint = () => {
    setCreateGeoPointModal(true);
  };

  const closeCreateGeoPointModal = () => {
    setCreateGeoPointModal(false);
  };

  const handleClickUpload = () => {
    document.getElementById(uploaderId).click();
  };

  const handleClickDirectUpload = () => {
    setOnUploadDirect(true);
    document.getElementById(uploaderId).click();
  };

  const handleUploadFile = (e) => {
    const [file] = e.target.files;
    let userId = null;
    let fullName = null;
    if (keycloak?.idTokenParsed) {
      userId = keycloak?.idTokenParsed?.sub;
      fullName = keycloak?.idTokenParsed?.preferred_username;
    }
    if (file && userId && fullName) {
      setOnUploadFile(true);
      AdminGeoPointsService.import({ file, type, userId, fullName, direct: onUploadDirect })
        .then(() => {
          displaySuccess("Fichier importé");
          document.getElementById(uploaderId).value = null;
          setOnUploadFile(false);
          setOnUploadDirect(false);
          setCanRefresh(true);
        })
        .catch((err) => {
          const message = err && err.response && err.response.data;
          displayError(`Échec lors de l'import du fichier ${message ? ` : ${message}` : "csv"}`);
          document.getElementById(uploaderId).value = null;
          setOnUploadFile(false);
          setOnUploadDirect(false);
          setCanRefresh(true);
        });
    }
  };

  const handleExport = () => {
    setOnExport(true);
    const params = {
      type,
      filter,
    };
    AdminGeoPointsService.export(params)
      .then((response) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.setAttribute("download", `Export_${type}_${exportDate}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setOnExport(false);
      })
      .catch(() => {
        displayError("Échec lors de l'export de la liste");
        setOnExport(false);
      });
  };

  const hasCategories = categories && Object.keys(categories).length > 0;

  const menuItems = [
    {
      label: "Ajouter un établissement",
      icon: "add-line",
      onClick: handleClickAddGeoPoint,
      disabled: false,
    },
    {
      label: "Importer en différé",
      icon: "upload-line",
      onClick: handleClickUpload,
      disabled: onUploadFile || !process.env.geoloc_import_folder,
      tooltip: "Les coordonnées des établissements sont enrichies grâce aux adresses postales. (Import biquotidien)",
    },
    {
      label: "Importer en direct",
      icon: "upload-line",
      onClick: handleClickDirectUpload,
      disabled: onUploadFile,
      tooltip: "Les coordonnées doivent être renseignés directement dans le CSV. (Import immédiat)",
    },
    {
      label: "Exporter la liste",
      icon: "download-line",
      onClick: handleExport,
      disabled: onExport || geoPoints.total === 0,
    },
  ];

  const nextStatus = useCallback((answer) => {
    const answerShifted = {
      all: "active",
      active: "inactive",
      inactive: "all",
    };
    return setStatus(answerShifted[answer]);
  }, []);

  const nextType = useCallback((answer) => {
    const answerShifted = {
      all: "basic",
      basic: "specific",
      specific: "all",
    };
    return setSpecificType(answerShifted[answer]);
  }, []);

  const searchReinit = () => {
    setIdentifiant("");
    setInputIdentValue("");
    setStatus("all");
    setSpecificType("all");
    setDepartment("");
    setSearch("");
    setInputSearchValue("");
  };

  return (
    <Stack sx={{ height: "100%" }}>
      <Box
        component="input"
        type="file"
        accept=".csv"
        id={uploaderId}
        onChange={handleUploadFile}
        sx={{ display: "none" }}
      />
      <h6>Liste des établissements ({type})</h6>
      <Box mb={2}>
        <Grid container justifyContent="space-between" spacing={2} alignItems="flex-start">
          <Grid item xs container direction="column" spacing={2}>
            <Grid item container alignItems="flex-end" spacing={3}>
              <Grid item xs={3} sx={endIconStyled}>
                <InputFilter
                  label="Par identifiant"
                  placeholder={customId}
                  inputValue={inputIdentValue}
                  handleChange={handleIdentifiantChange}
                  handleReset={handleResetIdentifiant}
                />
              </Grid>
              <Grid item xs={4} container alignItems="center">
                <Typography mr={2}>Statut :</Typography>
                {status === "all" && (
                  <MuiButton size="large" onClick={() => nextStatus(status)}>
                    Tous
                  </MuiButton>
                )}
                {status === "active" && (
                  <MuiButton size="large" onClick={() => nextStatus(status)} sx={{ color: "green" }}>
                    Actif
                  </MuiButton>
                )}
                {status === "inactive" && (
                  <MuiButton size="large" onClick={() => nextStatus(status)} sx={{ color: "red" }}>
                    Inactif
                  </MuiButton>
                )}
              </Grid>
              {hasCategories && (
                <Grid item xs={4} container alignItems="center">
                  <Typography mr={2}>Type :</Typography>
                  {specificType === "all" && (
                    <MuiButton size="large" onClick={() => nextType(specificType)}>
                      Tous
                    </MuiButton>
                  )}
                  {specificType === "basic" && (
                    <MuiButton size="large" onClick={() => nextType(specificType)} sx={{ color: "#396FF1" }}>
                      {categories[specificType]}
                    </MuiButton>
                  )}
                  {specificType === "specific" && (
                    <MuiButton size="large" onClick={() => nextType(specificType)} sx={{ color: "#F32121" }}>
                      {categories[specificType]}
                    </MuiButton>
                  )}
                </Grid>
              )}
              <Grid item xs={1} lg sx={{ textAlign: "end" }}>
                <IconButton size="small" color="secondary" onClick={() => setDisplaySearch(!displaySearch)}>
                  <Icon
                    iconDSFR={displaySearch ? "arrow-up-s-line" : "arrow-down-s-line"}
                    title={displaySearch ? "Masquer la recherche" : "Affiner la recherche"}
                  />
                </IconButton>
              </Grid>
            </Grid>
            {displaySearch && (
              <Grid item container spacing={2}>
                <Grid item xs={6} lg={6}>
                  <SelectDepartment department={department} setDepartment={setDepartment} />
                </Grid>
                <Grid item xs={6} lg={6} sx={endIconStyled}>
                  <InputFilter
                    label="Filtrer les établissements"
                    placeholder="Dénomination, adresse, etc..."
                    inputValue={inputSearchValue}
                    handleChange={handleSearchChange}
                    handleReset={handleResetSearch}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "center", mt: 1.5 }}>
            <IconButton size="small" color="secondary" onClick={getGeoPoints} disabled={!canRefresh}>
              <Icon
                iconDSFR="refresh-line"
                title="Rafraîchir le tableau pour afficher les établissements en cours d'import"
                aria-label="Rafraîchir"
              />
            </IconButton>
          </Grid>
          <Grid item xs="auto" sx={{ textAlign: "center", mt: 1.5 }}>
            <IconButton size="small" color="secondary" onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Icon
                iconDSFR="more-fill"
                title="Actions"
                aria-label="Menu d'actions"
                sx={{ transform: "rotate(90deg)" }}
              />
            </IconButton>
          </Grid>
          <Menu
            id="menu-actions"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {menuItems.map((item) => {
              const { label, icon, onClick, disabled, tooltip } = item;
              return (
                <MenuItem
                  key={label}
                  disabled={disabled}
                  onClick={() => {
                    onClick();
                    setAnchorEl(null);
                  }}
                >
                  <Icon iconDSFR={icon} />
                  <Typography sx={{ ml: 2 }}>{label}</Typography>
                  {tooltip && <Icon iconDSFR="information-line" title={tooltip} sx={{ ml: 2 }} />}
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
      </Box>
      <Box sx={{ flex: "1 0 auto" }}>
        {geoPoints.total === 0 &&
        (identifiant || status !== "all" || specificType !== "all" || department || search) ? (
          <Grid container direction="column" justifyContent="center" alignItems="center" height="100%" spacing={3}>
            <Grid item>
              <p>Aucun établissement</p>
            </Grid>
            <Grid item>
              <button type="button" className="fr-link" onClick={() => searchReinit()}>
                Réinitialiser la recherche
              </button>
            </Grid>
          </Grid>
        ) : (
          <EnhancedTable
            columns={columns}
            data={geoPoints.data}
            order={order}
            orderBy={orderBy}
            page={page}
            totalRows={geoPoints.total}
            rowsPerPage={rowsPerPage}
            onRequestSort={handleChangeSort}
            onChangePage={setPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            renderRowDetail={(row, closeDetail) => (
              <AdminGeoPointForm
                geoPoint={row}
                onSubmit={handleSubmitGeoPoint(closeDetail)}
                onCancel={closeDetail}
                submitLabel="Mettre à jour"
                type={type}
                getGeoPoints={getGeoPoints}
              />
            )}
          />
        )}
      </Box>
      <Modal open={createGeoPointModal} size="lg" onClose={closeCreateGeoPointModal}>
        <h2>Nouvel établissement (type {type})</h2>
        <AdminGeoPointForm
          onSubmit={handleSubmitGeoPoint(closeCreateGeoPointModal)}
          onCancel={closeCreateGeoPointModal}
          submitLabel="Créer"
          type={type}
        />
      </Modal>
    </Stack>
  );
};

AdminGeoPoints.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AdminGeoPoints;
