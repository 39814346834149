import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import PropTypes from "prop-types";
import React from "react";

const SelectContentModal = (props) => {
  const { open, contents, onSelectContent, onClose } = props;

  const theme = useTheme();

  const handleClickContent = (content) => {
    onSelectContent(content);
  };

  const getIcon = (c) => {
    const { icon, iconDSFR, IconComponent } = c || {};
    if (IconComponent) {
      return <IconComponent color="white" backgroundColor={theme.palette.primary[70]} />;
    }
    if (iconDSFR) {
      return <Icon iconDSFR={iconDSFR} type="fas" sx={{ color: "primary.70" }} />;
    }
    if (icon) {
      return <Icon icon={icon} type="fas" sx={{ color: "primary.70" }} />;
    }
    return null;
  };

  contents.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Modal
      aria-labelledby="content-type"
      aria-describedby="select-content-type"
      size="sm"
      open={open}
      onClose={onClose}
    >
      <Box
        component="h2"
        id="content-type"
        sx={{ textAlign: "center", pb: 1.5, borderBottom: "1px solid", borderColor: "secondary.main" }}
      >
        Choix de l&apos;élément
      </Box>
      <Grid container id="simple-modal-description">
        {contents.map((content) => (
          <Grid
            item
            xs={4}
            container
            direction="column"
            alignItems="center"
            key={content.type}
            p={0.75}
            sx={{
              cursor: "pointer",
              "&:hover": {
                bgcolor: "secondary.20",
              },
            }}
            onClick={() => handleClickContent(content)}
          >
            <Grid item sx={{ width: 24, height: 24, fontSize: 24, mb: 1 }}>
              {getIcon(content)}
            </Grid>
            <Box component="p" sx={{ color: "primary.70", textAlign: "center" }}>
              {content.label}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
};

SelectContentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  contents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectContent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SelectContentModal;
