import React, { Fragment } from "react";
import AdminActions from "components/adminComponents/AdminActions";
import AdminTabWrapper from "components/adminComponents/AdminTabWrapper";
import AdminTabsWrapper from "components/adminComponents/AdminTabsWrapper";
import DynamicAdmin from "components/adminComponents/DynamicAdmin";
import AutoResizeInput from "components/adminComponents/AutoResizeInput";
import AdminContentsGroups from "components/adminComponents/AdminContentsGroups";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import SliderForm from "components/adminComponents/contentForms/SliderForm";
import CKEditorCustom from "components/adminComponents/CKEditorCustom";
import { enhanceContent, generateContentId } from "utils/adminContentsUtils";
import { getDynamicComponent, formatPageImage } from "utils/templatePropsUtils";
import { fullToolbarEditor, descriptionToolbarEditor } from "utils/commonUtils";
import elementsTypes from "utils/elementsTypes";
import { getTitleMaxsize, getShortDescriptionMaxsize } from "utils/newsTemplateUtils";
import SelectSiteName from "components/adminComponents/SelectSiteName";
import news from "components/templates/news/news";

const getEditorComponent = ({ content, value, handleContentChange, index }) => {
  const { type, id, editionModal } = content;

  const handleValueChange = (newValue) => {
    handleContentChange({
      ...content,
      value: newValue,
    });
  };

  const handleAddChild = (newChild, i) => {
    const newChildren = [...(content.children || [])];
    // Filter children with same key
    const filterChildren = newChildren.filter((child) => child.key === newChild.key);
    let prevChild = null;
    if (i > 0) {
      prevChild = filterChildren[i - 1];
    }
    newChildren.splice(newChildren.indexOf(prevChild) + 1, 0, generateContentId(newChild));
    handleContentChange({
      ...content,
      children: newChildren,
    });
  };

  const handleRemoveChild = (i, key) => {
    const newChildren = [...(content.children || [])];
    const filterChildren = newChildren.filter((child) => child.key === key);
    const childToRemove = filterChildren[i];
    handleContentChange({
      ...content,
      children: newChildren.filter((child) => child !== childToRemove),
    });
  };

  const handleRemoveContent = () => {
    handleContentChange();
  };

  const handleDecode = (v) => {
    try {
      return decodeURIComponent(v);
    } catch (e) {
      return v;
    }
  };

  if (type) {
    let editorComponent = null;
    switch (type) {
      case elementsTypes.EDITABLE_INPUT:
        editorComponent = <AutoResizeInput value={value} onChange={(v) => handleValueChange(v)} />;
        break;
      case elementsTypes.HIGHLIGHTED_TEXT:
      case elementsTypes.TEXT:
        editorComponent = getDynamicComponent(
          type,
          <CKEditorCustom
            key={id}
            onChange={(data) => handleValueChange(encodeURIComponent(data))}
            data={handleDecode(value)}
            type="inline"
            config={{ toolbar: fullToolbarEditor }}
          />,
          id
        );
        break;
      case elementsTypes.DYNAMIC: {
        editorComponent = (
          <DynamicAdmin
            contentKey="dynamicElements"
            addContentText="Insérer un élément ici"
            deleteContentText="Supprimer cet élément"
            contentsTypes={[
              elementsTypes.HIGHLIGHTED_TEXT,
              elementsTypes.TEXT,
              elementsTypes.EXPANSION_TEXT,
              elementsTypes.BUTTON,
              elementsTypes.ICON_LINK,
              elementsTypes.ATTACHMENT,
              elementsTypes.ATTACHMENTS,
              elementsTypes.TIMELINE,
              elementsTypes.ACCORDION,
              elementsTypes.EXPANSION_PANEL,
              elementsTypes.IMAGE_BLOCK,
              elementsTypes.VIDEO,
              elementsTypes.GRID,
              elementsTypes.RESULT_LIST,
              elementsTypes.INFO_BLOCK,
              elementsTypes.MAILTO,
              elementsTypes.QUOTE,
            ]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {getDynamicComponent(type, value, id)}
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.TIMELINE: {
        editorComponent = getDynamicComponent(
          type,
          {
            steps: (
              <DynamicAdmin
                contentKey="steps"
                addContentText="Ajouter une étape"
                deleteContentText="Supprimer cette étape"
                contentsTypes={[elementsTypes.STEP_V2]}
                onAddContent={handleAddChild}
                onRemoveContent={handleRemoveChild}
              >
                {value && value.steps}
              </DynamicAdmin>
            ),
          },
          id
        );
        break;
      }
      case elementsTypes.STEP_V2_CONTENT: {
        editorComponent = (
          <DynamicAdmin
            contentKey="detailsElements"
            addContentText="Ajouter un élément"
            deleteContentText="Supprimer cet élément"
            contentsTypes={[
              elementsTypes.TEXT,
              elementsTypes.EXPANSION_TEXT,
              elementsTypes.BUTTON,
              elementsTypes.ICON_LINK,
              elementsTypes.EXPANSION_PANEL,
            ]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {value && value.detailsElements}
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.ACCORDION: {
        editorComponent = (
          <DynamicAdmin
            contentKey="panels"
            addContentText="Insérer un panneau"
            deleteContentText="Supprimer ce panneau"
            contentsTypes={[elementsTypes.ACCORDION_PANEL]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {value && value.panels}
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.RESULT_LIST: {
        let { results = [] } = value;
        if (!Array.isArray(results)) {
          results = [results];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="results"
            addContentText="Insérer un résultat"
            deleteContentText="Supprimer ce résultat"
            contentsTypes={[elementsTypes.RESULT]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {/* {value && value.results} */}
            {(appendButtonToNode) =>
              results.length > 0 &&
              getDynamicComponent(type, { ...value, results: results.map(appendButtonToNode) }, id)
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.ACCORDION_PANEL:
        editorComponent = getDynamicComponent(elementsTypes.EXPANSION_PANEL, value, id);
        break;
      case elementsTypes.EXPANSION_PANEL_DETAILS: {
        editorComponent = (
          <DynamicAdmin
            contentKey="detailsElements"
            addContentText="Insérer un élément"
            deleteContentText="Supprimer cet élément"
            contentsTypes={[
              elementsTypes.HIGHLIGHTED_TEXT,
              elementsTypes.TEXT,
              elementsTypes.EXPANSION_TEXT,
              elementsTypes.BUTTON,
              elementsTypes.ICON_LINK,
              elementsTypes.ATTACHMENT,
              elementsTypes.ATTACHMENTS,
              elementsTypes.TIMELINE,
              elementsTypes.GRID,
              elementsTypes.INFO_BLOCK,
              elementsTypes.MAILTO,
              elementsTypes.QUOTE,
            ]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {value && value.detailsElements}
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.STEPPER: {
        editorComponent = (
          <DynamicAdmin
            contentKey="steps"
            addContentText="Insérer une étape"
            deleteContentText="Supprimer cette étape"
            contentsTypes={[elementsTypes.STEPPER_STEP]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {value && value.steps}
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.STEPPER_DETAIL: {
        editorComponent = (
          <DynamicAdmin
            contentKey="detailsElements"
            addContentText="Insérer un élément"
            deleteContentText="Supprimer cet élément"
            contentsTypes={[
              elementsTypes.TEXT,
              elementsTypes.EXPANSION_TEXT,
              elementsTypes.BUTTON,
              elementsTypes.ICON_LINK,
              elementsTypes.EXPANSION_PANEL,
            ]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {value && value.detailsElements}
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.GRID: {
        let { items = [] } = value;
        if (!Array.isArray(items)) {
          items = [items];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="items"
            addContentText="Insérer un élément"
            deleteContentText="Supprimer cet élément"
            contentsTypes={[
              elementsTypes.HIGHLIGHTED_TEXT,
              elementsTypes.TEXT,
              elementsTypes.EXPANSION_TEXT,
              elementsTypes.BUTTON,
              elementsTypes.ICON_LINK,
              elementsTypes.ATTACHMENT,
              elementsTypes.ATTACHMENTS,
              elementsTypes.TIMELINE,
              elementsTypes.IMAGE_BLOCK,
              elementsTypes.VIDEO,
              elementsTypes.GRID,
              elementsTypes.INFO_BLOCK,
              elementsTypes.MAILTO,
              elementsTypes.QUOTE,
            ]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {(appendButtonToNode) =>
              items.length > 0 && getDynamicComponent(type, { ...value, items: items.map(appendButtonToNode) }, id)
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.CARDS_LIST: {
        const { limit, ...others } = value;
        let { cards = [] } = value;
        if (!Array.isArray(cards)) {
          cards = [cards];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="cards"
            addContentText="Insérer une card"
            deleteContentText="Supprimer cette card"
            contentsTypes={[elementsTypes.CARD]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
            limit={limit}
          >
            {(appendButtonToNode) =>
              cards.length > 0 && getDynamicComponent(type, { ...others, cards: cards.map(appendButtonToNode) }, id)
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.CARDS_LIST_NEWS: {
        const { limit, ...others } = value;
        let { cards = [] } = value;
        if (!Array.isArray(cards)) {
          cards = [cards];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="cards"
            addContentText="Insérer une actualité à la une"
            deleteContentText="Supprimer l'actualité à la une"
            contentsTypes={[elementsTypes.CARD_NEWS]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
            limit={limit}
          >
            {(appendButtonToNode) =>
              cards.length > 0 && getDynamicComponent(type, { ...others, cards: cards.map(appendButtonToNode) }, id)
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.OLD_CARDS_LIST_NEWS: {
        const { limit, ...others } = value;
        let { cards = [] } = value;
        if (!Array.isArray(cards)) {
          cards = [cards];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="cards"
            addContentText="Insérer une actualité"
            deleteContentText="Supprimer l'actualité"
            contentsTypes={[elementsTypes.OLD_CARD_NEWS]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
            limit={limit}
          >
            {(appendButtonToNode) =>
              cards.length > 0 && getDynamicComponent(type, { ...others, cards: cards.map(appendButtonToNode) }, id)
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.SUBPAGES_LIST: {
        const { limit } = value;
        let { pages = [] } = value;
        if (!Array.isArray(pages)) {
          pages = [pages];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="pages"
            addContentText="Insérer une page"
            deleteContentText="Supprimer cette page"
            contentsTypes={[elementsTypes.SUBPAGE]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
            limit={limit}
          >
            {(appendButtonToNode) =>
              pages.length > 0 && getDynamicComponent(type, { pages: pages.map(appendButtonToNode) }, id)
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.GLOSSARY: {
        editorComponent = (
          <Fragment>
            <DynamicAdmin
              contentKey="items"
              addContentText="Ajouter un mot / acronyme"
              contentsTypes={[elementsTypes.GLOSSARY_ITEM]}
              onAddContent={handleAddChild}
              onRemoveContent={handleRemoveChild}
              limit={1}
            />
            {getDynamicComponent(type, value, id, index)}
          </Fragment>
        );
        break;
      }
      case elementsTypes.GLOSSARY_ITEM: {
        editorComponent = {
          ...value,
          Wrapper: (p) => {
            const { children } = p;
            return (
              <AdminActions key={id} content={content} onContentChange={handleContentChange}>
                <DynamicAdmin onRemoveContent={handleRemoveContent} limit={0}>
                  {children}
                </DynamicAdmin>
              </AdminActions>
            );
          },
        };
        break;
      }
      case elementsTypes.INFO_BLOCK_LIST: {
        const { limit } = value;
        let { infos = [] } = value;
        if (!Array.isArray(infos)) {
          infos = [infos];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="infos"
            addContentText="Insérer un bloc d'information"
            deleteContentText="Supprimer"
            contentsTypes={[elementsTypes.INFO_BLOCK]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
            limit={limit}
          >
            {(appendButtonToNode) =>
              infos.length > 0 && getDynamicComponent(type, { infos: infos.map(appendButtonToNode) }, id)
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.TAB_CARDS:
      case elementsTypes.TAB: {
        editorComponent = {
          ...value,
          Wrapper: AdminTabWrapper,
          wrapperProps: {
            id,
            content,
            onContentChange: handleContentChange,
            onRemoveContent: handleRemoveContent,
          },
        };
        break;
      }
      case elementsTypes.TABS_CARDS:
      case elementsTypes.TABS: {
        editorComponent = {
          ...value,
          Wrapper: AdminTabsWrapper,
          wrapperProps: {
            id,
            content,
            onContentChange: handleContentChange,
            contentKey: "tabs",
            addContentText: "Ajouter un onglet",
            onAddContent: handleAddChild,
            onRemoveContent: handleRemoveChild,
            contentsTypes: [type === elementsTypes.TABS ? elementsTypes.TAB : elementsTypes.TAB_CARDS],
            limit: 0,
          },
        };
        break;
      }
      case elementsTypes.CONTENTS_GROUPS_LIST: {
        editorComponent = <AdminContentsGroups content={content} onContentChange={handleContentChange} />;
        break;
      }
      case elementsTypes.GEOLOC_SOURCETYPE: {
        editorComponent = <SelectForm content={content} onContentChange={handleContentChange} />;
        break;
      }
      case elementsTypes.SLIDER_INLINE: {
        editorComponent = <SliderForm content={content} onContentChange={handleContentChange} />;
        break;
      }
      case elementsTypes.CONTACT_FORM_PROFILE_LIST:
      case elementsTypes.CONTACT_FORM_SUBJECT_LIST: {
        editorComponent = {
          ...value,
          Wrapper: (p) => {
            const { children } = p;
            return (
              <AdminActions
                key={id}
                content={content}
                onContentChange={handleContentChange}
                modalProps={{ size: "md", disableEnforceFocus: true }}
              >
                {children}
              </AdminActions>
            );
          },
        };
        break;
      }
      case elementsTypes.VIDEOS_LIST: {
        const { limit, ...others } = value;
        let { videos = [] } = value;
        if (!Array.isArray(videos)) {
          videos = [videos];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="videos"
            addContentText="Insérer une vidéo"
            deleteContentText="Supprimer cette vidéo"
            contentsTypes={[elementsTypes.VIDEO]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
            limit={limit}
          >
            {(appendButtonToNode) =>
              videos.length > 0 && getDynamicComponent(type, { ...others, videos: videos.map(appendButtonToNode) }, id)
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.EXPANSION_TEXT: {
        editorComponent = getDynamicComponent(type, { ...value, defaultExpanded: true }, id, index);
        break;
      }
      case elementsTypes.ATTACHMENTS: {
        let { dynamicAttachments = [] } = value;
        if (!Array.isArray(dynamicAttachments)) {
          dynamicAttachments = [dynamicAttachments];
        }
        editorComponent = (
          <DynamicAdmin
            contentKey="dynamicAttachments"
            addContentText="Insérer une pièce jointe"
            deleteContentText="Supprimer la pièce jointe"
            contentsTypes={[elementsTypes.ATTACHMENT_LINK]}
            onAddContent={handleAddChild}
            onRemoveContent={handleRemoveChild}
          >
            {(appendButtonToNode) =>
              dynamicAttachments.length > 0 &&
              getDynamicComponent(
                type,
                { ...value, dynamicAttachments: dynamicAttachments.map(appendButtonToNode) },
                id
              )
            }
          </DynamicAdmin>
        );
        break;
      }
      case elementsTypes.SITE_NAME: {
        editorComponent = <SelectSiteName siteName={value} onSiteNameChange={handleValueChange} />;
        break;
      }
      default:
        editorComponent = getDynamicComponent(type, value, id, index);
    }
    if (editionModal) {
      return (
        <AdminActions key={id} content={content} onContentChange={handleContentChange}>
          {editorComponent}
        </AdminActions>
      );
    }
    return editorComponent;
  }
  return value;
};

const generateTemplatePropsFromContents = (contents, handleContentsChange) => {
  const props = {};
  if (Array.isArray(contents)) {
    contents.forEach((content) => {
      let { value } = content;
      const { key, children, id } = content;

      if (children && children.length) {
        const handleChildrenChange = (newChildren) => {
          handleContentsChange(
            contents.map((oldContent) => (oldContent === content ? { ...content, children: newChildren } : oldContent))
          );
        };

        value = generateTemplatePropsFromContents(children, handleChildrenChange);
        value.id = id;
      }

      const handleContentChange = (updatedContent) => {
        handleContentsChange(
          contents.map((oldContent) => (oldContent === content ? updatedContent : oldContent)).filter((c) => !!c)
        );
      };

      value = getEditorComponent({
        content: enhanceContent(content),
        value,
        handleContentChange,
        index: contents.filter((c) => c.key === key).indexOf(content),
      });

      // if key already exists, then it will be an array
      if (typeof props[key] !== "undefined") {
        if (!Array.isArray(props[key])) {
          props[key] = [props[key]];
        }
        props[key].push(value);
      } else {
        props[key] = value;
      }
    });
  }
  return props;
};

const generateEditablePage = (page, handlePageChange, currentPageVersion) => {
  const { template } = currentPageVersion;

  const { title, description, shortDescription, contents } = page;

  const handleTitleChange = (t) => {
    handlePageChange({
      ...page,
      title: t,
    });
  };
  const handleDescriptionChange = (data) => {
    handlePageChange({
      ...page,
      description: encodeURIComponent(data),
    });
  };
  const handleShortDescriptionChange = (data) => {
    handlePageChange({
      ...page,
      shortDescription: encodeURIComponent(data),
    });
  };
  const handleContentsChange = (c) => {
    handlePageChange({
      ...page,
      contents: c,
    });
  };

  const editableTitle = (
    <AutoResizeInput
      value={title}
      maxSize={template === news.key ? getTitleMaxsize() : null}
      onChange={(v) => handleTitleChange(v)}
    />
  );

  const editableDescription = () => {
    const handleDecode = (v) => {
      try {
        return decodeURIComponent(v);
      } catch (e) {
        return v;
      }
    };
    return (
      <CKEditorCustom
        key={page.id}
        data={handleDecode(description)}
        onChange={handleDescriptionChange}
        type="inline"
        config={{ toolbar: descriptionToolbarEditor }}
      />
    );
  };

  const editableShortDescription = () => {
    const handleDecode = (v) => {
      try {
        return decodeURIComponent(v);
      } catch (e) {
        return v;
      }
    };
    return (
      <CKEditorCustom
        key={page.id}
        data={handleDecode(shortDescription || "")}
        onChange={handleShortDescriptionChange}
        type="inline"
        maxSize={template === news.key ? getShortDescriptionMaxsize() : null}
        config={{ toolbar: descriptionToolbarEditor }}
      />
    );
  };

  const generatedProps = generateTemplatePropsFromContents(contents, handleContentsChange);

  return {
    ...page,
    title: editableTitle,
    description: editableDescription,
    shortDescription: editableShortDescription,
    image: formatPageImage({
      ...page,
      image: page.image || { url: "/default-image.jpg" },
    }),
    contents: generatedProps,
  };
};

export default generateEditablePage;
