import React, { Fragment } from "react";
import PropTypes from "prop-types";
import InputForm from "components/adminComponents/contentForms/InputForm";

const TabForm = (props) => {
  const { content, onContentChange, classes } = props;
  const { label, children } = content;

  const titleChild = children.find((c) => c.key === "title");

  const handleChange = (newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === titleChild ? newChild : c)),
    });
  };

  return (
    <Fragment>
      <h2 className={classes.title}>{label}</h2>
      <InputForm content={titleChild} onContentChange={handleChange} classes={classes} />
    </Fragment>
  );
};

TabForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default TabForm;
