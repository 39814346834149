import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React from "react";
import elementsTypes from "utils/elementsTypes";

const SelectIconForm = (props) => {
  const { content, onContentChange, onIconContentChange = null, child, children, disabled = false } = props;

  const onChange = (c) => {
    if (typeof onIconContentChange === "function") {
      onIconContentChange(c);
    } else {
      onContentChange(c);
    }
  };

  return (
    <>
      <Grid item xs={10} px={1}>
        <SelectForm content={content} onContentChange={onContentChange} disabled={disabled} />
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          textAlign: "center",
          border: "1px solid",
          borderColor: child?.value ? "secondary.main" : "grey",
          bgcolor: child?.value ? "secondary.20" : "transparent",
          opacity: disabled ? 0.4 : 1,
          px: 0.5,
          py: child.type === elementsTypes.SELECT && child.value ? 0 : 1.25,
        }}
      >
        {children}
      </Grid>
      <Grid item xs={1} sx={{ textAlign: "center" }}>
        {child?.value && (
          <IconButton
            disabled={disabled}
            onClick={() =>
              onChange({
                ...child,
                value: "",
              })
            }
            aria-label="Supprimer l'icône"
          >
            <Icon iconDSFR="close-line" sx={{ color: "secondary.main" }} title="Supprimer l'icône" />
          </IconButton>
        )}
      </Grid>
    </>
  );
};

SelectIconForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  onIconContentChange: PropTypes.func,
  child: PropTypes.shape().isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default SelectIconForm;
