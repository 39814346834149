import React, { Fragment, useState, useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import versionStatuses from "utils/versionStatuses";
import AdminContext from "components/adminComponents/AdminContext";
import ModalConfirm from "components/adminComponents/ModalConfirm";
import PagesTable from "components/adminComponents/PagesTable";
import PreventNavigation from "components/adminComponents/PreventNavigation";
import RenameVersionForm from "components/adminComponents/RenameVersionForm";
import SelectVersion from "components/adminComponents/SelectVersion";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import AdminSitesService from "components/adminServices/AdminSitesService";

const formatDate = (date) =>
  new Date(date).toLocaleDateString("FR-fr", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

const DefaultMessage = (props) => {
  const { version, classes } = props;
  const { status } = version || {};
  switch (status) {
    case versionStatuses.DRAFT:
      return (
        <span className={classes.versionLabel}>Brouillon (Dernière mise à jour le {formatDate(version.updated)})</span>
      );
    case versionStatuses.ARCHIVED:
      return <span className={classes.versionLabel}>Version archivée le {formatDate(version.updated)}</span>;
    case versionStatuses.PUBLISHED:
      return (
        <span className={classes.versionLabel} style={{ fontStyle: "normal", color: "green" }}>
          Version publiée le {formatDate(version.updated)}
        </span>
      );
    default:
      return null;
  }
};

DefaultMessage.propTypes = {
  version: PropTypes.shape(),
  classes: PropTypes.shape().isRequired,
};

DefaultMessage.defaultProps = {
  version: null,
};

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  versionLabel: {
    marginTop: "8px",
    fontSize: "0.79rem",
    fontStyle: "italic",
    color: "#666",
  },
  actionBar: {
    height: "80px",
    background: "white",
    borderBottom: "1px solid #ccc",
    zIndex: 1150,
    padding: "0 16px",
  },
}));

const AdminVersions = (props) => {
  const {
    leftActions,
    rightActions,
    versions,
    // refreshVersions,
    currentVersion,
    setCurrentVersion,
    onVersionChange,
    // versionMessage,
    canRename: canRenameProp,
    saveVersion,
    canPublish: canPublishProp,
    canUnpublish: canUnpublishProp,
    updateStatus,
    canDelete: canDeleteProp,
    deleteVersion,
    children,
    exportPage,
    autoSaveDelay,
    manualSave,
    hasRelatedPage,
    associatedPnuPages,
  } = props;

  const { currentSite, setCurrentPage } = useContext(AdminContext);

  const { id, versionName, page: currentPage = {} } = currentVersion;

  const { name: currentPageName, lang: currentPageVersionLang } = currentPage;

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);
  const [versionMessage, setVersionMessage] = useState(null);
  const [versionHasChanged, setVersionHasChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [modal, setModal] = useState({
    content: null,
    size: null,
  });

  useEffect(() => {
    setVersionHasChanged(false);
    setVersionMessage(null);
  }, [id]);

  const openModal = (content, size = null) => {
    setModal({ content, size });
  };

  const closeModal = () =>
    setModal({
      content: null,
      size: null,
    });

  const handleClickRenameVersion = () => {
    openModal(
      <RenameVersionForm
        versionName={versionName}
        onValidate={(name) => {
          saveVersion({ ...currentVersion, versionName: name }).then(() => {
            setCurrentVersion(currentVersion);
            closeModal();
          });
        }}
        onCancel={closeModal}
      />
    );
  };

  const handleClickDeleteVersion = () => {
    openModal(
      <ModalConfirm
        title="Supprimer cette version"
        text="Attention cette action est irréversible"
        confirmText="Supprimer"
        confirmButtonProps={{ style: { backgroundColor: theme.palette.error.main } }}
        onConfirm={() => {
          deleteVersion(currentVersion).then(() => {
            setVersionHasChanged(false);
            setCurrentVersion();
            closeModal();
          });
        }}
        onCancel={closeModal}
      />,
      "md"
    );
  };

  const handleUpdateEnglishVersion = useCallback(() => {
    if (currentVersion?.page?.lang === "FR" && hasRelatedPage) {
      openModal(
        <ModalConfirm
          title={currentPageName}
          text="Souhaitez-vous modifier ou publier la page anglaise rattachée ? "
          confirmText=" Aller sur la page anglaise"
          confirmButtonProps={{ variant: "contained" }}
          onConfirm={() => {
            const pathname = associatedPnuPages?.find((p) => p.page?.lang === "EN")?.fullPath;
            history.push(pathname);
            closeModal();
          }}
          onCancel={closeModal}
          cancelText="Rester sur la page française"
        />,
        "md"
      );
    }
  }, [currentVersion, hasRelatedPage, currentPageName, associatedPnuPages, history]);

  const updateVersionStatus = ({ version, status }) => {
    AdminSitesService.resetSiteCache(currentSite);
    return updateStatus({ version, status }).then(() => {
      setCurrentVersion(version);
      handleUpdateEnglishVersion();
    });
  };

  const handleClickPublish = () => {
    const { status } = currentVersion;
    if (status === versionStatuses.DRAFT) {
      updateVersionStatus({ version: currentVersion, status: versionStatuses.PUBLISHED });
    } else {
      saveVersion(currentVersion).then((newVersion) =>
        updateVersionStatus({ version: newVersion, status: versionStatuses.PUBLISHED })
      );
    }
  };

  const handleClickUnpublish = () => {
    openModal(
      <ModalConfirm
        title={`Dépublier "${currentPageName}"`}
        text={
          <Box>
            {hasRelatedPage && (
              <Box sx={{ color: "red", paddingBottom: theme.spacing(1) }}>
                Attention, une page {currentPageVersionLang === "EN" ? "française" : "anglaise"} est rattachée à cette
                page. Veillez à la dépublier également
              </Box>
            )}
            <Box>
              Attention, si vous dépubliez cette page, les sous-pages liées à celle-ci seront également dépubliées.
            </Box>
          </Box>
        }
        detailContent={
          <PagesTable
            onPageClick={(event, page) => {
              setCurrentPage(page);
              closeModal();
            }}
            pageId={currentVersion?.pageId}
            title={`Liste des sous-pages de "${currentPageName}"`}
          />
        }
        confirmText="Dépublier"
        confirmButtonProps={{ style: { backgroundColor: theme.palette.error.main } }}
        onConfirm={() => {
          updateVersionStatus({ version: currentVersion, status: versionStatuses.ARCHIVED });
          closeModal();
        }}
        onCancel={closeModal}
      />,
      "md"
    );
  };

  const handleSaveVersion = useCallback(
    (version, refresh = true) => {
      if (!isSaving) {
        if (version.status === versionStatuses.DRAFT) {
          setVersionMessage("Enregistrement...");
        } else {
          setVersionMessage("Création d'un nouveau brouillon...");
        }
        setAutoSaveTimeout(null);
        setIsSaving(true);
        saveVersion(version).then((v) => {
          if (refresh) {
            setCurrentVersion(v).then(() => {
              setIsSaving(false);
              setVersionHasChanged(false);
              setVersionMessage(null);
              handleUpdateEnglishVersion();
            });
          } else {
            setIsSaving(false);
            setVersionHasChanged(false);
            setVersionMessage(null);
          }
        });
      }
    },
    [isSaving, saveVersion, setCurrentVersion, handleUpdateEnglishVersion]
  );

  const handleVersionChange = useCallback(
    (version) => {
      if (version.id === id) {
        setVersionMessage("Modifications en attente");
        setVersionHasChanged(true);
        onVersionChange(version);
        if (autoSaveDelay > 0 || !manualSave) {
          if (autoSaveTimeout) {
            clearTimeout(autoSaveTimeout);
          }
          if (autoSaveDelay && autoSaveDelay > 0) {
            const newTimeout = setTimeout(() => {
              handleSaveVersion(version);
            }, autoSaveDelay);
            setAutoSaveTimeout(newTimeout);
          }
        }
      }
    },
    [id, onVersionChange, autoSaveTimeout, autoSaveDelay, handleSaveVersion, manualSave]
  );

  const handleShowPrompt = () => {
    clearTimeout(autoSaveTimeout);
  };

  const handleClickNavigate = () => {
    setVersionHasChanged(false);
    setVersionMessage(null);
  };

  const { status } = currentVersion || {};

  const isDraft = status === versionStatuses.DRAFT;
  const isPublished = status === versionStatuses.PUBLISHED;

  const canRename = canRenameProp && isDraft;
  const canPublish = canPublishProp && typeof updateStatus === "function" && !versionHasChanged && !isPublished;
  const canUnpublish = canUnpublishProp && typeof updateStatus === "function" && isPublished;
  const canDelete = canDeleteProp && typeof deleteVersion === "function" && isDraft && versions.length > 1;

  return (
    <Fragment>
      <PreventNavigation
        blocked={versionHasChanged && !isSaving}
        onSave={() => handleSaveVersion(currentVersion, false)}
        onShowPrompt={handleShowPrompt}
        onNavigate={handleClickNavigate}
      />
      <Modal open={!!modal.content} size={modal.size} onClose={closeModal}>
        {modal.content}
      </Modal>
      <div className={classes.actionBar}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.root}>
          <Grid item>{leftActions}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <SelectVersion
                      versions={versions}
                      selectedVersion={currentVersion}
                      onSelectVersion={setCurrentVersion}
                    />
                  </Grid>
                  {canRename && (
                    <Grid item>
                      <Icon
                        icon="edit"
                        type="fas"
                        iconDSFR="edit-box-line"
                        title="Renommer cette version"
                        onClick={handleClickRenameVersion}
                        style={{
                          fontSize: "0.83rem",
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                      />
                    </Grid>
                  )}
                  {canDelete && (
                    <Grid item>
                      <Icon
                        icon="trash"
                        type="fas"
                        iconDSFR="delete-line"
                        title="Supprimer cette version"
                        onClick={handleClickDeleteVersion}
                        style={{
                          fontSize: "0.83rem",
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                {versionMessage ? (
                  <span className={classes.versionLabel}>{versionMessage}</span>
                ) : (
                  <DefaultMessage version={currentVersion} classes={classes} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <IconButton
                  onClick={exportPage}
                  color="secondary"
                  style={{ fontSize: "1rem" }}
                  aria-label="Exporter la version courante de cette page"
                >
                  <Icon icon="download" iconDSFR="download-line" title="Exporter la version courante de cette page" />
                </IconButton>
              </Grid>
              <Grid item>{rightActions}</Grid>
              {manualSave && (
                <Grid item>
                  <Button onClick={() => handleSaveVersion(currentVersion)} disabled={!versionHasChanged || isSaving}>
                    Enregistrer
                  </Button>
                </Grid>
              )}
              <Grid item>
                {!canUnpublish && (
                  <Button onClick={handleClickPublish} disabled={!canPublish || versionHasChanged}>
                    Publier cette version
                  </Button>
                )}
                {canUnpublish && (
                  <Button
                    style={{
                      backgroundColor: theme.palette.warning.light,
                      color: theme.palette.getContrastText(theme.palette.warning.light),
                    }}
                    onClick={handleClickUnpublish}
                  >
                    Dépublier cette version
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {children({ handleVersionChange })}
    </Fragment>
  );
};

const versionShape = {
  status: PropTypes.oneOf(Object.values(versionStatuses)).isRequired,
  updated: PropTypes.string.isRequired,
  versionName: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape()),
};

AdminVersions.propTypes = {
  leftActions: PropTypes.node,
  rightActions: PropTypes.node,
  versions: PropTypes.arrayOf(PropTypes.shape(versionShape)).isRequired,
  currentVersion: PropTypes.shape(versionShape).isRequired,
  setCurrentVersion: PropTypes.func.isRequired,
  onVersionChange: PropTypes.func.isRequired,
  saveVersion: PropTypes.func.isRequired,
  canRename: PropTypes.bool,
  canPublish: PropTypes.bool,
  canUnpublish: PropTypes.bool,
  updateStatus: PropTypes.func,
  canDelete: PropTypes.bool,
  deleteVersion: PropTypes.func,
  children: PropTypes.func.isRequired,
  exportPage: PropTypes.func,
  autoSaveDelay: PropTypes.number,
  manualSave: PropTypes.bool,
  hasRelatedPage: PropTypes.bool,
  associatedPnuPages: PropTypes.arrayOf(PropTypes.shape()),
};
AdminVersions.defaultProps = {
  leftActions: null,
  rightActions: null,
  canRename: true,
  canPublish: true,
  canUnpublish: true,
  updateStatus: null,
  canDelete: true,
  deleteVersion: null,
  exportPage: null,
  autoSaveDelay: 2000,
  manualSave: false,
  hasRelatedPage: false,
  associatedPnuPages: [],
};

export default AdminVersions;
