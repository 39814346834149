import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";
import elementsTypes from "utils/elementsTypes";
import AdminListForm from "components/adminComponents/contentForms/AdminListForm";
import AdminContentModal, { canBeAdministrated } from "components/adminComponents/AdminContentModal";
import SelectContentModal from "components/adminComponents/SelectContentModal";
import Icon from "components/templatesComponents/Icon";
import { generateContentId, getDefaultContentByType, enhanceContent } from "utils/adminContentsUtils";

const useStyles = makeStyles((theme) => ({
  root: {},
  menuItemLabel: {
    cursor: "pointer",
    height: "40px",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
}));

const defaultMenuItemModal = {
  open: false,
  menuItem: null,
  onValidate: () => {},
};

const defaultSelectContentModal = {
  open: false,
  depth: 0,
  onValidate: () => {},
};

const AdminMenuItems = (props) => {
  const { items, itemKey, subItemKey, onItemsChange, maxDepth } = props;

  const classes = useStyles();

  const [modalSelectContent, setModalSelectContent] = useState(defaultSelectContentModal);
  const { open: selectContentModalOpen, depth: currentDepth, onValidate: onValidateAddContent } = modalSelectContent;

  const [menuItemModal, setMenuItemModal] = useState(defaultMenuItemModal);
  const { open: itemModalOpen, onValidate: onValidateItem, menuItem: currentMenuItem } = menuItemModal;

  const closeSelectContentModal = useCallback(() => setModalSelectContent(defaultSelectContentModal), []);
  const closeMenuItemModal = useCallback(() => setMenuItemModal(defaultMenuItemModal), []);

  const [groupOpen, setGroupOpen] = useState(null);

  const toggleGroupOpen = useCallback(
    (group) => () => {
      if (group === groupOpen) {
        setGroupOpen(null);
      } else {
        setGroupOpen(group);
      }
    },
    [groupOpen]
  );

  const handleItemChange = (item) => {
    const { id } = item;
    onItemsChange(items.map((c) => (c.id !== id ? c : item)));
  };

  const getAvailableContents = (depth = currentDepth) => {
    const availableContents = [getDefaultContentByType(elementsTypes.MENU_ITEM)];
    if (depth < maxDepth) {
      availableContents.push(getDefaultContentByType(elementsTypes.MENU_GROUP));
    }
    return availableContents;
  };

  const handleSelectContent = (content, onValidate = onValidateAddContent) => {
    if (canBeAdministrated(content)) {
      setMenuItemModal({
        open: true,
        menuItem: enhanceContent(content),
        onValidate: (c) => {
          onValidate(c);
          closeMenuItemModal();
        },
      });
    } else {
      onValidate(content);
    }
    closeSelectContentModal();
  };

  const handleClickAddContent = (onValidate, depth = 0) => {
    const availableContents = getAvailableContents(depth);
    if (availableContents.length > 1) {
      setModalSelectContent({
        open: true,
        depth,
        onValidate,
      });
    } else if (availableContents.length === 1) {
      handleSelectContent(availableContents[0], onValidate);
    }
  };

  const handleClickEditMenuItem = (menuItem, onValidate) => (e) => {
    e.stopPropagation();
    setMenuItemModal({
      open: true,
      menuItem: enhanceContent(menuItem),
      onValidate: (c) => {
        onValidate(c);
        closeMenuItemModal();
      },
    });
  };

  const renderMenuItem = (menuItem, onItemChange, depth) => {
    const { id, children, type } = menuItem;
    const titleChild = children.find((c) => c.key === "title");
    const subMenusChild = children.filter((c) => c.key === subItemKey);

    const isGroup = type === elementsTypes.MENU_GROUP;
    const isOpen = menuItem.id === (groupOpen && groupOpen.id);

    const handleItemChildrenChange = (newChildren) => {
      onItemChange({
        ...menuItem,
        children: newChildren,
      });
    };

    const handleSubMenuChange = (updatedSubMenu) => {
      onItemChange({
        ...menuItem,
        children: children.map((child) => (child.id === updatedSubMenu.id ? updatedSubMenu : child)),
      });
    };

    const addSubMenuItem = (subMenuItem) => {
      onItemChange({
        ...menuItem,
        children: [...children, generateContentId({ key: subItemKey, ...subMenuItem })],
      });
    };

    return (
      <div key={id}>
        <div
          className={classes.menuItemLabel}
          onClick={isGroup ? toggleGroupOpen(menuItem) : handleClickEditMenuItem(menuItem, onItemChange)}
        >
          <Icon
            icon="edit"
            type="fas"
            iconDSFR="edit-box-line"
            onClick={handleClickEditMenuItem(menuItem, onItemChange)}
          />
          <Box component="span" px={1}>
            {titleChild && titleChild.value}
          </Box>
          {isGroup && <Icon icon="plus" iconDSFR="add-line" title="Voir plus" />}
        </div>
        {isGroup && isOpen && (
          <Box ml={6} border={1} p={1}>
            <AdminListForm inline contents={children} onContentsChange={handleItemChildrenChange}>
              {subMenusChild.map((item) => renderMenuItem(item, handleSubMenuChange, depth + 1))}
            </AdminListForm>
            <Box ml={4}>
              <MuiLink
                component="button"
                variant="body2"
                onClick={() => handleClickAddContent(addSubMenuItem, depth + 1)}
              >
                Ajouter un élément
              </MuiLink>
            </Box>
          </Box>
        )}
      </div>
    );
  };

  const addMenuItem = (menuItem) => {
    onItemsChange([...items, generateContentId({ key: itemKey, ...menuItem })]);
  };

  return (
    <div className={classes.root}>
      <AdminListForm inline contents={items} onContentsChange={onItemsChange}>
        {items.map((menuItem) => renderMenuItem(menuItem, handleItemChange, 0))}
      </AdminListForm>
      <Box ml={4}>
        <MuiLink component="button" variant="body2" onClick={() => handleClickAddContent(addMenuItem, 0)}>
          Ajouter un élément
        </MuiLink>
      </Box>
      <SelectContentModal
        open={selectContentModalOpen}
        contents={getAvailableContents()}
        onSelectContent={handleSelectContent}
        onClose={closeSelectContentModal}
      />
      <AdminContentModal
        open={itemModalOpen}
        content={currentMenuItem}
        onValidate={onValidateItem}
        onClose={closeMenuItemModal}
      />
    </div>
  );
};

AdminMenuItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onItemsChange: PropTypes.func.isRequired,
  itemKey: PropTypes.string.isRequired,
  subItemKey: PropTypes.string,
  maxDepth: PropTypes.number,
};

AdminMenuItems.defaultProps = {
  maxDepth: 0,
  subItemKey: "subMenus",
};

export default AdminMenuItems;
