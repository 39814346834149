import { ThemeProvider } from "@mui/material/styles";
import ContentForm from "components/adminComponents/contentForms/ContentForm";
import Button from "components/templatesComponents/Button";
import Modal, { ModalActions } from "components/templatesComponents/Modal";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";
import { StaticRouter } from "react-router";
import { enhanceContent } from "utils/adminContentsUtils";
import themes from "../../apps/front-office/client/themes/themes";

const theme = themes.ANTS;

export const canBeAdministrated = (content) => {
  const div = document.createElement("div");
  flushSync(() => {
    createRoot(div).render(
      <ThemeProvider theme={theme}>
        <StaticRouter location={window.location}>
          <ContentForm content={content} topLevel />
        </StaticRouter>
      </ThemeProvider>
    );
  });
  const hasContentForm = !!div.children.length;
  div.remove();
  return hasContentForm;
};

const AdminContentModal = (props) => {
  const { open = false, content = null, onValidate, onClose, ...others } = props;
  const [fullContent, setFullContent] = useState(content ? enhanceContent(content) : {});

  useEffect(() => {
    if (open) {
      setFullContent(enhanceContent(content));
    }
  }, [open, content]);

  const handleClickValidate = () => {
    onValidate(fullContent);
  };

  return (
    <Modal
      aria-labelledby="admin-content"
      aria-describedby="content-administration"
      open={open}
      onClose={onClose}
      size="md"
      {...others}
    >
      <ContentForm topLevel content={fullContent} onContentChange={setFullContent} />
      <ModalActions>
        <Button onClick={onClose} outlined>
          Annuler
        </Button>
        <Button onClick={handleClickValidate}>Valider</Button>
      </ModalActions>
    </Modal>
  );
};

AdminContentModal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.shape(),
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AdminContentModal;
