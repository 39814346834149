import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CKEditor } from "ckeditor4-react";
import AdminListForm from "components/adminComponents/contentForms/AdminListForm";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import InputForm from "components/adminComponents/contentForms/InputForm";
import ExpansionPanel from "components/templatesComponents/ExpansionPanel";
import PropTypes from "prop-types";
import React from "react";
import { fullToolbarEditor } from "utils/commonUtils";
import elementsTypes from "utils/elementsTypes";

const ContactFormSubjectListForm = (props) => {
  const { content, onContentChange } = props;
  const { id, label, children = [] } = content;

  const objectsChildren = children.filter((c) => c.type === elementsTypes.CONTACT_FORM_SUBJECT) || [];

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: newChildren,
    });
  };

  return (
    <div key={id}>
      <Box component="h2" sx={formStyled.title}>
        {label}
      </Box>

      <Box sx={formStyled.fieldset}>
        <h4>Objets</h4>

        <AdminListForm
          inline
          contents={objectsChildren}
          elementsType={elementsTypes.CONTACT_FORM_SUBJECT}
          elementsKey="ContactFormSubjects"
          onContentsChange={(newContents) => handleChildrenChange(objectsChildren, newContents)}
        >
          {objectsChildren.map((currentObject) => (
            <ExpansionPanel
              key={currentObject.id}
              summary={(currentObject.children.find((c) => c.key === "title") || {}).value}
              details={
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputForm
                        content={{
                          ...currentObject.children.find((c) => c.key === "title"),
                          label: "Objet",
                        }}
                        info={
                          <Box component="p" sx={{ color: "#fff" }}>
                            Si ce champ n&apos;est pas rempli, cet objet n&apos;apparaîtra pas dans la liste
                          </Box>
                        }
                        onContentChange={(newContent) =>
                          handleChildChange(currentObject, {
                            ...currentObject,
                            children: (currentObject.children || []).map((c) => (c.key === "title" ? newContent : c)),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                  <InputForm
                    content={{
                      ...currentObject.children.find((c) => c.key === "recipient"),
                      label: "E-mail de destination",
                    }}
                    info={
                      <Box component="p" sx={{ color: "#fff" }}>
                        En cas d&apos;absence de réponse automatique, si ce champ n&apos;est pas rempli, cet objet
                        n&apos;apparaîtra pas dans la liste
                      </Box>
                    }
                    onContentChange={(newContent) =>
                      handleChildChange(currentObject, {
                        ...currentObject,
                        children: (currentObject.children || []).map((c) => (c.key === "recipient" ? newContent : c)),
                      })
                    }
                  />

                  <CheckboxForm
                    content={{
                      ...(currentObject.children || []).find((c) => c.key === "autoReply"),
                      label: "Activer la réponse automatique",
                    }}
                    onContentChange={(newContent) =>
                      handleChildChange(currentObject, {
                        ...currentObject,
                        children: (currentObject.children || []).map((c) => (c.key === "autoReply" ? newContent : c)),
                      })
                    }
                  />
                  {((currentObject.children || []).find((c) => c.key === "autoReply") || {}).value === "true" && (
                    <CKEditor
                      onBeforeLoad={(CKEDITOR) => {
                        // eslint-disable-next-line no-param-reassign
                        CKEDITOR.disableAutoInline = true;
                      }}
                      key={currentObject.id}
                      onChange={(e) =>
                        handleChildChange(currentObject, {
                          ...currentObject,
                          children: (currentObject.children || []).map((c) =>
                            c.key === "replyMessage" ? { ...c, value: encodeURIComponent(e.editor.getData()) } : c
                          ),
                        })
                      }
                      initData={decodeURIComponent(
                        ((currentObject.children || []).find((c) => c.key === "replyMessage") || {}).value
                      )}
                      editorUrl="/ckeditor/ckeditor.js"
                      config={{ toolbar: fullToolbarEditor }}
                    />
                  )}
                </>
              }
            />
          ))}
        </AdminListForm>
      </Box>
    </div>
  );
};

ContactFormSubjectListForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default ContactFormSubjectListForm;
