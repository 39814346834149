import elementsTypes from "utils/elementsTypes";

export const decodeContents = (contents) => {
  if (Array.isArray(contents)) {
    contents.forEach((content) => {
      if ([elementsTypes.TEXT, elementsTypes.SHORT_TEXT].includes(content.type)) {
        // eslint-disable-next-line no-param-reassign
        content.value = decodeURIComponent(content.value || "");
      }
      if (content.value?.shortDescription) {
        // eslint-disable-next-line no-param-reassign
        content.value.shortDescription = decodeURIComponent(content.value?.shortDescription);
      }
      if ([elementsTypes.PAGE, elementsTypes.SUBPAGE].includes(content.type) && typeof content.value === "object") {
        const { title, description, shortDescription, path, fullPath, pageId, image } = content.value;
        // eslint-disable-next-line no-param-reassign
        content.value = { title, description, shortDescription, contents: [], path, fullPath, pageId, image };
      }
      if (content.children?.length) {
        decodeContents(content.children);
      }
    });
  }
  return contents;
};

const dateNow = new Date(Date.now()).toLocaleDateString().split("/") || [];
export const exportDate = `${dateNow[2]}${dateNow[1]}${dateNow[0]}` || "";

export const downloadFile = (page, siteName) => {
  const file = new Blob([JSON.stringify(page, null, 2)], {
    type: "application/json",
  });
  const fileURL = URL.createObjectURL(file);
  const element = document.createElement("a");
  element.setAttribute("href", fileURL);
  element.setAttribute(
    "download",
    `${process.env.environment_suffix} - ${siteName} - ${page?.title} - ${exportDate}` || "export"
  );
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
