import { ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import keycloak from "apps/back-office/client/keycloak";
import themes from "apps/front-office/client/themes/themes";
import axios from "axios";
import AdminBanners from "components/adminComponents/AdminBanners";
import AdminContentModal from "components/adminComponents/AdminContentModal";
import AdminContext from "components/adminComponents/AdminContext";
import AdminFooterForm from "components/adminComponents/AdminFooterForm";
import AdminGeoPoints from "components/adminComponents/AdminGeoPoints";
import AdminHeaderForm from "components/adminComponents/AdminHeaderForm";
import AdminMenuForm from "components/adminComponents/AdminMenuForm";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import AdminPageForm from "components/adminComponents/AdminPageForm";
import AdminSection from "components/adminComponents/AdminSection";
import AdminVersions from "components/adminComponents/AdminVersions";
import GEDModal from "components/adminComponents/GEDModal";
import ModalConfirm from "components/adminComponents/ModalConfirm";
import PagesTable from "components/adminComponents/PagesTable";
import AdminPagesService from "components/adminServices/AdminPagesService";
import AdminPageVersionsService from "components/adminServices/AdminPageVersionsService";
import AdminSitesService from "components/adminServices/AdminSitesService";
import ForceOnboardingContext from "components/ForceOnboardingContext";
import LanguageContext from "components/LanguageContext";
import RenderPage from "components/LayoutBack/RenderPage";
import SideBar, { SideBarDivider, SideBarElement, SideBarGroup } from "components/LayoutBack/SideBar";
import Snackbar from "components/Snackbar";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import LinkComponent from "components/templatesComponents/Link";
import Modal, { ModalActions } from "components/templatesComponents/Modal";
import useAxiosCache, { resetCache } from "hooks/axiosCache";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PageService from "services/PageService";
import { generateContentId, getDefaultContentByType } from "utils/adminContentsUtils";
import { decodeContents, downloadFile, exportDate } from "utils/contentsUtils";
import elementsTypes from "utils/elementsTypes";
import geolocSourceType from "utils/geolocSourceType";
import sectionsTypes from "utils/sectionsTypes";
import { getQueryParams, stringToPath } from "utils/urlUtils";
import versionStatuses from "utils/versionStatuses";
import { v4 as uuidv4 } from "uuid";

const modalWarnStyled = {
  color: "#f44336",
  fontWeight: 600,
};

const LayoutBack = () => {
  const [pageVersion, setPageVersion] = useState();
  const [editionMode, setEditionMode] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [adminModal, setAdminModal] = useState({
    content: null,
    size: null,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
  });

  const [isAdmin, setIsAdmin] = useState(false);

  const [forceOnboarding, setForceOnboarding] = useState(false);

  const [importPageContents, setImportPageContents] = useState({});

  const [openExportMediaModal, setOpenExportMediaModal] = useState(false);
  const [mediaIds, setMediaIds] = useState("");
  const [fileName, setFileName] = useState("");

  const [importId] = useState(`uploader-${Math.floor(Math.random() * 100000)}`);
  const [uploaderId] = useState(`uploader-${Math.floor(Math.random() * 100000)}`);

  useEffect(() => {
    axios.get("/isAdmin").then((response) => {
      setIsAdmin(response.data);
    });
  }, []);

  const {
    currentSite,
    setCurrentSite,
    refreshSite,
    allSites,
    setCurrentPage,
    currentPageVersion,
    setCurrentPageVersion: setCurrentPageVersionContext,
    pageVersions,
    refreshPageVersions,
    goToHomePage,
  } = useContext(AdminContext);

  const { setLanguage } = useContext(LanguageContext);
  const queryParams = getQueryParams();
  const { page: queryParamPage, version: queryParamVersion } = queryParams;

  const { page: currentPage = {}, pageId } = currentPageVersion || {};
  const { name: currentPageName, lang: currentPageVersionLang, originalPageId: currentOriginalPageId } = currentPage;

  const { id: siteId, name: siteName, contents: siteContents = [] } = currentSite || {};

  const theme = themes[siteName] || themes.ANTS;

  useEffect(() => {
    setPageVersion(currentPageVersion);
  }, [currentPageVersion]);

  useEffect(() => {
    if (queryParamPage || queryParamVersion) {
      setLanguage(currentPageVersionLang);
    }
  }, [currentPageVersion, currentPageVersionLang, queryParamPage, queryParamVersion, setLanguage]);

  const openAdminModal = (content, size = null) => {
    setAdminModal({ content, size });
  };

  const closeAdminModal = useCallback(() => {
    setAdminModal({
      content: null,
      size: null,
    });
    if (Object.keys(importPageContents).length) {
      setImportPageContents({});
    }
  }, [importPageContents]);

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const displayError = useCallback((message) => {
    setSnackbar({
      open: true,
      duration: null,
      variant: "error",
      message,
    });
  }, []);

  const displaySuccess = useCallback((message) => {
    setSnackbar({
      open: true,
      duration: 5000,
      message,
    });
  }, []);

  const adminMessageContextValue = useMemo(() => ({ displaySuccess, displayError }), [displaySuccess, displayError]);

  const setCurrentPageVersion = (version = {}) =>
    refreshPageVersions().then((versions) => {
      setCurrentPageVersionContext(versions.find((v) => v.id === version.id));
      // setPageVersionMessage(null);
    });

  const [{ data: associatedPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: currentPageVersionLang === "FR" ? pageId : currentOriginalPageId,
    })
  );

  const hasRelatedPage = useMemo(
    () =>
      currentPageVersionLang === "EN"
        ? associatedPnuPages.some((m) => m.page?.lang === "FR")
        : associatedPnuPages.some((m) => m.page?.lang === "EN"),
    [currentPageVersionLang, associatedPnuPages]
  );

  const handleSaveVersion = (p) => {
    const promises = [];
    promises.push(AdminPagesService.update(p.page));
    if (p.status === versionStatuses.DRAFT) {
      promises.push(AdminPageVersionsService.update(p));
      return Promise.all(promises)
        .then((responses) => {
          const [, version] = responses;
          return version;
        })
        .catch((error) => {
          setCurrentPageVersion(p);
          if (error.response && error.response.status === 409 && error.response.statusText === "Conflict") {
            displayError("Erreur de conflit, enregistrement déjà effectué.");
          } else {
            displayError("Erreur lors de la mise à jour du brouillon");
          }
        });
    }
    promises.push(AdminPageVersionsService.create(p));
    return Promise.all(promises)
      .then((responses) => {
        const [, version] = responses;
        return version;
      })
      .catch((error) => {
        setCurrentPageVersion(currentPageVersion);
        if (error.response && error.response.status === 409 && error.response.statusText === "Conflict") {
          displayError("Erreur de conflit, enregistrement déjà effectué.");
        } else {
          displayError("Erreur lors de la mise à jour du brouillon");
        }
      });
  };

  const updateVersionStatus = ({ version, status }) =>
    AdminPageVersionsService.updateStatus(version, status).catch((error) => {
      if (error.response && error.response.status === 502 && error.response.statusText === "Bad Gateway") {
        displayError("Erreur d'indexation, mise à jour du statut de la version impossible.");
      } else {
        displayError("Erreur lors de la mise à jour du statut de la version");
      }
    });

  const handleClickMenu = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleClickAdminPage = () => {
    openAdminModal(
      <AdminPageForm
        version={pageVersion}
        onValidate={(p) => {
          handleSaveVersion(p).then(setCurrentPageVersion);
          closeAdminModal();
        }}
        onCancel={closeAdminModal}
      />
    );
  };

  const createPage = useCallback(
    async (version) => {
      const { page } = version;
      const { name, parentId, lang, originalPageId } = page;

      const newPage = {
        name,
        parentId,
        siteId,
        lang,
        originalPageId,
      };

      let createdPage = null;
      try {
        createdPage = await AdminPagesService.create(newPage);

        const newPageVersion = {
          ...version,
          page: createdPage,
        };

        try {
          await AdminPageVersionsService.create(newPageVersion);

          closeAdminModal();
        } catch (e) {
          // Rollback page
          AdminPagesService.delete(createdPage);
          throw e;
        }
      } catch (e) {
        createdPage = null;
        console.error(e);
      }
      if (createdPage) {
        setCurrentPage(createdPage);
        if (lang === "EN") {
          window.location.reload();
        }
      }
    },
    [closeAdminModal, siteId, setCurrentPage]
  );

  const handleClickAddPage = () => {
    openAdminModal(<AdminPageForm onValidate={createPage} onCancel={closeAdminModal} />);
  };

  const handleUploadFile = (e) => {
    const fileToUpload = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const { originalPageId, ...form } = JSON.parse(reader.result) || {};
      setImportPageContents({ ...form, path: `${stringToPath(form.title)}`, page: { originalPageId } });
    });
    reader.readAsText(fileToUpload);
  };

  useEffect(() => {
    if (Object.keys(importPageContents).length) {
      openAdminModal(
        <AdminPageForm
          version={importPageContents}
          onValidate={createPage}
          onCancel={() => {
            closeAdminModal();
          }}
          isImport
        />
      );
    }
  }, [closeAdminModal, createPage, importPageContents]);

  const importPage = () => {
    document.getElementById(importId).click();
  };

  const exportPage = () => {
    const {
      title,
      description,
      shortDescription,
      template,
      contents,
      tags,
      image,
      pageId: pageVersionId,
    } = pageVersion;
    const siteNameById = allSites.find((site) => site.id === siteId)?.name || "";
    const pageVersionToExport = {
      title,
      description,
      shortDescription: decodeURIComponent(shortDescription),
      template,
      contents: decodeContents(contents),
      tags,
      image,
      originalPageId: pageVersionId,
    };
    downloadFile(pageVersionToExport, siteNameById);
  };

  const handleLogout = () => {
    keycloak.logout();
  };

  const handleRedirectManagement = () => {
    keycloak.accountManagement();
  };

  const handleClickPages = () => {
    openAdminModal(
      <PagesTable
        onPageClick={(event, page) => {
          setCurrentPage(page);
          closeAdminModal();
        }}
        title="Liste des pages"
        canExport
      />,
      "lg"
    );
  };

  const handleDeletePageVersion = (version) => AdminPageVersionsService.delete(version);

  const handleDeletePage = () => {
    openAdminModal(
      <ModalConfirm
        title={`Supprimer "${currentPageName}"`}
        text={
          <Box pb={2}>
            {hasRelatedPage && (
              <Box sx={{ color: "red", pb: 1 }}>
                Attention, une page {currentPageVersionLang === "EN" ? "française" : "anglaise"} est rattachée à cette
                page. Veillez à la supprimer également
              </Box>
            )}
            Attention cette action est irréversible et entraîne :
            <br />
            <ul>
              <li>
                La suppression de{" "}
                <Box component="span" sx={modalWarnStyled}>
                  cet article ainsi que de toutes ses versions
                </Box>
              </li>
              <li>
                La suppression de{" "}
                <Box component="span" sx={modalWarnStyled}>
                  tous les sous-articles
                </Box>{" "}
                liés à cet article
              </li>
            </ul>
          </Box>
        }
        detailContent={
          <PagesTable
            onPageClick={(event, page) => {
              setCurrentPage(page);
              closeAdminModal();
            }}
            pageId={currentPageVersion?.pageId}
            title={`Liste des sous-pages de "${currentPageName}"`}
          />
        }
        confirmText="Supprimer"
        confirmButtonProps={{ style: { backgroundColor: theme.palette.error.main } }}
        onConfirm={() => {
          AdminPagesService.delete(currentPage).then(() => {
            goToHomePage();
          });
          closeAdminModal();
        }}
        onCancel={closeAdminModal}
      />,
      "md"
    );
  };

  const handleClickMedias = () => {
    openAdminModal(<GEDModal onCancel={closeAdminModal} />);
  };

  const updateSite = (site, message = "Le site a été mis à jour.") => {
    AdminSitesService.update(site)
      .then(() => {
        refreshSite();
        closeAdminModal();
        setSnackbar({
          open: true,
          message,
        });
      })
      .catch(() => {
        closeAdminModal();
        displayError("Une erreur est survenue.");
      });
  };

  const [key, setKey] = useState(uuidv4());
  const refreshPage = () => {
    resetCache();
    setKey(uuidv4());
  };

  const handleClickAdminHeader = () => {
    openAdminModal(
      <AdminSection title="Gestion du header" type={sectionsTypes.HEADER} onSectionChanged={refreshPage}>
        {({ section, onSectionChange }) => <AdminHeaderForm header={section} onHeaderChange={onSectionChange} />}
      </AdminSection>,
      "lg"
    );
  };

  const handleClickAdminMenu = () => {
    openAdminModal(
      <AdminSection title="Gestion du menu" type={sectionsTypes.MENU} onSectionChanged={refreshPage}>
        {({ section, onSectionChange }) => <AdminMenuForm menu={section} onMenuChange={onSectionChange} />}
      </AdminSection>,
      "lg"
    );
  };

  const handleClickAdminFooter = () => {
    openAdminModal(
      <AdminSection title="Gestion du footer" type={sectionsTypes.FOOTER} onSectionChanged={refreshPage}>
        {({ section, onSectionChange }) => <AdminFooterForm footer={section} onFooterChange={onSectionChange} />}
      </AdminSection>,
      "xl"
    );
  };

  const handleSiteSettingsChange = (settings) => {
    openAdminModal(
      <ModalConfirm
        title={`Paramétres du site : ${siteName}`}
        text="Attention, cette action sera effective immédiatement sur le site"
        confirmText="Valider"
        onConfirm={() => {
          const { children } = settings || {};
          const titleChild = children.find((c) => c.key === "title") || {};
          const newSettings = { ...settings, children: settings.children.filter((c) => c.key !== "title") };
          updateSite({
            id: siteId,
            title: titleChild.value,
            contents: siteContents.map((c) => (c.key === "settings" ? newSettings : c)),
          });
        }}
        onCancel={() => {
          closeAdminModal();
          // eslint-disable-next-line no-use-before-define
          handleClickSiteSettings(settings);
        }}
      />,
      "md"
    );
  };

  const handleOnboardingChange = (onboarding) => {
    openAdminModal(
      <ModalConfirm
        title={`Calque didactique du site : ${siteName}`}
        text="Attention, cette action sera effective immédiatement sur le site"
        confirmText="Valider"
        onConfirm={() => {
          let { contents } = currentSite;
          const onboardingChild = contents.find((c) => c.key === "onboarding");
          if (!onboardingChild) {
            contents.push(onboarding);
          } else {
            contents = contents.map((c) => (c.key === "onboarding" ? onboarding : c));
          }
          updateSite(
            {
              id: siteId,
              contents,
            },
            "Le calque a été mis à jour"
          );
        }}
        onCancel={() => {
          closeAdminModal();
          // eslint-disable-next-line no-use-before-define
          handleClickAdminOnboarding(onboarding);
        }}
      />,
      "md"
    );
  };

  const handleClickSiteSettings = (settings) => {
    const { title, contents = [] } = currentSite;
    const siteSettings = settings || (contents && contents.find((c) => c.key === "settings")) || {};
    openAdminModal(
      <AdminContentModal
        open
        content={{
          ...siteSettings,
          children: [...(siteSettings.children || []), { key: "title", value: title }],
          label: `Paramétres du site : ${siteName}`,
        }}
        onClose={closeAdminModal}
        onValidate={(newSettings) => {
          handleSiteSettingsChange(newSettings);
        }}
        size="lg"
        // necessaire pour le fonctionnement des champs textes dans les popup du ckeditor
        disableEnforceFocus
      />
    );
  };

  const handleClickAdminOnboarding = (onboarding) => {
    const { contents = [] } = currentSite;
    const siteOnboarding =
      onboarding ||
      (contents && contents.find((c) => c.key === "onboarding")) ||
      generateContentId(getDefaultContentByType(elementsTypes.SITE_ONBOARDING));

    openAdminModal(
      <AdminContentModal
        open
        content={{
          ...siteOnboarding,
          label: `Calque didactique du site : ${siteName}`,
        }}
        onClose={closeAdminModal}
        onValidate={(newOnboarding) => {
          handleOnboardingChange(newOnboarding);
        }}
        size="lg"
        // necessaire pour le fonctionnement des champs textes dans les popup du ckeditor
        disableEnforceFocus
      />
    );
  };

  const handleClickBanners = () => {
    openAdminModal(
      <Box sx={{ height: "75vh" }}>
        <AdminBanners
          onClose={() => {
            closeAdminModal();
          }}
        />
      </Box>,
      "xl"
    );
  };

  const handleCloseOnboarding = () => {
    setForceOnboarding(false);
  };

  const forceOnboardingContextValue = useMemo(
    () => ({
      forceDisplay: forceOnboarding,
      onClose: handleCloseOnboarding,
      forcedProps: { style: { top: 80 } },
    }),
    [forceOnboarding]
  );

  const handleClickGeoPoints = (type) => () => {
    openAdminModal(
      <Box sx={{ height: "82vh" }}>
        <AdminGeoPoints
          type={type}
          onClose={() => {
            closeAdminModal();
          }}
        />
      </Box>,
      "xl"
    );
  };

  const handleClickUpload = () => {
    document.getElementById(uploaderId).click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setFileName(file?.name || "");
    return AdminPageVersionsService.readMediaFile(file);
  };

  const handleCancelExportMedias = () => {
    setFileName("");
    setMediaIds("");
    setOpenExportMediaModal(false);
  };

  const exportContentMedia = async () => {
    const exportTable = await AdminPageVersionsService.exportContentMedia(allSites, mediaIds);
    const titleKeys = Object.keys(exportTable[0]);
    const refinedData = [];
    refinedData.push(titleKeys);

    exportTable.forEach((item) => {
      refinedData.push(Object.values(item));
    });
    // export data into csv file
    const csvContent = `data:text/csv;charset=utf-8,${refinedData.map((row) => row.join(",")).join("\n")}`;

    const element = document.createElement("a");
    const encodeUri = encodeURI(csvContent);
    element.setAttribute("href", encodeUri);
    element.setAttribute("download", `export_medias_portail_${process.env.environment_suffix}_${exportDate}.csv`);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    handleCancelExportMedias();
  };

  const isHomePage = (version) => {
    if (!version) {
      return false;
    }
    const { page } = version;
    return page && page.parentId === null && version.path === "/";
  };

  // const { status } = pageVersion || {};

  // const isDraft = status === versionStatuses.DRAFT;
  // const isPublished = status === versionStatuses.PUBLISHED;

  // const canPublish = pageVersion && !pageHasChanged && !isPublished;
  // const canRenameVersion = isDraft;
  // const canDeleteVersion = isDraft && pageVersions.length > 1;
  const canDeletePage = !pageVersions.find((version) => version.status === versionStatuses.PUBLISHED);
  const canUnpublish = !isHomePage(pageVersion);

  return (
    <ThemeProvider theme={theme}>
      <Snackbar variant="success" {...snackbar} onClose={handleCloseSnackbar} />
      <AdminMessageContext.Provider value={adminMessageContextValue}>
        <Stack sx={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}>
          <SideBar open={sidebarOpen}>
            <SideBarElement icon="file-alt" iconDSFR="file-text-line" onClick={handleClickAdminPage}>
              Paramètres de la page
            </SideBarElement>
            <SideBarElement icon="file-plus" iconDSFR="file-add-line" onClick={handleClickAddPage}>
              Créer une nouvelle page
            </SideBarElement>
            <SideBarElement
              icon="download"
              iconDSFR="download-line"
              onClick={exportPage}
              title="Exporter la version courante de cette page"
            >
              Exporter la page
            </SideBarElement>
            <SideBarElement
              icon="upload"
              iconDSFR="upload-line"
              onClick={importPage}
              title="Importer une page depuis un fichier JSON"
            >
              Importer une page
            </SideBarElement>
            <Box
              component="input"
              type="file"
              id={importId}
              onChange={handleUploadFile}
              sx={{ display: "none" }}
              accept="application/json"
            />
            <SideBarDivider />
            <SideBarElement icon="search" iconDSFR="search-line" onClick={handleClickPages}>
              Pages du site
            </SideBarElement>
            <SideBarElement icon="horizontal-rule" iconDSFR="subtract-line" onClick={handleClickAdminHeader}>
              Gérer le header du site
            </SideBarElement>
            <SideBarElement icon="bars" iconDSFR="menu-fill" onClick={handleClickAdminMenu}>
              Gérer le menu du site
            </SideBarElement>
            <SideBarElement icon="line-columns" iconDSFR="more-line" onClick={handleClickAdminFooter}>
              Gérer le footer du site
            </SideBarElement>
            <SideBarElement icon="cog" iconDSFR="settings-5-line" onClick={() => handleClickSiteSettings()}>
              Paramètres du site
            </SideBarElement>
            <SideBarElement iconDSFR="information-line" onClick={handleClickBanners}>
              Bandeaux d&apos;information
            </SideBarElement>
            <SideBarDivider />
            <SideBarGroup icon="globe-europe" iconDSFR="earth-line" name="Les sites">
              {allSites.map((site) => (
                <SideBarElement key={site.id} focus={site === currentSite} onClick={() => setCurrentSite(site)}>
                  {site.name}
                </SideBarElement>
              ))}
            </SideBarGroup>
            <SideBarDivider />
            <SideBarElement icon="photo-video" iconDSFR="gallery-line" onClick={handleClickMedias}>
              Médiathèque
            </SideBarElement>
            <SideBarElement
              icon="download"
              iconDSFR="download-line"
              onClick={() => {
                setOpenExportMediaModal(true);
              }}
            >
              Export media
            </SideBarElement>
            <SideBarDivider />
            <SideBarGroup icon="store" iconDSFR="store-line" name="Établissements">
              <SideBarElement onClick={handleClickGeoPoints(geolocSourceType.MAIRIE)}>Mairies</SideBarElement>
              <SideBarElement onClick={handleClickGeoPoints(geolocSourceType.PHOTOGRAPHE)}>Photographes</SideBarElement>
              <SideBarElement onClick={handleClickGeoPoints(geolocSourceType.SIV)}>SIV</SideBarElement>
            </SideBarGroup>
            <SideBarDivider />
            <SideBarElement icon="user-circle" iconDSFR="account-circle-line" onClick={handleRedirectManagement}>
              Mon profil
            </SideBarElement>
            {process.env.keycloak_admin_url && isAdmin && (
              <SideBarElement icon="users" iconDSFR="team-line">
                <LinkComponent url={process.env.keycloak_admin_url} external>
                  Gestion des utilisateurs
                </LinkComponent>
              </SideBarElement>
            )}
            <SideBarElement icon="sign-out" iconDSFR="logout-box-r-line" onClick={handleLogout}>
              Déconnexion
            </SideBarElement>
          </SideBar>
          <Modal open={!!adminModal.content} size={adminModal.size} onClose={closeAdminModal}>
            {adminModal.content}
          </Modal>
          <Modal open={openExportMediaModal} size="md" onClose={() => handleCancelExportMedias()}>
            <h2>Export Media</h2>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Button onClick={handleClickUpload}>Téléverser un fichier</Button>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Box
                  component="p"
                  sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "90%" }}
                >
                  {fileName}
                </Box>
              </Grid>
            </Grid>
            <Box
              component="input"
              id={uploaderId}
              sx={{ mb: 2, display: "none" }}
              type="file"
              onChange={async (e) => {
                const result = await handleFileUpload(e);
                setMediaIds(result);
              }}
            />
            <ModalActions>
              <Button outlined onClick={() => handleCancelExportMedias()}>
                Annuler
              </Button>
              <Button onClick={exportContentMedia}>Valider</Button>
            </ModalActions>
          </Modal>
          {pageVersion && (
            <AdminVersions
              leftActions={
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Icon
                      icon="bars"
                      iconDSFR="menu-fill"
                      onClick={handleClickMenu}
                      sx={{ cursor: "pointer", "&::before": { "--icon-size": "1.75rem" } }}
                      title="Menu"
                    />
                  </Grid>
                  <Grid item>
                    <Button onClick={() => setEditionMode(!editionMode)}>
                      {editionMode ? "Mode prévisualisation" : "Mode édition"}
                    </Button>
                  </Grid>
                </Grid>
              }
              rightActions={
                canDeletePage && (
                  <IconButton onClick={handleDeletePage} aria-label="Supprimer cet article">
                    <Icon
                      icon="trash-can"
                      iconDSFR="delete-bin-line"
                      title="Supprimer cet article"
                      sx={{ color: "#f44336", fontSize: "1rem" }}
                    />
                  </IconButton>
                )
              }
              versions={pageVersions}
              currentVersion={pageVersion}
              setCurrentVersion={setCurrentPageVersion}
              onVersionChange={setPageVersion}
              saveVersion={handleSaveVersion}
              canUnpublish={canUnpublish}
              updateStatus={updateVersionStatus}
              deleteVersion={handleDeletePageVersion}
              exportPage={exportPage}
              autoSaveDelay={process.env.autosave_delay || 0}
              manualSave
              hasRelatedPage={hasRelatedPage}
              associatedPnuPages={associatedPnuPages}
            >
              {({ handleVersionChange }) => (
                <Box sx={{ flex: 1, position: "relative" }} onClick={() => setSidebarOpen(false)}>
                  <ForceOnboardingContext.Provider value={forceOnboardingContextValue}>
                    <RenderPage
                      key={key}
                      page={pageVersion}
                      handlePageChange={handleVersionChange}
                      editionMode={editionMode}
                    />
                  </ForceOnboardingContext.Provider>
                </Box>
              )}
            </AdminVersions>
          )}
        </Stack>
      </AdminMessageContext.Provider>
    </ThemeProvider>
  );
};

export default LayoutBack;
