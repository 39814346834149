import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import scrollIntoView from "scroll-into-view-if-needed";

function EnhancedTableHead(props) {
  const { order, orderBy, columns, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order && order.toLowerCase() : false}
          >
            <TableSortLabel
              hideSortIcon={!headCell.sortable}
              active={orderBy === headCell.id}
              direction={order && order.toLowerCase()}
              onClick={headCell.sortable && createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  sx={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: 1,
                    m: -1,
                    overflow: "hidden",
                    p: 0,
                    position: "absolute",
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === "DESC" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      render: PropTypes.func.isRequired,
    })
  ).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTable = (props) => {
  const {
    columns = [],
    data = [],
    order,
    orderBy,
    page = 1,
    totalRows = 0,
    rowsPerPage,
    rowsPerPageOptions = [5, 10, 25, 50],
    onRequestSort,
    onChangePage,
    onChangeRowsPerPage,
    onRowClick = null,
    renderRowDetail = null,
  } = props;

  const [openedRows, setOpenedRows] = useState([]);
  const [oldSizeOpenedRows, setOldSizeOpenedRows] = useState(openedRows.length);

  const isRowOpen = (row) => openedRows.includes(row.id);

  const scrollSent = (id) => {
    scrollIntoView(document.getElementById(id), {
      block: "start",
      inline: "start",
    });
  };

  useEffect(() => {
    if (oldSizeOpenedRows < openedRows.length) {
      scrollSent(openedRows[openedRows.length - 1]);
    }
    setOldSizeOpenedRows(openedRows.length);
  }, [oldSizeOpenedRows, openedRows]);

  const openRow = (row = {}) => {
    const { id } = row;
    if (id) {
      setOpenedRows([...openedRows, id]);
    }
  };

  const closeRow = (row = {}) => {
    const { id } = row;
    if (id) {
      setOpenedRows(openedRows.filter((r) => r !== id));
    }
  };

  const toggleRow = (row) => {
    if (isRowOpen(row)) {
      closeRow(row);
    } else {
      openRow(row);
    }
  };

  let handleRowClick = onRowClick;
  if (!handleRowClick && renderRowDetail) {
    handleRowClick = (event, row) => {
      toggleRow(row);
    };
  }

  return (
    <Stack sx={{ height: "100%" }}>
      <Box sx={{ flex: "1 0 auto", position: "relative" }}>
        <TableContainer sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
            <EnhancedTableHead columns={columns} order={order} orderBy={orderBy} onRequestSort={onRequestSort} />
            <TableBody>
              {data.map((row) => (
                <Fragment key={row.id}>
                  <TableRow
                    hover
                    id={row.id}
                    onClick={(event) => typeof handleRowClick === "function" && handleRowClick(event, row)}
                    sx={{ cursor: typeof handleRowClick === "function" ? "pointer" : "default" }}
                    tabIndex={-1}
                  >
                    {columns.map((column) => (
                      <TableCell key={column.id} {...column.cellProps}>
                        {column.render(row)}
                      </TableCell>
                    ))}
                  </TableRow>
                  {isRowOpen(row) && (
                    <TableRow>
                      <TableCell colSpan={columns.length}>{renderRowDetail(row, () => closeRow(row))}</TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        labelRowsPerPage=""
        slotProps={{
          actions: {
            nextButton: { "aria-label": "Page suivante", title: "Page suivante" },
            previousButton: { "aria-label": "Page précédente", title: "Page précédente" },
          },
        }}
        onPageChange={(event, p) => onChangePage(p + 1)}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Stack>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func.isRequired,
      cellProps: PropTypes.shape(),
    })
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number,
  totalRows: PropTypes.number,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  onRequestSort: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  renderRowDetail: PropTypes.func,
};

export default EnhancedTable;
