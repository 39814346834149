import axios from "axios";
import generateCRUD from "components/adminServices/generateCRUD";

const name = "geopoints";
const GeoPointsCRUD = generateCRUD(name);

export default class AdminGeoPointsService extends GeoPointsCRUD {
  static import = ({ file, type, userId, fullName, direct }) => {
    const formData = new FormData();
    formData.append("file", file);
    if (direct) {
      formData.append("userId", userId);
      formData.append("fullName", fullName);
      return axios
        .post(`/api/${name}/import`, formData, { params: { overwrite: true, type, direct } })
        .then((r) => r.data);
    }
    const jsonFile = JSON.stringify({ userId, fullName });
    return axios.post(`/${name}/enhance`, formData, { params: { type, jsonFile } }).then((r) => r.data);
  };

  static export = (params) => {
    return axios.get(`/api/${name}/export`, { params, responseType: "blob" }).then((r) => r.data);
  };
}
