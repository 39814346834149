import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AdminContentModal, { canBeAdministrated } from "components/adminComponents/AdminContentModal";
import SelectContentModal from "components/adminComponents/SelectContentModal";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { getAllDefaultContents } from "utils/adminContentsUtils";

const iconArrowStyled = {
  border: "1px solid rgba(0,0,0,0.23)",
  borderRadius: 1,
  color: "secondary.main",
  cursor: "pointer",
};

const AddContentButton = (props) => {
  const { availableContents = [], addContentText = "Ajouter un élement", onClick } = props;
  return (
    availableContents.length > 0 && (
      <Button
        outlined
        onClick={onClick}
        sx={{ color: "inherit", borderColor: "rgba(0,0,0,0.23)", "&::after": { color: "secondary.main" } }}
        icon={{ icon: "add", iconDSFR: "add-line" }}
        iconPosition="right"
      >
        {addContentText}
      </Button>
    )
  );
};
AddContentButton.propTypes = {
  availableContents: PropTypes.arrayOf(PropTypes.shape({})),
  addContentText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

const DynamicAdmin = (props) => {
  const {
    children = null,
    onAddContent = () => {},
    onRemoveContent,
    onSwapContent = () => {},
    contentKey = null,
    addContentText = "Ajouter un élement",
    deleteContentText = null,
    contentsTypes = [],
    childrenLength = null,
    limit = Infinity,
  } = props;

  const [insertIndex, setInsertIndex] = useState();
  const [displayModalSelectContent, setDisplayModalSelectContent] = useState(false);
  const [selectedContent, setSelectedContent] = useState();

  const availableContents = getAllDefaultContents().filter((content) => contentsTypes.includes(content.type));

  const handleAddContent = (content, index) => {
    if (typeof onAddContent === "function") {
      const { key, value = "", children: contentChildren, type } = content;
      onAddContent(
        {
          key: contentKey || key,
          value,
          type,
          children: contentChildren,
        },
        index !== undefined ? index : insertIndex
      );
    }
    setSelectedContent();
  };

  const handleSelectContent = (content, index) => {
    setDisplayModalSelectContent(false);
    if (canBeAdministrated(content)) {
      if (index) {
        setInsertIndex(index);
      }
      setSelectedContent(content);
    } else {
      handleAddContent(content, index);
    }
  };

  const handleClickAddContent = (index) => () => {
    if (availableContents.length > 1) {
      setDisplayModalSelectContent(true);
      setInsertIndex(index);
    } else if (availableContents.length === 1) {
      handleSelectContent(availableContents[0], index);
    }
  };

  const handeRemoveContent = (index) => () => {
    onRemoveContent(index, contentKey);
  };

  const handleMoveUpContent = (index) => () => {
    onSwapContent(index, index - 1);
  };

  const handleMoveDownContent = (index) => () => {
    onSwapContent(index, index + 1);
  };

  const appendButtonToNode = (node, index, allNodes = []) =>
    !!node && (
      <Fragment key={node.key}>
        {index === 0 && allNodes.length < limit && (
          <AddContentButton
            availableContents={availableContents}
            addContentText={addContentText}
            onClick={handleClickAddContent(0)}
          />
        )}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ position: "relative" }}>
            {onSwapContent && childrenLength && (
              <Stack justifyContent="space-between" sx={{ position: "absolute", left: -32, top: 0, bottom: 0 }}>
                {index - 1 >= 0 ? (
                  <Icon
                    iconDSFR="arrow-up-line"
                    title="Remonter l'élément"
                    sx={iconArrowStyled}
                    onClick={handleMoveUpContent(index)}
                  />
                ) : (
                  <Box />
                )}
                {index + 1 < childrenLength ? (
                  <Icon
                    iconDSFR="arrow-down-line"
                    title="Descendre l'élément"
                    sx={iconArrowStyled}
                    onClick={handleMoveDownContent(index)}
                  />
                ) : (
                  <Box />
                )}
              </Stack>
            )}
            {node}
            <span title={deleteContentText}>
              <Icon
                iconDSFR="close-circle-fill"
                sx={{ position: "absolute", cursor: "pointer", top: "-12px", right: "-12px" }}
                onClick={handeRemoveContent(index)}
              />
            </span>
          </Box>
          {availableContents.length > 0 && allNodes.length < limit && (
            <AddContentButton
              availableContents={availableContents}
              addContentText={addContentText}
              onClick={handleClickAddContent(index + 1)}
            />
          )}
        </Box>
      </Fragment>
    );

  const renderedChildren =
    typeof children === "function" ? children(appendButtonToNode) : React.Children.map(children, appendButtonToNode);
  let hasChildren = !!renderedChildren;
  if (Array.isArray(renderedChildren)) {
    hasChildren = renderedChildren.filter((c) => !!c).length > 0;
  }

  return (
    <>
      {!hasChildren && (
        <AddContentButton
          availableContents={availableContents}
          addContentText={addContentText}
          onClick={handleClickAddContent(0)}
        />
      )}
      {renderedChildren}
      <SelectContentModal
        open={displayModalSelectContent}
        contents={availableContents}
        onSelectContent={handleSelectContent}
        onClose={() => setDisplayModalSelectContent(false)}
      />
      <AdminContentModal
        key={selectedContent?.type}
        open={!!selectedContent}
        content={selectedContent}
        onValidate={handleAddContent}
        onClose={() => setSelectedContent()}
      />
    </>
  );
};

DynamicAdmin.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onAddContent: PropTypes.func,
  onSwapContent: PropTypes.func,
  onRemoveContent: PropTypes.func.isRequired,
  addContentText: PropTypes.string,
  deleteContentText: PropTypes.string,
  contentKey: PropTypes.string,
  contentsTypes: PropTypes.arrayOf(PropTypes.string),
  childrenLength: PropTypes.number,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DynamicAdmin;
