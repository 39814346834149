import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import { formControlStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import PropTypes from "prop-types";
import React from "react";

const InputForm = (props) => {
  const { content, info = "", onContentChange, ...others } = props;

  const { id, label, value, width } = content;

  const handleInputChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.value,
    });
  };

  return (
    <FormControl variant="standard" sx={formControlStyled(width)} key={id}>
      <InputLabel shrink sx={info ? { display: "flex", alignItems: "center" } : {}}>
        <p className="fr-text-sm">{label}</p>
        {info && (
          <Tooltip title={info} aria-label="info" placement="top-end" sx={{ fontSize: "1.14rem" }}>
            <p>&nbsp;&#9432;</p>
          </Tooltip>
        )}
      </InputLabel>
      <Input value={value} onChange={handleInputChange} {...others} />
    </FormControl>
  );
};

InputForm.propTypes = {
  content: PropTypes.shape().isRequired,
  info: PropTypes.string,
  onContentChange: PropTypes.func.isRequired,
};

export default InputForm;
