import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Slider from "@material-ui/core/Slider";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

const SliderForm = (props) => {
  const { content, classes, onContentChange, tooltip, ...others } = props;

  const { id, label, value, min, max, step } = content;

  const handleChange = (event, newValue) => {
    onContentChange({
      ...content,
      value: String(newValue),
    });
  };

  return (
    <FormControl key={id} className={classes?.formControl}>
      <InputLabel sx={{ pointerEvents: "auto" }} shrink className={tooltip ? classes?.labelWithTooltip : null}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip}>
            <HelpIcon />
          </Tooltip>
        )}
      </InputLabel>

      <Slider
        className={classes?.slider}
        value={Number(value)}
        valueLabelDisplay="auto"
        step={Number(step)}
        marks
        min={Number(min)}
        max={Number(max)}
        onChange={handleChange}
        {...others}
      />
    </FormControl>
  );
};

SliderForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape(),
  onContentChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

SliderForm.defaultProps = {
  classes: {},
  tooltip: null,
};

export default SliderForm;
