import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Switch from "@material-ui/core/Switch";
import SelectPage from "components/adminComponents/SelectPage";
import elementsTypes from "utils/elementsTypes";
import InputForm from "components/adminComponents/contentForms/InputForm";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import PageVersionContext from "components/PageVersionContext";

const linkTypes = {
  page: "page",
  url: "url",
};

const LinkForm = (props) => {
  const { content, onContentChange, classes, disabled } = props;

  const { id, label, children } = content;

  const { currentPageVersion = {} } = useContext(PageVersionContext);
  const { page = {} } = currentPageVersion;
  const { lang } = page;

  const pageChild = children.find((c) => c.type === elementsTypes.PAGE);
  const urlChild = children.find((c) => c.type === elementsTypes.URL);
  const titleUrlChild = children.find((c) => c.type === elementsTypes.INPUT);
  const checkboxChild = children.find((c) => c.type === elementsTypes.CHECKBOX);

  const [linkType, setLinkType] = useState(pageChild.value ? linkTypes.page : linkTypes.url);
  const [englishPage, setEnglishPage] = useState(lang === "EN");

  useEffect(() => {
    setLinkType(pageChild.value ? linkTypes.page : linkTypes.url);
  }, [pageChild.value]);

  const handleLinkTypeChange = (e) => {
    const type = e.target.value;
    setLinkType(type);
  };

  const handleSwitchLang = () => {
    setEnglishPage(!englishPage);
  };

  const handleSelectPage = (p) => {
    const { versions, ...others } = p;
    // sort version by updated (last modified first)
    versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
    const [lastVersion] = versions;
    if (lastVersion) {
      onContentChange({
        ...content,
        children: children.map((child) => {
          // Update page value
          if (child === pageChild) {
            return {
              ...child,
              value: {
                ...lastVersion,
                page: { ...others },
              },
            };
          }
          // Set url empty when a page is selected
          if (child === urlChild) {
            return {
              ...child,
              value: "",
            };
          }
          return child;
        }),
      });
    }
  };

  const handleUrlChange = (newUrlChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        // Set page empty when a page is selected
        if (child === pageChild) {
          return {
            ...child,
            value: "",
          };
        }
        // Update url value
        if (child === urlChild) {
          return newUrlChild;
        }
        return child;
      }),
    });
  };

  const handleCheckboxChange = (newCheckboxChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === checkboxChild) {
          return newCheckboxChild;
        }
        return child;
      }),
    });
  };

  const handleTitleUrlChange = (newTitleUrlChild) => {
    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === titleUrlChild) {
          return newTitleUrlChild;
        }
        return child;
      }),
    });
  };

  return (
    <fieldset key={id} className={classes.fieldset}>
      <legend>{label}</legend>
      <FormControl>
        <FormLabel component="legend">Type de lien</FormLabel>
        <Grid container>
          <RadioGroup value={linkType} onChange={handleLinkTypeChange} row>
            <FormControlLabel
              value={linkTypes.url}
              control={<Radio color="secondary" disabled={disabled} />}
              label="Url"
            />
            <FormControlLabel
              value={linkTypes.page}
              control={<Radio color="secondary" disabled={disabled} />}
              label="Page"
            />
          </RadioGroup>
          {linkType === linkTypes.page && (
            <Grid item xs container alignItems="center" style={{ marginLeft: "16px" }}>
              <p style={{ marginRight: "12px" }}>FR</p>
              <FormControlLabel
                control={
                  <Switch
                    checked={englishPage}
                    onChange={handleSwitchLang}
                    name="lang"
                    classes={{
                      thumb: classes.thumb,
                      track: classes.track,
                    }}
                  />
                }
                label="EN"
                classes={{ label: classes.switchLabel }}
              />
            </Grid>
          )}
        </Grid>
      </FormControl>
      {linkType === linkTypes.url ? (
        <InputForm content={urlChild} onContentChange={handleUrlChange} classes={classes} />
      ) : (
        <FormControl className={classes.formControl}>
          <SelectPage
            currentPage={pageChild.value && pageChild.value.page}
            onSelectPage={handleSelectPage}
            disabled={disabled}
            label={pageChild.label}
            englishPage={englishPage}
            switchComponent
          />
        </FormControl>
      )}
      <InputForm
        content={titleUrlChild}
        onContentChange={handleTitleUrlChange}
        placeholder="Titre du lien"
        classes={classes}
      />
      <CheckboxForm content={checkboxChild} onContentChange={handleCheckboxChange} classes={classes} />
    </fieldset>
  );
};

LinkForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

LinkForm.defaultProps = {
  disabled: false,
};

export default LinkForm;
