import axios from "axios";
import axiosCatchError from "./AxiosCatchErrorImplement";

export default class AuthService {
  static getMyAccountId = () => {
    return axios
      .get(`/da-ged/api/accounts`)
      .then((res) => {
        return res.data && res.data.owner;
      })
      .catch((error) => axiosCatchError(error));
  };
}
