import AdminMenuItems from "components/adminComponents/AdminMenuItems";
import PropTypes from "prop-types";
import React from "react";

const AdminHeaderForm = (props) => {
  const { header = {}, onHeaderChange } = props;
  const { contents = [] } = header;

  const handleItemsChange = (headerItems) => {
    onHeaderChange({
      ...header,
      contents: headerItems,
    });
  };

  return <AdminMenuItems items={contents} itemKey="profiles" onItemsChange={handleItemsChange} />;
};

AdminHeaderForm.propTypes = {
  header: PropTypes.shape(),
  onHeaderChange: PropTypes.func.isRequired,
};

export default AdminHeaderForm;
