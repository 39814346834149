import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Edit from "@material-ui/icons/Edit";
import AdminContentModal from "components/adminComponents/AdminContentModal";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    border: "double #2646D4",
  },
  actions: {
    position: "absolute",
    top: "12px",
    left: "12px",
    zIndex: "50",
    background: "#ffffff66",
    display: "flex",
    borderRadius: "50%",
    padding: "2px",
    cursor: "pointer",
  },
}));

const AdminActions = (props) => {
  const { children, content, onContentChange, modalProps, ...others } = props;

  const [displayModalContent, setDisplayModalContent] = useState(false);

  const classes = useStyles();

  const handleClickEdit = () => {
    setDisplayModalContent(true);
  };

  const handleContentChange = (updatedContent) => {
    setDisplayModalContent(false);
    onContentChange(updatedContent);
  };

  return (
    <div className={classes.wrapper} {...others}>
      <div className={classes.actions}>
        <Edit onClick={handleClickEdit} />
      </div>
      {children}
      <AdminContentModal
        open={displayModalContent}
        content={content}
        onValidate={handleContentChange}
        onClose={() => setDisplayModalContent(false)}
        {...modalProps}
      />
    </div>
  );
};

AdminActions.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(),
};

AdminActions.defaultProps = {
  modalProps: {},
};

export default AdminActions;
