import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Icon from "components/templatesComponents/Icon";

const useElementStyles = makeStyles((theme) => ({
  element: (props) => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(3),
    height: "45px",
    cursor: "pointer",
    background: props.focus ? "#ccc" : "transparent",
    "& > a": {
      fontSize: "0.875rem",
    },
    "&:hover": {
      background: props.focus ? "#ccc" : "#ebebeb",
    },
  }),
  icon: {
    position: "absolute",
    left: "18px",
    top: "calc(50% - 11px)",
  },
  groupName: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  groupChildren: (props) => ({
    height: props.open ? "auto" : 0,
    overflow: "hidden",
    paddingLeft: theme.spacing(2),
  }),
  divider: {
    borderBottom: "1px solid #ccc",
    margin: theme.spacing(2),
  },
}));

export const SideBarElement = (props) => {
  const { children, focus, icon, iconDSFR, ...others } = props;

  const classes = useElementStyles({ focus });

  return (
    <div className={classes.element} {...others}>
      {(icon || iconDSFR) && <Icon icon={icon} iconDSFR={iconDSFR} className={classes.icon} />}
      {children}
    </div>
  );
};

SideBarElement.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  iconDSFR: PropTypes.string,
  focus: PropTypes.bool,
};

SideBarElement.defaultProps = {
  children: null,
  icon: null,
  iconDSFR: null,
  focus: false,
};

export const SideBarGroup = (props) => {
  const { name, children, ...others } = props;

  const [open, setOpen] = useState(false);

  const classes = useElementStyles({ open });

  const handleClickGroupName = () => setOpen(!open);

  const icon = open ? "chevron-up" : "chevron-down";
  const iconDSFR = open ? "arrow-up-s-line" : "arrow-down-s-line";

  return (
    <Fragment>
      <SideBarElement onClick={handleClickGroupName} {...others}>
        <div className={classes.groupName}>
          {name}
          <Icon icon={icon} iconDSFR={iconDSFR} />
        </div>
      </SideBarElement>
      <div className={classes.groupChildren}>{children}</div>
    </Fragment>
  );
};

SideBarGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};

SideBarGroup.defaultProps = {
  children: null,
};

export const SideBarDivider = () => {
  const classes = useElementStyles();
  return <div className={classes.divider} />;
};

const useStyles = makeStyles(() => ({
  root: (props) => ({
    zIndex: 1200,
    position: "fixed",
    top: 81,
    left: `${props.open ? "0" : "-300px"}`,
    bottom: 0,
    background: "#fff",
    borderRight: "1px solid #ccc",
    paddingTop: "24px",
    width: "300px",
    transition: "left linear 350ms",
    overflow: "auto",
  }),
}));

const SideBar = (props) => {
  const { open, children } = props;

  const classes = useStyles({ open });

  return <div className={classes.root}>{children}</div>;
};

SideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default SideBar;
