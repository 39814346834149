import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import React from "react";

const SelectVersion = (props) => {
  const { versions = [], selectedVersion = null, onSelectVersion } = props;
  const handleSelectChange = (e) => {
    const id = e.target.value;
    if (selectedVersion.id !== id) {
      onSelectVersion(versions.find((version) => version.id === id));
    }
  };
  const getVersionName = (version) =>
    version.versionName ||
    `Version du ${new Date(version.updated).toLocaleDateString("FR-fr", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })}`;

  return (
    <FormControl variant="outlined">
      {selectedVersion && (
        <Select value={selectedVersion.id} onChange={handleSelectChange}>
          {versions.map((version) => (
            <MenuItem key={version.id} value={version.id}>
              {getVersionName(version)}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

SelectVersion.propTypes = {
  versions: PropTypes.arrayOf(PropTypes.shape()),
  selectedVersion: PropTypes.shape(),
  onSelectVersion: PropTypes.func.isRequired,
};

export default SelectVersion;
