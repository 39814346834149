import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";

const InputFilter = (props) => {
  const { label, placeholder, inputValue, handleChange, handleReset } = props;
  return (
    <>
      <TextField
        label={label}
        placeholder={placeholder}
        variant="standard"
        value={inputValue}
        disableUnderline
        onChange={handleChange}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <IconButton onClick={() => handleReset()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
};

InputFilter.propTypes = {
  inputValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputFilter;
