import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import AdminSitesService from "components/adminServices/AdminSitesService";

const SelectSiteName = (props) => {
  const { siteName, onSiteNameChange } = props;

  const [sites, setSites] = useState([]);

  useEffect(() => {
    AdminSitesService.find({
      params: {
        sort: "name,ASC",
      },
    }).then(setSites);
  }, []);

  const handleSelectSiteChange = (e) => {
    const name = e.target.value;
    onSiteNameChange(name);
  };

  return (
    <Select
      value={siteName}
      onChange={handleSelectSiteChange}
      displayEmpty
      MenuProps={{ getContentAnchorEl: null }}
      style={{ width: "140px" }}
    >
      <MenuItem value="allSites">Tous les sites</MenuItem>
      {sites.map((s) => (
        <MenuItem key={s.name} value={s.name}>
          {s.name}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectSiteName.propTypes = {
  siteName: PropTypes.string,
  onSiteNameChange: PropTypes.func.isRequired,
};

SelectSiteName.defaultProps = {
  siteName: "",
};

export default SelectSiteName;
