import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import AdminContext from "components/adminComponents/AdminContext";
import ModalConfirm from "components/adminComponents/ModalConfirm";
import PagesTable from "components/adminComponents/PagesTable";
import PreventNavigation from "components/adminComponents/PreventNavigation";
import RenameVersionForm from "components/adminComponents/RenameVersionForm";
import SelectVersion from "components/adminComponents/SelectVersion";
import AdminSitesService from "components/adminServices/AdminSitesService";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import versionStatuses from "utils/versionStatuses";

const formatDate = (date) =>
  new Date(date).toLocaleDateString("FR-fr", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

const versionLabelStyled = (published = false) => ({
  mt: 1,
  fontSize: "0.79rem",
  fontStyle: published ? "normal" : "italic",
  color: published ? "green" : "#666",
});

const DefaultMessage = (props) => {
  const { version = null } = props;
  const { status } = version || {};
  switch (status) {
    case versionStatuses.DRAFT:
      return (
        <Box component="span" sx={versionLabelStyled()}>
          Brouillon (Dernière mise à jour le {formatDate(version.updated)})
        </Box>
      );
    case versionStatuses.ARCHIVED:
      return (
        <Box component="span" sx={versionLabelStyled()}>
          Version archivée le {formatDate(version.updated)}
        </Box>
      );
    case versionStatuses.PUBLISHED:
      return (
        <Box component="span" sx={versionLabelStyled(true)}>
          Version publiée le {formatDate(version.updated)}
        </Box>
      );
    default:
      return null;
  }
};

DefaultMessage.propTypes = {
  version: PropTypes.shape(),
};

const AdminVersions = (props) => {
  const {
    leftActions,
    rightActions = null,
    versions,
    // refreshVersions,
    currentVersion,
    setCurrentVersion,
    onVersionChange,
    // versionMessage,
    canRename: canRenameProp = true,
    saveVersion,
    canPublish: canPublishProp = true,
    canUnpublish: canUnpublishProp,
    updateStatus = null,
    canDelete: canDeleteProp = true,
    deleteVersion,
    children,
    exportPage = null,
    autoSaveDelay = 2000,
    manualSave = false,
    hasRelatedPage = false,
    associatedPnuPages = [],
  } = props;

  const { currentSite, setCurrentPage } = useContext(AdminContext);

  const { id, versionName, page: currentPage = {} } = currentVersion;

  const { name: currentPageName, lang: currentPageVersionLang } = currentPage;

  const history = useHistory();
  const theme = useTheme();

  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);
  const [versionMessage, setVersionMessage] = useState(null);
  const [versionHasChanged, setVersionHasChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [modal, setModal] = useState({
    content: null,
    size: null,
  });

  useEffect(() => {
    setVersionHasChanged(false);
    setVersionMessage(null);
  }, [id]);

  const openModal = (content, size = null) => {
    setModal({ content, size });
  };

  const closeModal = () =>
    setModal({
      content: null,
      size: null,
    });

  const handleClickRenameVersion = () => {
    openModal(
      <RenameVersionForm
        versionName={versionName}
        onValidate={(name) => {
          saveVersion({ ...currentVersion, versionName: name }).then(() => {
            setCurrentVersion(currentVersion);
            closeModal();
          });
        }}
        onCancel={closeModal}
      />
    );
  };

  const handleClickDeleteVersion = () => {
    openModal(
      <ModalConfirm
        title="Supprimer cette version"
        text="Attention cette action est irréversible"
        confirmText="Supprimer"
        confirmButtonProps={{ sx: { bgcolor: "error.main" } }}
        onConfirm={() => {
          deleteVersion(currentVersion).then(() => {
            setVersionHasChanged(false);
            setCurrentVersion();
            closeModal();
          });
        }}
        onCancel={closeModal}
      />,
      "md"
    );
  };

  const handleUpdateEnglishVersion = useCallback(() => {
    if (currentVersion?.page?.lang === "FR" && hasRelatedPage) {
      openModal(
        <ModalConfirm
          title={currentPageName}
          text="Souhaitez-vous modifier ou publier la page anglaise rattachée ? "
          confirmText=" Aller sur la page anglaise"
          confirmButtonProps={{ variant: "contained" }}
          onConfirm={() => {
            const pathname = associatedPnuPages?.find((p) => p.page?.lang === "EN")?.fullPath;
            history.push(pathname);
            closeModal();
          }}
          onCancel={closeModal}
          cancelText="Rester sur la page française"
        />,
        "md"
      );
    }
  }, [currentVersion, hasRelatedPage, currentPageName, associatedPnuPages, history]);

  const updateVersionStatus = ({ version, status }) => {
    AdminSitesService.resetSiteCache(currentSite);
    return updateStatus({ version, status }).then(() => {
      setCurrentVersion(version);
      handleUpdateEnglishVersion();
    });
  };

  const handleClickPublish = () => {
    const { status } = currentVersion;
    if (status === versionStatuses.DRAFT) {
      updateVersionStatus({ version: currentVersion, status: versionStatuses.PUBLISHED });
    } else {
      saveVersion(currentVersion).then((newVersion) =>
        updateVersionStatus({ version: newVersion, status: versionStatuses.PUBLISHED })
      );
    }
  };

  const handleClickUnpublish = () => {
    openModal(
      <ModalConfirm
        title={`Dépublier "${currentPageName}"`}
        text={
          <Box>
            {hasRelatedPage && (
              <Box sx={{ color: "red", pb: 1 }}>
                Attention, une page {currentPageVersionLang === "EN" ? "française" : "anglaise"} est rattachée à cette
                page. Veillez à la dépublier également
              </Box>
            )}
            <Box>
              Attention, si vous dépubliez cette page, les sous-pages liées à celle-ci seront également dépubliées.
            </Box>
          </Box>
        }
        detailContent={
          <PagesTable
            onPageClick={(event, page) => {
              setCurrentPage(page);
              closeModal();
            }}
            pageId={currentVersion?.pageId}
            title={`Liste des sous-pages de "${currentPageName}"`}
          />
        }
        confirmText="Dépublier"
        confirmButtonProps={{ sx: { bgcolor: "error.main" } }}
        onConfirm={() => {
          updateVersionStatus({ version: currentVersion, status: versionStatuses.ARCHIVED });
          closeModal();
        }}
        onCancel={closeModal}
      />,
      "md"
    );
  };

  const handleSaveVersion = useCallback(
    (version, refresh = true) => {
      if (!isSaving) {
        if (version.status === versionStatuses.DRAFT) {
          setVersionMessage("Enregistrement...");
        } else {
          setVersionMessage("Création d'un nouveau brouillon...");
        }
        setAutoSaveTimeout(null);
        setIsSaving(true);
        saveVersion(version).then((v) => {
          if (refresh) {
            setCurrentVersion(v).then(() => {
              setIsSaving(false);
              setVersionHasChanged(false);
              setVersionMessage(null);
              handleUpdateEnglishVersion();
            });
          } else {
            setIsSaving(false);
            setVersionHasChanged(false);
            setVersionMessage(null);
          }
        });
      }
    },
    [isSaving, saveVersion, setCurrentVersion, handleUpdateEnglishVersion]
  );

  const handleVersionChange = useCallback(
    (version) => {
      if (version.id === id) {
        setVersionMessage("Modifications en attente");
        setVersionHasChanged(true);
        onVersionChange(version);
        if (autoSaveDelay > 0 || !manualSave) {
          if (autoSaveTimeout) {
            clearTimeout(autoSaveTimeout);
          }
          if (autoSaveDelay && autoSaveDelay > 0) {
            const newTimeout = setTimeout(() => {
              handleSaveVersion(version);
            }, autoSaveDelay);
            setAutoSaveTimeout(newTimeout);
          }
        }
      }
    },
    [id, onVersionChange, autoSaveTimeout, autoSaveDelay, handleSaveVersion, manualSave]
  );

  const handleShowPrompt = () => {
    clearTimeout(autoSaveTimeout);
  };

  const handleClickNavigate = () => {
    setVersionHasChanged(false);
    setVersionMessage(null);
  };

  const { status } = currentVersion || {};

  const isDraft = status === versionStatuses.DRAFT;
  const isPublished = status === versionStatuses.PUBLISHED;

  const canRename = canRenameProp && isDraft;
  const canPublish = canPublishProp && typeof updateStatus === "function" && !versionHasChanged && !isPublished;
  const canUnpublish = canUnpublishProp && typeof updateStatus === "function" && isPublished;
  const canDelete = canDeleteProp && typeof deleteVersion === "function" && isDraft && versions.length > 1;

  return (
    <>
      <PreventNavigation
        blocked={versionHasChanged && !isSaving}
        onSave={() => handleSaveVersion(currentVersion, false)}
        onShowPrompt={handleShowPrompt}
        onNavigate={handleClickNavigate}
      />
      <Modal open={!!modal.content} size={modal.size} onClose={closeModal}>
        {modal.content}
      </Modal>
      <Box sx={{ height: "80px", bgcolor: "white", borderBottom: "1px solid #ccc", zIndex: 1150, px: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ height: "100%" }}>
          <Grid item>{leftActions}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <SelectVersion
                      versions={versions}
                      selectedVersion={currentVersion}
                      onSelectVersion={setCurrentVersion}
                    />
                  </Grid>
                  {canRename && (
                    <Grid item>
                      <Icon
                        icon="edit"
                        type="fas"
                        iconDSFR="edit-box-line"
                        title="Renommer cette version"
                        onClick={handleClickRenameVersion}
                        sx={{
                          fontSize: "0.83rem",
                          cursor: "pointer",
                          ml: 1,
                        }}
                      />
                    </Grid>
                  )}
                  {canDelete && (
                    <Grid item>
                      <Icon
                        icon="trash"
                        type="fas"
                        iconDSFR="delete-line"
                        title="Supprimer cette version"
                        onClick={handleClickDeleteVersion}
                        sx={{
                          fontSize: "0.83rem",
                          cursor: "pointer",
                          ml: 1,
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                {versionMessage ? (
                  <Box component="span" sx={versionLabelStyled()}>
                    {versionMessage}
                  </Box>
                ) : (
                  <DefaultMessage version={currentVersion} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <IconButton
                  onClick={exportPage}
                  color="secondary"
                  sx={{ fontSize: "1rem" }}
                  aria-label="Exporter la version courante de cette page"
                >
                  <Icon icon="download" iconDSFR="download-line" title="Exporter la version courante de cette page" />
                </IconButton>
              </Grid>
              <Grid item>{rightActions}</Grid>
              {manualSave && (
                <Grid item>
                  <Button onClick={() => handleSaveVersion(currentVersion)} disabled={!versionHasChanged || isSaving}>
                    Enregistrer
                  </Button>
                </Grid>
              )}
              <Grid item>
                {!canUnpublish && (
                  <Button onClick={handleClickPublish} disabled={!canPublish || versionHasChanged}>
                    Publier cette version
                  </Button>
                )}
                {canUnpublish && (
                  <Button
                    style={{
                      backgroundColor: theme.palette.warning.light,
                      color: theme.palette.getContrastText(theme.palette.warning.light),
                    }}
                    onClick={handleClickUnpublish}
                  >
                    Dépublier cette version
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {children({ handleVersionChange })}
    </>
  );
};

const versionShape = {
  status: PropTypes.oneOf(Object.values(versionStatuses)).isRequired,
  updated: PropTypes.string.isRequired,
  versionName: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape()),
};

AdminVersions.propTypes = {
  leftActions: PropTypes.node.isRequired,
  rightActions: PropTypes.node,
  versions: PropTypes.arrayOf(PropTypes.shape(versionShape)).isRequired,
  currentVersion: PropTypes.shape(versionShape).isRequired,
  setCurrentVersion: PropTypes.func.isRequired,
  onVersionChange: PropTypes.func.isRequired,
  saveVersion: PropTypes.func.isRequired,
  canRename: PropTypes.bool,
  canPublish: PropTypes.bool,
  canUnpublish: PropTypes.bool.isRequired,
  updateStatus: PropTypes.func,
  canDelete: PropTypes.bool,
  deleteVersion: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  exportPage: PropTypes.func,
  autoSaveDelay: PropTypes.number,
  manualSave: PropTypes.bool,
  hasRelatedPage: PropTypes.bool,
  associatedPnuPages: PropTypes.arrayOf(PropTypes.shape()),
};

export default AdminVersions;
