import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import Modal from "components/templatesComponents/Modal";
import { Grid } from "@material-ui/core";
import Icon from "components/templatesComponents/Icon";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    paddingBottom: "12px",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  item: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary[20],
    },
  },
}));
const SelectContentModal = (props) => {
  const { open, contents, onSelectContent, onClose } = props;

  const classes = useStyles();

  const theme = useTheme();

  const handleClickContent = (content) => {
    onSelectContent(content);
  };

  const getIcon = (c) => {
    const { icon, iconDSFR, IconComponent } = c || {};
    if (IconComponent) {
      return <IconComponent color="white" backgroundColor={theme.palette.primary[70]} />;
    }
    if (iconDSFR) {
      return <Icon iconDSFR={iconDSFR} type="fas" style={{ color: theme.palette.primary[70] }} />;
    }
    if (icon) {
      return <Icon icon={icon} type="fas" style={{ color: theme.palette.primary[70] }} />;
    }
    return null;
  };

  contents.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Modal
      aria-labelledby="content-type"
      aria-describedby="select-content-type"
      size="sm"
      open={open}
      onClose={onClose}
    >
      <h2 id="content-type" className={classes.title}>
        Choix de l&apos;élément
      </h2>
      <Grid container spacing={2} id="simple-modal-description">
        {contents.map((content) => (
          <Grid
            item
            xs={4}
            container
            direction="column"
            alignItems="center"
            key={content.type}
            className={classes.item}
            onClick={() => handleClickContent(content)}
          >
            <Grid item style={{ width: 24, height: 24, fontSize: 24, marginBottom: 1 }}>
              {getIcon(content)}
            </Grid>
            <p style={{ color: theme.palette.primary[70] }}>{content.label}</p>
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
};

SelectContentModal.propTypes = {
  open: PropTypes.bool,
  contents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectContent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

SelectContentModal.defaultProps = {
  open: false,
};

export default SelectContentModal;
