import Box from "@mui/material/Box";
import AdminContext from "components/adminComponents/AdminContext";
import { formStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import FilePicker from "components/adminComponents/FilePicker";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import elementsTypes from "utils/elementsTypes";

const ImageForm = (props) => {
  const { content, onContentChange, formSubmitted = false } = props;
  const { id, label, children } = content;

  const { currentPageVersion } = useContext(AdminContext);

  const fileImageChild = children.find((c) => c.type === elementsTypes.FILE_IMAGE);

  const altChild = children.find((c) => c.key === "alt");
  const titleChild = children.find((c) => c.key === "title");

  const fileValue = {
    ...((fileImageChild && fileImageChild.value && JSON.parse(fileImageChild.value)) || {}),
    alt: altChild && altChild.value,
    title: titleChild && titleChild.value,
  };

  const handleSelectFile = (file) => {
    const { alt, title } = file || {};

    onContentChange({
      ...content,
      children: children.map((child) => {
        if (child === fileImageChild) {
          return {
            ...child,
            value: file ? JSON.stringify(file) : "",
          };
        }
        if (child === altChild) {
          return {
            ...child,
            value: alt || "",
          };
        }
        if (child === titleChild) {
          return {
            ...child,
            value: title || "",
          };
        }
        return child;
      }),
    });
  };

  return (
    <Box component="fieldset" key={id} sx={formStyled.fieldset}>
      <legend>{label}</legend>
      <FilePicker
        onSelectFile={handleSelectFile}
        pageVersion={currentPageVersion}
        image
        file={fileValue}
        formSubmitted={formSubmitted}
      />
    </Box>
  );
};

ImageForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
};

export default ImageForm;
