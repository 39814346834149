import React, { useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MuiButton from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import AdminContext from "components/adminComponents/AdminContext";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import ModalConfirm from "components/adminComponents/ModalConfirm";
import themes from "apps/front-office/client/themes/themes";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import AdminBannerContent from "components/adminComponents/AdminBannerContent";
import AdminBannersService from "components/adminServices/AdminBannersService";
import { getSiteName } from "utils/commonUtils";
import { types, dateNowFormatted, localeDateNow, getDisplayPeriod, displaySortedSites } from "utils/bannersUtils";
import { TableBody, TableCell, TableRow } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fieldset: {
    border: "1px solid rgba(0,0,0, 0.28)",
    borderRadius: 4,
    "& legend": {
      fontSize: "0.875rem",
    },
  },
  divider: {
    margin: theme.spacing(1, 2),
  },
  table: {
    margin: theme.spacing(2, 0),
    "& tbody": {
      display: "table",
      width: "100%",
    },
  },
}));

const AdminBannerForm = (props) => {
  const { banner, banners, getBanners, onSubmit, submitLabel, onCancel } = props;
  const { id, ...duplicateBanner } = banner;

  const classes = useStyles();
  const { currentSite, allSites } = useContext(AdminContext);
  const { displaySuccess, displayError } = useContext(AdminMessageContext);

  const theme = themes[currentSite.name] || themes.ANTS;

  const [form, setForm] = useState(banner);
  const [submitted, setSubmitted] = useState(false);
  const [duplicateBannerModal, setDuplicateBannerModal] = useState(false);
  const [multipleBannersModal, setMultipleBannersModal] = useState(false);
  const [deleteBannerModal, setDeleteBannerModal] = useState(false);

  const { forceDisplay = false, sites = [], type, displayStartDate = "", displayEndDate = "", contents = [] } = form;

  const startDateFormatted = useMemo(
    () => (displayStartDate ? displayStartDate.split(":")[0].concat(":", displayStartDate.split(":")[1]) : ""),
    [displayStartDate]
  );

  const endDateFormatted = useMemo(
    () => (displayEndDate ? displayEndDate.split(":")[0].concat(":", displayEndDate.split(":")[1]) : ""),
    [displayEndDate]
  );

  const errors = useMemo(() => {
    const newErrors = {};
    if (!type) {
      newErrors.type = "Le type est obligatoire";
    }
    if (!contents.find((content) => content.lang === "FR")?.title) {
      newErrors.titleFR = "Le titre du bandeau français est obligatoire";
    }
    if (!contents.find((content) => content.lang === "EN")?.title) {
      newErrors.titleEN = "Le titre du bandeau en anglais est obligatoire";
    }
    if (!forceDisplay) {
      if (!displayStartDate && displayEndDate) {
        newErrors.displayStartDate = "Les deux dates sont nécessaires pour définir une période d'affichage";
      }
      if (displayStartDate && !displayEndDate) {
        newErrors.displayEndDate = "Les deux dates sont nécessaires pour définir une période d'affichage";
      }
      if (displayStartDate && displayEndDate) {
        if (displayStartDate >= displayEndDate) {
          newErrors.displayDate = "La date de fin doit être postérieure à la date de début";
        }
      }
    }
    return newErrors;
  }, [contents, displayEndDate, displayStartDate, forceDisplay, type]);

  const warnings = useMemo(() => {
    const newWarnings = {};
    if (displayStartDate && displayEndDate) {
      if (forceDisplay && displayStartDate >= displayEndDate) {
        newWarnings.displayDate = "Les dates étant erronées, elles ne seront pas enregistrées";
      }
      if (!forceDisplay && Date.parse(displayEndDate) < localeDateNow) {
        newWarnings.displayDate = "La fin de période d'affichage est antérieure à la date actuelle";
      }
    }
    return newWarnings;
  }, [displayEndDate, displayStartDate, forceDisplay]);

  const showError = (key) => submitted && errors[key];
  const showWarning = (key) => warnings[key];
  const isFormValid = Object.values(errors).reduce((a, b) => a && !b, true);

  const closeDuplicateBannerModal = () => {
    setDuplicateBannerModal(false);
  };

  const closeMultipleBannersModal = () => {
    setMultipleBannersModal(false);
  };

  const closeDeleteBannerModal = () => {
    setDeleteBannerModal(false);
  };

  const handleTypeChange = (e) => {
    setForm({
      ...form,
      type: e.target.value,
    });
  };

  const handleSelectSites = (e) => {
    const { value } = e.target;
    setForm({
      ...form,
      sites: value.map((site) => JSON.parse(site)),
    });
  };

  const handleDisplayChange = (e) => {
    setForm({ ...form, forceDisplay: e.target.checked });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value ? `${value}:00.000Z` : "" });
  };

  const handleContentsChange = (e, lang) => {
    const { name, value } = e.target;
    if (contents.length === 0 || !contents.find((content) => content.lang === lang)) {
      setForm({
        ...form,
        contents: [...contents, { lang, [name]: value }],
      });
    } else {
      const newValue = contents?.map((content) => (content.lang === lang ? { ...content, [name]: value } : content));
      setForm({
        ...form,
        contents: newValue,
      });
    }
  };

  const deleteBanner = () => {
    AdminBannersService.delete(banner)
      .then(() => {
        displaySuccess("Bandeau supprimé");
        getBanners();
      })
      .catch(() => {
        displayError("Échec lors de la suppression du bandeau");
      });
  };

  const multipleBanners = useMemo(() => {
    if (sites.length === 0 || (!forceDisplay && !displayStartDate && !displayEndDate)) {
      return [];
    }
    let bannersFiltered = banners.filter((b) => b.id !== id);
    if (sites.length > 0) {
      const sitesIds = sites.map((site) => site.id);
      bannersFiltered = bannersFiltered.filter((b) => b.sites.find((site) => sitesIds.includes(site.id)));
    }
    if (forceDisplay) {
      bannersFiltered = bannersFiltered.filter(
        (b) =>
          b.forceDisplay || (b.displayStartDate && b.displayEndDate && Date.parse(b.displayEndDate) >= localeDateNow)
      );
    }
    if (displayStartDate && displayEndDate) {
      const start = new Date(displayStartDate);
      const end = new Date(displayEndDate);
      bannersFiltered = bannersFiltered.filter((b) => {
        const startDate = new Date(b.displayStartDate);
        const endDate = new Date(b.displayEndDate);
        return (
          b.forceDisplay ||
          (start >= startDate && start <= endDate) ||
          (end >= startDate && end <= endDate) ||
          (start <= startDate && end >= endDate)
        );
      });
    }
    return bannersFiltered;
  }, [banners, displayEndDate, displayStartDate, forceDisplay, id, sites]);

  const handleFormSubmit = () => {
    setSubmitted(true);
    if (isFormValid) {
      if (multipleBanners.length === 0) {
        if (typeof onSubmit === "function") {
          onSubmit(form);
        }
      } else {
        setMultipleBannersModal(true);
      }
    }
  };

  return (
    <Box p={2} borderColor="grey.500" border={1} style={{ position: "relative" }}>
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={banner?.id ? 2 : 3}>
          <FormControl error={showError("type")}>
            <TextField
              id="type"
              name="type"
              select
              label="Type *"
              value={type || ""}
              onChange={handleTypeChange}
              error={showError("type")}
            >
              {types.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {showError("type") && <FormHelperText style={{ height: "22px" }}>{errors.type}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={banner?.id ? 8 : 9}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="controlled-multiple-sites-label">Sites</InputLabel>
            <Select
              labelId="controlled-open-select-label"
              id="controlled-open-select"
              name="sites"
              multiple
              label="Sites"
              value={sites?.map((site) => JSON.stringify(site)) || []}
              onChange={handleSelectSites}
              renderValue={(selectedSites) => {
                if (selectedSites.length === 0) {
                  return "";
                }
                return displaySortedSites(selectedSites?.map((site) => JSON.parse(site)) || []);
              }}
              MenuProps={{
                MenuListProps: {
                  style: {
                    padding: 0,
                  },
                },
                getContentAnchorEl: null,
              }}
            >
              {allSites.map((option) => (
                <MenuItem key={option.id} value={JSON.stringify(option)}>
                  <Checkbox checked={sites.map((site) => site.id).includes(option.id)} />
                  {getSiteName(option.name)}
                </MenuItem>
              ))}
            </Select>
            {showError("type") && <FormHelperText style={{ height: "22px" }} />}
          </FormControl>
        </Grid>
        {banner?.id && (
          <>
            <Grid item xs={1} style={{ textAlign: "center" }}>
              <IconButton size="small" color="secondary" onClick={() => setDuplicateBannerModal(true)}>
                <Icon icon="copy" title="Dupliquer le bandeau" />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" color="secondary" onClick={() => setDeleteBannerModal(true)}>
                <Icon icon="trash-alt" title="Supprimer le bandeau" />
              </IconButton>
            </Grid>
            {typeof onCancel === "function" && (
              <IconButton
                size="small"
                color="secondary"
                onClick={onCancel}
                style={{ position: "absolute", top: "4px", right: "8px" }}
              >
                <Icon iconDSFR="close-line" title="Fermer le détail" />
              </IconButton>
            )}
          </>
        )}
        <Grid item xs={12}>
          <fieldset className={classes.fieldset}>
            <FormLabel component="legend">Affichage</FormLabel>
            <Grid container alignItems="center" justifyContent="center" spacing={4}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="forceDisplay"
                      name="forceDisplay"
                      checked={forceDisplay}
                      onChange={handleDisplayChange}
                      disableRipple
                    />
                  }
                  label="Permanent"
                />
              </Grid>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <Grid item xs={8}>
                <FormControl
                  error={showError("displayStartDate") || showError("displayEndDate") || showError("displayDate")}
                >
                  <Grid container justifyContent="space-around" alignItems="flex-end">
                    <Grid item xs={5}>
                      <TextField
                        id="startDate"
                        name="displayStartDate"
                        label="Date de début d'affichage"
                        type="datetime-local"
                        value={startDateFormatted}
                        onChange={handleDateChange}
                        disabled={forceDisplay}
                        title={forceDisplay ? "Le bandeau est en affichage permanent" : ""}
                        error={showError("displayStartDate") || showError("displayDate")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: dateNowFormatted,
                          max: endDateFormatted,
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="endDate"
                        name="displayEndDate"
                        label="Date de fin d'affichage"
                        type="datetime-local"
                        value={endDateFormatted}
                        onChange={handleDateChange}
                        disabled={forceDisplay}
                        title={forceDisplay ? "Le bandeau est en affichage permanent" : ""}
                        error={showError("displayEndDate") || showError("displayDate")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: startDateFormatted || dateNowFormatted,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} container justifyContent="flex-end">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => setForm({ ...form, displayStartDate: "", displayEndDate: "" })}
                        disabled={forceDisplay || (!displayStartDate && !displayEndDate)}
                      >
                        <Icon iconDSFR="refresh-line" title="Réinitialiser les dates" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {showError("displayEndDate") && (
                    <FormHelperText style={{ textAlign: "center" }}>{errors.displayEndDate}</FormHelperText>
                  )}
                  {showError("displayStartDate") && (
                    <FormHelperText style={{ textAlign: "center" }}>{errors.displayStartDate}</FormHelperText>
                  )}
                  {showError("displayDate") && (
                    <FormHelperText style={{ textAlign: "center" }}>{errors.displayDate}</FormHelperText>
                  )}
                </FormControl>
                {showWarning("displayDate") && (
                  <FormHelperText className="fr-icon-warning-line" style={{ textAlign: "center" }}>
                    &nbsp;{warnings.displayDate}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
        <AdminBannerContent
          classes={classes}
          contents={contents}
          errors={errors}
          lang="FR"
          onChange={(e) => handleContentsChange(e, "FR")}
          showError={showError}
          title="Français"
        />
        <AdminBannerContent
          classes={classes}
          contents={contents}
          errors={errors}
          lang="EN"
          onChange={(e) => handleContentsChange(e, "EN")}
          showError={showError}
          title="Anglais"
        />
      </Grid>
      <Box mt={2}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            {typeof onCancel === "function" && (
              <MuiButton variant="contained" color="default" type="button" size="large" onClick={onCancel}>
                Annuler
              </MuiButton>
            )}
          </Grid>
          <Grid item>
            <Button onClick={handleFormSubmit} primary>
              {submitLabel}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Modal open={duplicateBannerModal} size="lg" onClose={closeDuplicateBannerModal}>
        <h2>Duplication du bandeau</h2>
        <AdminBannerForm
          banner={duplicateBanner}
          banners={banners}
          onSubmit={onSubmit}
          onCancel={closeDuplicateBannerModal}
          submitLabel="Dupliquer"
        />
      </Modal>
      <Modal open={multipleBannersModal} size="sm" onClose={closeMultipleBannersModal}>
        <ModalConfirm
          title={multipleBanners.length > 1 ? "Bandeaux similaires déjà existants" : "Bandeau similaire déjà existant"}
          text={
            <Box pb={2}>
              {`Attention ${
                multipleBanners ? "des bandeaux sont déjà programmés" : "un bandeau est déjà programmé"
              } pour apparaître sur au moins un des sites selectionnés :`}
              <Grid container direction="column" spacing={2} className={classes.table}>
                {sites.length > 0 &&
                  sites.map((site) => (
                    <Grid item key={site.id}>
                      <h6 style={{ margin: 0 }}>{getSiteName(site.name)}</h6>
                      <TableBody>
                        {multipleBanners
                          ?.filter((ban) => ban.sites?.find((s) => s.id === site.id))
                          .map((ban) => (
                            <TableRow key={ban.id}>
                              <TableCell>{ban.contents?.find((content) => content.lang === "FR").title}</TableCell>
                              <TableCell>{getDisplayPeriod(ban)}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Grid>
                  ))}
              </Grid>
              {`Voulez-vous tout de même ${submitLabel.toLowerCase()} ce nouveau bandeau ?`}
            </Box>
          }
          confirmText={submitLabel}
          onConfirm={() => {
            if (typeof onSubmit === "function") {
              onSubmit(form);
            }
            closeMultipleBannersModal();
          }}
          onCancel={closeMultipleBannersModal}
        />
      </Modal>
      <Modal open={deleteBannerModal} size="sm" onClose={closeDeleteBannerModal}>
        <ModalConfirm
          title={`Supprimer "${banner?.contents?.find((content) => content.lang === "FR").title}"`}
          text={
            <Box pb={2}>
              Attention cette action est irréversible.
              <br />
              Le bandeau pourra être recréé manuellement.
            </Box>
          }
          confirmText="Supprimer"
          confirmButtonProps={{ style: { backgroundColor: theme.palette.error.main } }}
          onConfirm={() => {
            deleteBanner();
            closeDeleteBannerModal();
          }}
          onCancel={closeDeleteBannerModal}
        />
      </Modal>
    </Box>
  );
};

AdminBannerForm.propTypes = {
  banner: PropTypes.shape(),
  banners: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  onCancel: PropTypes.func,
  getBanners: PropTypes.func,
};

AdminBannerForm.defaultProps = {
  banner: { sites: [], forceDisplay: false },
  banners: [],
  submitLabel: "Valider",
  onCancel: null,
  getBanners: null,
};

export default AdminBannerForm;
