import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import themes from "apps/front-office/client/themes/themes";
import AdminBannerContent, { fieldsetCustomStyled } from "components/adminComponents/AdminBannerContent";
import AdminContext from "components/adminComponents/AdminContext";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import ModalConfirm from "components/adminComponents/ModalConfirm";
import AdminBannersService from "components/adminServices/AdminBannersService";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  dateNowFormatted,
  displaySortedSites,
  formatDateToLocalTimezone,
  getDisplayPeriod,
  localeDateNow,
  types,
} from "utils/bannersUtils";
import { getSiteName } from "utils/commonUtils";

const AdminBannerForm = (props) => {
  const {
    banner = { sites: [], forceDisplay: false },
    banners = [],
    getBanners = null,
    onSubmit,
    submitLabel,
    onCancel,
  } = props;
  const { id, ...duplicateBanner } = banner;

  const { currentSite, allSites } = useContext(AdminContext);
  const { displaySuccess, displayError } = useContext(AdminMessageContext);

  const theme = themes[currentSite.name] || themes.ANTS;

  const localBanner = useMemo(() => {
    const { displayStartDate, displayEndDate } = banner;
    if (displayStartDate && displayEndDate) {
      const newDisplayStartDate = formatDateToLocalTimezone(displayStartDate);
      const newDisplayEndDate = formatDateToLocalTimezone(displayEndDate);
      return { ...banner, displayStartDate: newDisplayStartDate, displayEndDate: newDisplayEndDate };
    }
    return banner;
  }, [banner]);

  const [form, setForm] = useState(localBanner);
  const [submitted, setSubmitted] = useState(false);
  const [duplicateBannerModal, setDuplicateBannerModal] = useState(false);
  const [multipleBannersModal, setMultipleBannersModal] = useState(false);
  const [deleteBannerModal, setDeleteBannerModal] = useState(false);

  const { forceDisplay = false, sites = [], type, displayStartDate = "", displayEndDate = "", contents = [] } = form;

  const startDateFormatted = useMemo(
    () => (displayStartDate ? displayStartDate.split("T")[0] : ""),
    [displayStartDate]
  );

  const startTimeFormatted = useMemo(
    () => (displayStartDate ? displayStartDate.split("T")[1].substring(0, 5) : ""),
    [displayStartDate]
  );

  const endDateFormatted = useMemo(() => (displayEndDate ? displayEndDate.split("T")[0] : ""), [displayEndDate]);

  const endTimeFormatted = useMemo(
    () => (displayEndDate ? displayEndDate.split("T")[1].substring(0, 5) : ""),
    [displayEndDate]
  );

  const errors = useMemo(() => {
    const newErrors = {};
    if (!type) {
      newErrors.type = "Le type est obligatoire";
    }
    if (!contents.find((content) => content.lang === "FR")?.title) {
      newErrors.titleFR = "Le titre du bandeau français est obligatoire";
    }
    if (!contents.find((content) => content.lang === "EN")?.title) {
      newErrors.titleEN = "Le titre du bandeau en anglais est obligatoire";
    }
    if (!forceDisplay) {
      if (!displayStartDate && !displayEndDate) {
        newErrors.displayDate = "La période d’affichage est obligatoire";
      }
      if (!displayStartDate && displayEndDate) {
        newErrors.displayStartDate = "Les deux dates sont nécessaires pour définir une période d'affichage";
      }
      if (displayStartDate && !displayEndDate) {
        newErrors.displayEndDate = "Les deux dates sont nécessaires pour définir une période d'affichage";
      }
      if (displayStartDate && displayEndDate) {
        if (displayStartDate >= displayEndDate) {
          newErrors.displayDate = "La date de fin doit être postérieure à la date de début";
        }
      }
    }
    return newErrors;
  }, [contents, displayEndDate, displayStartDate, forceDisplay, type]);

  const dateTitle = useMemo(() => (forceDisplay ? "Le bandeau est en affichage permanent" : ""), [forceDisplay]);

  const timeTitle = useCallback(
    (date, name) => {
      if (forceDisplay) {
        return "Le bandeau est en affichage permanent";
      }
      if (!date) {
        if (name === "start") {
          return "Veuillez renseigner une date de début";
        }
        if (name === "end") {
          return "Veuillez renseigner une date de fin";
        }
      }
      return "";
    },
    [forceDisplay]
  );

  const warnings = useMemo(() => {
    const newWarnings = {};
    if (displayStartDate && displayEndDate) {
      if (forceDisplay && displayStartDate >= displayEndDate) {
        newWarnings.displayDate = "Les dates étant erronées, elles ne seront pas enregistrées";
      }
      if (!forceDisplay && Date.parse(displayEndDate) < localeDateNow) {
        newWarnings.displayDate = "La fin de période d'affichage est antérieure à la date actuelle";
      }
    }
    return newWarnings;
  }, [displayEndDate, displayStartDate, forceDisplay]);

  const showError = (key) => submitted && errors[key];
  const showWarning = (key) => warnings[key];
  const isFormValid = Object.values(errors).reduce((a, b) => a && !b, true);

  const closeDuplicateBannerModal = () => {
    setDuplicateBannerModal(false);
  };

  const closeMultipleBannersModal = () => {
    setMultipleBannersModal(false);
  };

  const closeDeleteBannerModal = () => {
    setDeleteBannerModal(false);
  };

  const handleTypeChange = (e) => {
    setForm({
      ...form,
      type: e.target.value,
    });
  };

  const handleSelectSites = (e) => {
    const { value } = e.target;
    setForm({
      ...form,
      sites: value.map((site) => JSON.parse(site)),
    });
  };

  const handleDisplayChange = (e) => {
    setForm({ ...form, forceDisplay: e.target.checked });
  };

  const handleDateChange = (e) => {
    const { id: inputId, name, value } = e.target;
    let defaultTime;
    switch (name) {
      case "displayStartDate":
        defaultTime = "00:00:00.000";
        break;
      case "displayEndDate":
        defaultTime = "23:59:00.000";
        break;
      default:
        defaultTime = "00:00:00.000";
    }
    let newValue;
    switch (inputId) {
      case "date":
        newValue = value ? `${value}T${form[name]?.split("T")[1] || defaultTime}` : "";
        break;
      case "time":
        newValue = value ? `${form[name]?.split("T")[0]}T${value}:00.000` : "";
        break;
      default:
        newValue = value;
    }
    setForm({ ...form, [name]: newValue || "" });
  };

  const handleContentsChange = (e, lang) => {
    const { name, value } = e.target;
    if (contents.length === 0 || !contents.find((content) => content.lang === lang)) {
      setForm({
        ...form,
        contents: [...contents, { lang, [name]: value }],
      });
    } else {
      const newValue = contents?.map((content) => (content.lang === lang ? { ...content, [name]: value } : content));
      setForm({
        ...form,
        contents: newValue,
      });
    }
  };

  const deleteBanner = () => {
    AdminBannersService.delete(banner)
      .then(() => {
        displaySuccess("Bandeau supprimé");
        getBanners();
      })
      .catch(() => {
        displayError("Échec lors de la suppression du bandeau");
      });
  };

  const multipleBanners = useMemo(() => {
    if (sites.length === 0 || (!forceDisplay && !displayStartDate && !displayEndDate)) {
      return [];
    }
    let bannersFiltered = banners.filter((b) => b.id !== id);
    if (sites.length > 0) {
      const sitesIds = sites.map((site) => site.id);
      bannersFiltered = bannersFiltered.filter((b) => b.sites.find((site) => sitesIds.includes(site.id)));
    }
    if (forceDisplay) {
      bannersFiltered = bannersFiltered.filter(
        (b) =>
          b.forceDisplay || (b.displayStartDate && b.displayEndDate && Date.parse(b.displayEndDate) >= localeDateNow)
      );
    }
    if (displayStartDate && displayEndDate) {
      const start = new Date(displayStartDate);
      const end = new Date(displayEndDate);
      bannersFiltered = bannersFiltered.filter((b) => {
        const startDate = new Date(b.displayStartDate);
        const endDate = new Date(b.displayEndDate);
        return (
          b.forceDisplay ||
          (start >= startDate && start <= endDate) ||
          (end >= startDate && end <= endDate) ||
          (start <= startDate && end >= endDate)
        );
      });
    }
    return bannersFiltered;
  }, [banners, displayEndDate, displayStartDate, forceDisplay, id, sites]);

  const handleFormSubmit = () => {
    setSubmitted(true);
    if (isFormValid) {
      if (multipleBanners.length === 0) {
        if (typeof onSubmit === "function") {
          onSubmit(form);
        }
      } else {
        setMultipleBannersModal(true);
      }
    }
  };

  return (
    <Box sx={{ position: "relative", p: 2, border: "1px solid", borderColor: "grey.500" }}>
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={banner?.id ? 2 : 3}>
          <FormControl error={showError("type")}>
            <TextField
              id="type"
              name="type"
              select
              label="Type *"
              value={type || ""}
              variant="standard"
              onChange={handleTypeChange}
              error={showError("type")}
            >
              {types.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {showError("type") && <FormHelperText sx={{ height: "22px" }}>{errors.type}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={banner?.id ? 8 : 9}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="controlled-multiple-sites-label">Sites</InputLabel>
            <Select
              labelId="controlled-open-select-label"
              id="controlled-open-select"
              name="sites"
              multiple
              label="Sites"
              variant="standard"
              value={sites?.map((site) => JSON.stringify(site)) || []}
              onChange={handleSelectSites}
              renderValue={(selectedSites) => {
                if (selectedSites.length === 0) {
                  return "";
                }
                return displaySortedSites(selectedSites?.map((site) => JSON.parse(site)) || []);
              }}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    p: 0,
                  },
                },
                getContentAnchorEl: null,
              }}
            >
              {allSites.map((option) => (
                <MenuItem key={option.id} value={JSON.stringify(option)}>
                  <Checkbox checked={sites.map((site) => site.id).includes(option.id)} />
                  {getSiteName(option.name)}
                </MenuItem>
              ))}
            </Select>
            {showError("type") && <FormHelperText sx={{ height: "22px" }} />}
          </FormControl>
        </Grid>
        {banner?.id && (
          <>
            <Grid item xs={1} sx={{ textAlign: "center" }}>
              <IconButton size="small" color="secondary" onClick={() => setDuplicateBannerModal(true)}>
                <Icon icon="copy" title="Dupliquer le bandeau" />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <IconButton size="small" color="secondary" onClick={() => setDeleteBannerModal(true)}>
                <Icon icon="trash-alt" title="Supprimer le bandeau" />
              </IconButton>
            </Grid>
            {typeof onCancel === "function" && (
              <IconButton
                size="small"
                color="secondary"
                onClick={onCancel}
                sx={{ position: "absolute", top: 4, right: 8 }}
              >
                <Icon iconDSFR="close-line" title="Fermer le détail" />
              </IconButton>
            )}
          </>
        )}
        <Grid item xs={12}>
          <Box component="fieldset" sx={fieldsetCustomStyled}>
            <FormLabel component="legend">Affichage</FormLabel>
            <Grid container alignItems="center" justifyContent="center" spacing={4}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="forceDisplay"
                      name="forceDisplay"
                      checked={forceDisplay}
                      onChange={handleDisplayChange}
                      disableRipple
                    />
                  }
                  label="Permanent"
                />
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ mx: 2, my: 1 }} />
              <Grid item xs={8}>
                <FormControl
                  error={showError("displayStartDate") || showError("displayEndDate") || showError("displayDate")}
                >
                  <Grid container justifyContent="space-around" alignItems="flex-end">
                    <Grid item xs={5} container alignItems="center" spacing={1}>
                      <Grid item xs={8}>
                        <TextField
                          id="date"
                          name="displayStartDate"
                          label="Date de début"
                          type="date"
                          value={startDateFormatted}
                          variant="standard"
                          onChange={handleDateChange}
                          disabled={forceDisplay}
                          title={dateTitle}
                          error={showError("displayStartDate") || showError("displayDate")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: dateNowFormatted,
                            max: endDateFormatted,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="time"
                          name="displayStartDate"
                          label="Heure"
                          type="time"
                          value={startTimeFormatted}
                          variant="standard"
                          onChange={handleDateChange}
                          disabled={forceDisplay || !displayStartDate}
                          title={timeTitle(displayStartDate, "start")}
                          error={showError("displayStartDate") || showError("displayDate")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={5} container alignItems="center" spacing={1}>
                      <Grid item xs={8}>
                        <TextField
                          id="date"
                          name="displayEndDate"
                          label="Date de fin"
                          type="date"
                          value={endDateFormatted}
                          variant="standard"
                          onChange={handleDateChange}
                          disabled={forceDisplay}
                          title={dateTitle}
                          error={showError("displayEndDate") || showError("displayDate")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: startDateFormatted || dateNowFormatted,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="time"
                          name="displayEndDate"
                          label="Heure"
                          type="time"
                          value={endTimeFormatted}
                          variant="standard"
                          onChange={handleDateChange}
                          disabled={forceDisplay || !displayEndDate}
                          title={timeTitle(displayEndDate, "end")}
                          error={showError("displayStartDate") || showError("displayDate")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: dateNowFormatted,
                            max: endDateFormatted,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={1} container justifyContent="flex-end">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => setForm({ ...form, displayStartDate: "", displayEndDate: "" })}
                        disabled={forceDisplay || (!displayStartDate && !displayEndDate)}
                      >
                        <Icon iconDSFR="refresh-line" title="Réinitialiser les dates" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {showError("displayEndDate") && (
                    <FormHelperText sx={{ textAlign: "center" }}>{errors.displayEndDate}</FormHelperText>
                  )}
                  {showError("displayStartDate") && (
                    <FormHelperText sx={{ textAlign: "center" }}>{errors.displayStartDate}</FormHelperText>
                  )}
                  {showError("displayDate") && (
                    <FormHelperText sx={{ textAlign: "center" }}>{errors.displayDate}</FormHelperText>
                  )}
                </FormControl>
                {showWarning("displayDate") && (
                  <FormHelperText className="fr-icon-warning-line" sx={{ textAlign: "center" }}>
                    &nbsp;{warnings.displayDate}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <AdminBannerContent
          contents={contents}
          errors={errors}
          lang="FR"
          onChange={(e) => handleContentsChange(e, "FR")}
          showError={showError}
          title="Français"
        />
        <AdminBannerContent
          contents={contents}
          errors={errors}
          lang="EN"
          onChange={(e) => handleContentsChange(e, "EN")}
          showError={showError}
          title="Anglais"
        />
      </Grid>
      <Box mt={2}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            {typeof onCancel === "function" && (
              <Button outlined onClick={onCancel}>
                Annuler
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={handleFormSubmit}>{submitLabel}</Button>
          </Grid>
        </Grid>
      </Box>
      <Modal open={duplicateBannerModal} size="lg" onClose={closeDuplicateBannerModal}>
        <h2>Duplication du bandeau</h2>
        <AdminBannerForm
          banner={duplicateBanner}
          banners={banners}
          onSubmit={onSubmit}
          onCancel={closeDuplicateBannerModal}
          submitLabel="Dupliquer"
        />
      </Modal>
      <Modal open={multipleBannersModal} size="sm" onClose={closeMultipleBannersModal}>
        <ModalConfirm
          title={multipleBanners.length > 1 ? "Bandeaux similaires déjà existants" : "Bandeau similaire déjà existant"}
          text={
            <Box pb={2}>
              {`Attention ${
                multipleBanners ? "des bandeaux sont déjà programmés" : "un bandeau est déjà programmé"
              } pour apparaître sur au moins un des sites selectionnés :`}
              <Stack spacing={2} sx={{ my: 2, "& tbody": { display: "table", width: "100%" } }}>
                {sites.length > 0 &&
                  sites.map((site) => (
                    <Box key={site.id}>
                      <Box component="h6" m={0}>
                        {getSiteName(site.name)}
                      </Box>
                      <TableBody>
                        {multipleBanners
                          ?.filter((ban) => ban.sites?.find((s) => s.id === site.id))
                          .map((ban) => (
                            <TableRow key={ban.id}>
                              <TableCell>{ban.contents?.find((content) => content.lang === "FR").title}</TableCell>
                              <TableCell>{getDisplayPeriod(ban)}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Box>
                  ))}
              </Stack>
              {`Voulez-vous tout de même ${submitLabel.toLowerCase()} ce nouveau bandeau ?`}
            </Box>
          }
          confirmText={submitLabel}
          onConfirm={() => {
            if (typeof onSubmit === "function") {
              onSubmit(form);
            }
            closeMultipleBannersModal();
          }}
          onCancel={closeMultipleBannersModal}
        />
      </Modal>
      <Modal open={deleteBannerModal} size="sm" onClose={closeDeleteBannerModal}>
        <ModalConfirm
          title={`Supprimer "${banner?.contents?.find((content) => content.lang === "FR").title}"`}
          text={
            <Box pb={2}>
              Attention cette action est irréversible.
              <br />
              Le bandeau pourra être recréé manuellement.
            </Box>
          }
          confirmText="Supprimer"
          confirmButtonProps={{ style: { backgroundColor: theme.palette.error.main } }}
          onConfirm={() => {
            deleteBanner();
            closeDeleteBannerModal();
          }}
          onCancel={closeDeleteBannerModal}
        />
      </Modal>
    </Box>
  );
};

AdminBannerForm.propTypes = {
  banner: PropTypes.shape(),
  banners: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  getBanners: PropTypes.func,
};

export default AdminBannerForm;
