import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import AdminMenuItems from "components/adminComponents/AdminMenuItems";

const useStyles = makeStyles(() => ({
  root: {},
}));

const AdminHeaderForm = (props) => {
  const { header, onHeaderChange } = props;
  const { contents = [] } = header;

  const classes = useStyles();

  const handleItemsChange = (headerItems) => {
    onHeaderChange({
      ...header,
      contents: headerItems,
    });
  };

  return (
    <div className={classes.root}>
      <AdminMenuItems items={contents} itemKey="profiles" onItemsChange={handleItemsChange} maxDepth={0} />
    </div>
  );
};

AdminHeaderForm.propTypes = {
  header: PropTypes.shape(),
  onHeaderChange: PropTypes.func.isRequired,
};

AdminHeaderForm.defaultProps = {
  header: {},
};

export default AdminHeaderForm;
