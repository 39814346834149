import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { formControlStyled } from "components/adminComponents/contentForms/ContentFormStyled";
import PropTypes from "prop-types";
import React from "react";

const TextAreaForm = (props) => {
  const { content, onContentChange, ...others } = props;

  const { id, label, value, width } = content;

  const handleTextareaChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.value,
    });
  };

  return (
    <FormControl sx={formControlStyled(width)} key={id}>
      <TextField label={label} value={value} onChange={handleTextareaChange} multiline rows={3} {...others} />
    </FormControl>
  );
};

TextAreaForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default TextAreaForm;
