import AdminMenuItems from "components/adminComponents/AdminMenuItems";
import PropTypes from "prop-types";
import React from "react";

const AdminMenuForm = (props) => {
  const { menu = {}, onMenuChange } = props;
  const { contents = [] } = menu;

  const handleItemsChange = (menuItems) => {
    onMenuChange({
      ...menu,
      contents: menuItems,
    });
  };

  return (
    <AdminMenuItems
      items={contents}
      itemKey="menus"
      subItemKey="subMenus"
      onItemsChange={handleItemsChange}
      maxDepth={1}
    />
  );
};

AdminMenuForm.propTypes = {
  menu: PropTypes.shape(),
  onMenuChange: PropTypes.func.isRequired,
};

export default AdminMenuForm;
