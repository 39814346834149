import React, { useState, useCallback, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import AdminVersions from "components/adminComponents/AdminVersions";
import AdminContext from "components/adminComponents/AdminContext";
import AdminSectionsService from "components/adminServices/AdminSectionsService";
import sectionsTypes from "utils/sectionsTypes";
import versionStatuses from "utils/versionStatuses";

// let autoSaveTimeout = null;
// const autoSaveDelay = 2000;

const useStyles = makeStyles(() => ({
  root: {},
}));

const AdminSection = (props) => {
  const { title, type, children, onSectionChanged } = props;

  const classes = useStyles();

  const [sectionsVersions, setSectionsVersions] = useState([]);
  const [currentSection, setCurrentSection] = useState(null);
  const [sectionForm, setSectionForm] = useState(null);

  useEffect(() => {
    setSectionForm(currentSection);
  }, [currentSection]);

  const {
    currentSite,
    // refreshSite,
  } = useContext(AdminContext);

  const getSections = useCallback(() => {
    return AdminSectionsService.getSectionVersions({ type, siteId: currentSite.id }).then((sections) => {
      setSectionsVersions(sections);
      return sections;
    });
  }, [currentSite.id, type]);

  const setCurrentSectionVersion = useCallback(
    (section = {}) =>
      getSections().then((sections) => {
        setCurrentSection((sections && sections.find((s) => s.id === section.id)) || sections[0]);
      }),
    [getSections]
  );

  useEffect(() => {
    setCurrentSectionVersion();
  }, [setCurrentSectionVersion]);

  const sectionChangedCallback = useCallback(
    (response) => {
      if (typeof onSectionChanged === "function") {
        onSectionChanged();
      }
      return response;
    },
    [onSectionChanged]
  );

  const handleSaveVersion = (section) => {
    if (section.status === versionStatuses.DRAFT) {
      return AdminSectionsService.update(section)
        .then(sectionChangedCallback)
        .catch(() => {
          setCurrentSectionVersion(section);
          // displayError("Erreur lors de la mise à jour du brouillon");
        });
    }
    return AdminSectionsService.create(section)
      .then(sectionChangedCallback)
      .catch(() => {
        setCurrentSectionVersion(section);
        // displayError("Erreur lors de la création du brouillon");
      });
  };

  const updateVersionStatus = ({ version, status }) => {
    return AdminSectionsService.updateStatus(version, status)
      .then(sectionChangedCallback)
      .catch(() => {
        // displayError("Erreur lors de la mise à jour du statut de la version");
      });
  };

  const deleteVersion = (version) => AdminSectionsService.delete(version).then(sectionChangedCallback);

  return (
    <div className={classes.root}>
      {sectionForm && (
        <AdminVersions
          leftActions={<h2>{title}</h2>}
          versions={sectionsVersions}
          currentVersion={sectionForm}
          setCurrentVersion={setCurrentSectionVersion}
          onVersionChange={setSectionForm}
          saveVersion={handleSaveVersion}
          canUnpublish={false}
          updateStatus={updateVersionStatus}
          deleteVersion={deleteVersion}
        >
          {({ handleVersionChange }) => children({ section: sectionForm, onSectionChange: handleVersionChange })}
        </AdminVersions>
      )}
    </div>
  );
};

AdminSection.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(sectionsTypes)).isRequired,
  children: PropTypes.func.isRequired,
  onSectionChanged: PropTypes.func,
};

AdminSection.defaultProps = {
  onSectionChanged: null,
};

export default AdminSection;
