import React from "react";
import PropTypes from "prop-types";
import AdminActions from "components/adminComponents/AdminActions";
import DynamicAdmin from "components/adminComponents/DynamicAdmin";

const AdminTabWrapper = (props) => {
  const { id, content, children, onContentChange, onRemoveContent, limit, wrappedComponentProps } = props;
  const { hidden } = wrappedComponentProps;

  return (
    <AdminActions key={id} content={content} onContentChange={onContentChange} hidden={hidden}>
      <DynamicAdmin onRemoveContent={onRemoveContent} limit={limit} deleteContentText="Supprimer cet onglet">
        {children}
      </DynamicAdmin>
    </AdminActions>
  );
};

AdminTabWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.shape().isRequired,
  children: PropTypes.node,
  onContentChange: PropTypes.func.isRequired,
  onRemoveContent: PropTypes.func.isRequired,
  limit: PropTypes.number,
  wrappedComponentProps: PropTypes.shape(),
};

AdminTabWrapper.defaultProps = {
  children: null,
  limit: null,
  wrappedComponentProps: {},
};

export default AdminTabWrapper;
