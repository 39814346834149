import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const SelectForm = (props) => {
  const { content, classes, onContentChange } = props;

  const { id, label, value, options } = content;

  const handleSelectChange = (e) => {
    onContentChange({
      ...content,
      value: e.target.value,
    });
  };

  return (
    <FormControl className={classes && classes.formControl} key={id}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        value={value}
        // native
        onChange={handleSelectChange}
        displayEmpty
        MenuProps={{ getContentAnchorEl: null }}
        style={{ fontStyle: !value ? "italic" : "inherit" }}
      >
        <MenuItem value="" style={{ fontStyle: "italic" }}>
          -- Sélectionner --
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default SelectForm;
